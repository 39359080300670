import { IAdvertiserState, IAdvertiserStateAdmin, IAdvertiserStateBrand, IAdvertiserStateChain } from './AdvertiserInterfaces';
import REPRESENIVE_INITIAL_STATE from '../representative/RepresentativeInicialState';

export const DEFAULT_ADVERTISER_STATE_BRAND: IAdvertiserStateBrand = {
  id: 0,
  name: '',  
  category:{
    id:0,
    name:'',
  },
  categories:[]
};

export const DEFAULT_ADVERTISER_STATE_CHAIN: IAdvertiserStateChain = {
  id: 0,
  name: '',
};

export const ADMIN_INITIAL_STATE: IAdvertiserStateAdmin = {
  id: 0,
  name: '',
  lastName: '',
  position: '',
  email: '',
  phone: '',
  mobilePhone: '',
  identification: '',
  identificationType: 'ID',
  emailExists: false,
  idExists: 0,
};


const ADVERTISER_INITIAL_STATE: IAdvertiserState = {
  data: {
    id: 0,
    city: '',
    country: '',
    address: '',
    phoneNumber: '',
    rncCode: '',
    name: '',
    commercialName: '',
    admin: ADMIN_INITIAL_STATE,
    representative: REPRESENIVE_INITIAL_STATE.data,
  },
  errors: {
    rncCode: undefined,
  },
  meta: {
    isEditingSingleStep: false,
    loading: false,
  },
};

export default ADVERTISER_INITIAL_STATE;
