import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../common/constants/routes/admin-routes.constants";
import lazyPages from "../pages/async";

const loadingFallback = <div>Loading...</div>;

const adminRouter = () => (
  <Suspense fallback={loadingFallback}>
    <Switch>
      <Redirect exact from={routes.ROOT} to={routes.RESERVATIONS} />
      <Route
        exact
        path={routes.DASHBOARD}
        render={() => <lazyPages.Dashboard.AdminDashboard />}
      />
      <Route
        exact
        path={routes.RESTORE_PASSWORD}
        render={() => <lazyPages.Authorization.RestorePassword />}
      />
      <Route
        exact
        path={routes.CHAINS}
        render={() => <lazyPages.Chains.ChainList />}
      />
      <Route
        exact
        path={routes.CHAINS_CREATE}
        render={() => <lazyPages.Chains.CreateChains />}
      />
      <Route
        exact
        path={routes.CHAIN_DETAIL}
        render={() => <lazyPages.Chains.ChainDetail />}
      />
      <Route
        exact
        path={routes.CHAIN_EDIT}
        render={() => <lazyPages.Chains.ChainEdit />}
      />
      <Route
        exact
        path={routes.CHAINS_BRANCHES}
        render={() => <lazyPages.Chains.Branches.BranchList />}
      />
      <Route
        exact
        path={routes.CHAINS_BRANCHES_CREATE}
        render={() => <lazyPages.Chains.Branches.CreateBranch />}
      />
      <Route
        exact
        path={routes.CHAINS_BRANCHES_DETAIL}
        render={() => <lazyPages.Chains.Branches.BranchDetail />}
      />
      <Route
        exact
        path={routes.CHAINS_BRANCHES_EDIT}
        render={() => <lazyPages.Chains.Branches.BranchEdit />}
      />
      <Route
        exact
        path={routes.CATEGORIES}
        render={() => <lazyPages.Categories.CategoryList />}
      />
      <Route
        exact
        path={routes.CATEGORY_DETAIL}
        render={() => <lazyPages.Categories.CategoryDetail />}
      />
      <Route
        exact
        path={routes.SCREENS}
        render={() => <lazyPages.Screens.ScreenList />}
      />
      <Route
        exact
        path={routes.SCREENS_CREATE}
        render={() => <lazyPages.Screens.CreateScreen />}
      />
      <Route
        exact
        path={routes.ADVERTISERS}
        render={() => <lazyPages.Advertisers.AdvertiserList />}
      />
      <Route
        exact
        path={routes.ADVERTISER_DETAIL}
        render={() => <lazyPages.Advertisers.AdvertiserDetail />}
      />
      <Route
        exact
        path={routes.ADVERTISERS_CREATE}
        render={() => <lazyPages.Advertisers.CreateAdvertiser />}
      />
      <Route
        exact
        path={routes.ADVERTISER_EDIT}
        render={() => <lazyPages.Advertisers.AdvertiserEdit />}
      />
      <Route
        exact
        path={routes.BRANDS}
        render={() => <lazyPages.Brands.BrandList />}
      />
      <Route
        exact
        path={routes.BRAND_DETAIL}
        render={() => <lazyPages.Brands.BrandDetail />}
      />
      <Route
        exact
        path={routes.CAMPAIGNS}
        render={() => <lazyPages.Campaigns.CampaignList />}
      />
      <Route
        exact
        path={routes.CAMPAIGNS_CREATE}
        render={() => <lazyPages.Campaigns.CreateCampaign />}
      />
      <Route
        exact
        path={routes.CAMPAIGNS_EDIT}
        render={() => <lazyPages.Campaigns.CreateCampaign />}
      />
      <Route
        exact
        path={routes.CAMPAIGNS_DETAIL}
        render={() => <lazyPages.Campaigns.CampaignDetail />}
      />
      <Route
        exact
        path={routes.RESERVATIONS}
        render={() => <lazyPages.Reservations.ReservationList />}
      />
      <Route
        exact
        path={routes.RESERVATIONS_CREATE}
        render={() => <lazyPages.Reservations.CreateReservation />}
      />
      <Route
        exact
        path={routes.RESERVATIONS_CART}
        render={() => <lazyPages.Reservations.CartReservation />}
      />
      <Route
        exact
        path={routes.RESERVATIONS_DETAIL}
        render={() => <lazyPages.Reservations.ReservationDetail />}
      />
      <Route
        exact
        path={routes.REPORTS}
        render={() => <lazyPages.Reports.ReportList />}
      />
      <Route
        exact
        path={routes.REPORTS_ADVERTISERS}
        render={() => <lazyPages.Reports.AdminReportsAboutAdvertisers />}
      />
      <Route
        exact
        path={routes.REPORTS_BRANCHES}
        render={() => <lazyPages.Reports.AdminReportsAboutBranches />}
      />
      <Route
        exact
        path={routes.REPORTS_CATEGORIES}
        render={() => <lazyPages.Reports.AdminReportsAboutCategories />}
      />
      <Route
        exact
        path={routes.REPORTS_RESERVATIONS}
        render={() => <lazyPages.Reports.AdminReportsAboutReservations />}
      />
      <Route
        exact
        path={routes.REPORTS_SALES}
        render={() => <lazyPages.Reports.AdminReportsAboutSales />}
      />
      <Route
        exact
        path={routes.REPORTS_MERAKI}
        render={() => <lazyPages.Reports.AdminReportsAboutMeraki />}
      />
      <Route
        exact
        path={routes.PRODUCT_LOADS}
        render={() => <lazyPages.Reports.AdminProductLoads />}
      />
      <Route
        exact
        path={routes.USERS}
        render={() => <lazyPages.Users.UserList />}
      />
      <Route
        exact
        path={routes.USERS_CREATE}
        render={() => <lazyPages.Users.CreateUser />}
      />
      <Route
        exact
        path={routes.USER_DETAIL}
        render={() => <lazyPages.Users.UserDetail />}
      />
      <Route
        exact
        path={routes.NOTIFICATIONS}
        render={() => <lazyPages.Notifications.NotificationList />}
      />
      <Route
        exact
        path={routes.CONFIGURATION}
        render={() => <lazyPages.Configuration.ConfigurationEdit />}
      />
      <Route
        exact
        path={routes.PRODUCTS}
        render={() => <lazyPages.Products.ProductList />}
      />
      <Route
        exact
        path={routes.TERMS_AND_CONDITIONS}
        render={() => <lazyPages.TermsAndConditions.TermsAndConditions />}
      />
      <Route
        exact
        path={routes.USER_CERTIFICATION}
        render={() => <lazyPages.UserCertification.UserCertification />}
      />
      <Route
        exact
        path={routes.RESEND_EMAILS}
        render={() => <lazyPages.ResendEmails.ResendEmails />}
      />
    </Switch>
  </Suspense>
);

export default adminRouter;

