import _ from "lodash";
import { handleActions, Action } from "redux-actions";

import * as ACTIONS from "../constants/actions";
import ThynkadsResponse from "../../dtos/ThynkadsResponse";
import ThynkadsResponseInitialState from "../../dtos/ThynkadsResponseInitialState";
import { IReservationData, IReservationsState } from "./ReservationsInterfaces";
import RESERVATIONS_INITIAL_STATE from "./ReservationsInitialState";

const setReservations = (
  reservations: IReservationsState,
  action: Action<ThynkadsResponse<IReservationData[]>>
): any => {
  const payload = action.payload! || ThynkadsResponseInitialState;
  const data = payload.data || RESERVATIONS_INITIAL_STATE.data;
  return {
    ...reservations,
    data,
  };
};

const setHasBilledReservations = (
  reservations: IReservationsState,
  action: Action<ThynkadsResponse<IReservationData[]>>
): any => {
  return {
    ...reservations,
  };
};

const setHasPreReservations = (
  reservations: IReservationsState,
  action: Action<ThynkadsResponse<IReservationData[]>>
): any => {
  return {
    ...reservations,
  };
};

const resetReservationsState = (): IReservationsState => {
  return {
    ...RESERVATIONS_INITIAL_STATE,
  };
};

const reservationsSearchStarted = (
  reservations: IReservationsState,
  action: Action<string>
): IReservationsState => {
  return {
    ...reservations,
  };
};

const reservationsSearchFinished = (
  reservations: IReservationsState
): IReservationsState => {
  return {
    ...reservations,
  };
};

export const reservationsReducer = handleActions<IReservationsState, any>(
  {
    [ACTIONS.SET_RESERVATIONS]: setReservations,
    [ACTIONS.RESET_RESERVATIONS_STATE]: resetReservationsState,
    [ACTIONS.RESERVATIONS_SEARCH_STARTED]: reservationsSearchStarted,
    [ACTIONS.RESERVATIONS_SEARCH_FINISHED]: reservationsSearchFinished,
    [ACTIONS.RESERVATIONS_SEARCH_SUCCESS]: setReservations,
    [ACTIONS.BILLED_RESERVATIONS_SEARCH_SUCCESS]: setHasBilledReservations,
    [ACTIONS.PRE_RESERVATIONS_SEARCH_SUCCESS]: setHasPreReservations,
  },
  RESERVATIONS_INITIAL_STATE
);

export default reservationsReducer;
