import React, { Component } from 'react';
import { ISite, SiteCallback } from './interfaces';
import Icons from '../../../resources/assets/icons';
import CONSTANTS from './constants';

interface IPathBarProps {
  path: ISite[];
  className: string;
}

export default class PathBar extends Component<IPathBarProps> {
  static defaultProps = {
    className: '',
  };

  getHandleOnClick = (callback: SiteCallback, name: string, clickedPathPos: number) => 
    () => callback(name, clickedPathPos)

  renderIcon = (shouldRender: boolean) => {
    if (!shouldRender) return null;

    return (
      <Icons.ChevronRight
        width={CONSTANTS.DEFAULT_CHEVRON_WIDTH}
        height={CONSTANTS.DEFAULT_CHEVRON_HEIGHT}
        fill={CONSTANTS.DEFAULT_CHEVRON_COLOR}
      />
    );
  }

  getLastTextClass = (isLast: boolean) => isLast ? 'last-site' : '';

  renderPath = () => {
    const { path } = this.props;

    return (
      <ul className="path-list">
        {
          path.map((site, i, arr) => {
            const isLast = (i >= arr.length - 1);
            return (
              <li key={i}>
                <span
                  className={`site-name clickable ${this.getLastTextClass(isLast)}`}
                  onClick={this.getHandleOnClick(site.callback, site.name, i)}
                >
                  {site.name}
                </span>
                {this.renderIcon(!isLast)}
              </li>
            );
          })
        }
      </ul>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        {this.renderPath()}
      </div>
    );
  }
}
