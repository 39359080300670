import { IPlayersStateDataItem, IPlayersState } from './PlayersInterfaces';
import { PlayerStatusTypes } from '../../enums/players/PlayerStatusTypes';

// Properties defaults
export const DEFAULT_PLAYERS_STATE_DATA_ITEM: IPlayersStateDataItem = {
  id: 0,
  serialCode: '',
  manufacturer: '',
  model: '',
  status: PlayerStatusTypes.Ok,
};

// Initial State
const PLAYERS_INITIAL_STATE: IPlayersState = {
  data: [],
  filters: {},
  errors: {
    search: '',
  },
};

export default PLAYERS_INITIAL_STATE;
