export const products = {
  searchProducts: {
    method: 'get',
    url: '/products',
  },
  createProduct: {
    method: 'post',
    url: '/products',
  }
};
