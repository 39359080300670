import { handleActions, Action } from 'redux-actions';
import { IConfigurationState } from './ConfigurationInterfaces';
import CONFIGURATION_INITIAL_STATE from './ConfigurationInitialState';
import * as ACTIONS from '../constants/actions';
import { IUpdateConfigurationPayload } from '../../payloads/configuration/IUpdateConfigurationPayload';

export const getConfigurationSuceed = (state: IConfigurationState, action: Action<Partial<IConfigurationState>>): IConfigurationState => {

  const data = action.payload && action.payload.data ? action.payload.data : CONFIGURATION_INITIAL_STATE.data;

  return {
    ...CONFIGURATION_INITIAL_STATE,
    data: {
      ...data,
    },
  };
};

export const getConfigurationFailed = (state: IConfigurationState, action: Action<{ error: string }>): IConfigurationState => {
  const error = action.payload && action.payload.error ? action.payload.error : '';
  return {
    ...CONFIGURATION_INITIAL_STATE,
    errors: {
      ...CONFIGURATION_INITIAL_STATE.errors,
      fetch: error,
    },
  };
};

export const configurationItemChanged = (state: IConfigurationState, action: Action<Partial<IUpdateConfigurationPayload>>): IConfigurationState => {

  return {
    ...CONFIGURATION_INITIAL_STATE,
    data: {
      ...state.data,
      ...action.payload,
    },
  };
};

export const resetConfigurationState = (state: IConfigurationState, action: Action<null>): IConfigurationState => ({
  ...CONFIGURATION_INITIAL_STATE,
});

export const updateConfigurationFailed = (state: IConfigurationState, action: Action<string>): IConfigurationState => ({
  ...state,
  errors: {
    ...state.errors,
    udpate: (action.payload || ''),
  },
});

const configurationReducer = handleActions<IConfigurationState, any>(
  {
    [ACTIONS.GET_CONFIGURATION_SUCCESS]: getConfigurationSuceed,
    [ACTIONS.GET_CONFIGURATION_FAIL]: getConfigurationFailed,
    [ACTIONS.CHANGE_CONFIGURATION_ITEM]: configurationItemChanged,
    [ACTIONS.RESET_CONFIGURATION_STATE]: resetConfigurationState,
    [ACTIONS.UPDATE_CONFIGURATION_SUCCESS]: getConfigurationSuceed,
    [ACTIONS.UPDATE_CONFIGURATION_FAILED]: updateConfigurationFailed,
  },
  CONFIGURATION_INITIAL_STATE,
);

export default configurationReducer;
