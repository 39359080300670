import { lazy } from 'react';

const AdminDashboard = lazy(() => import('./AdminDashboard'));;
const AdvertiserDashboard = lazy(() => import('./AdvertiserDashboard'));;
const AccountManagerDashboard = lazy(() => import('./AccountManagerDashboard'));;
const ChainAdminDashboard = lazy(() => import('./ChainAdminDashboard'));;

export default {
  AdminDashboard,
  AdvertiserDashboard,
  AccountManagerDashboard,
  ChainAdminDashboard,
};
