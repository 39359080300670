import BaseService from '../BaseService';
import { blobToDataURL, dataURLtoBlob } from '../../utilities/ImageConverter';

export default class FileService extends BaseService {

  static async fetchImage(fileName: string): Promise<Response> {
    const url = process.env.REACT_APP_STORED_IMAGE_URL + '/' + fileName;
  
    const myInit = {
      method: 'GET',
      mode: 'cors' as RequestMode,
    };

    const myRequest = new Request(url, myInit);
    return fetch(myRequest);
  }
  
}
