import { ICategory } from './CategoryInterfaces';

const CATEGORY_INITIAL_STATE: ICategory = {
  id: 0,
  name: '',
  active: false,

  activeScreensAmount: 0,
  activeAdvertisersAmount: 0,
  totalAdvertisersAmount: 0,
  awaitingApprovalCampaignsAmount: 0,

  chains: [],
  brands: [],
  screens: [],
};

export default CATEGORY_INITIAL_STATE;
