import { IMonitorsStateDataItem, IMonitorsState } from './MonitorsInterfaces';

// Properties defaults
export const DEFAULT_MONITORS_STATE_DATA_ITEM: IMonitorsStateDataItem = {
  id: 0,
  serialCode: '',
  manufacturer: '',
  model: '',
  status: 1,
  size: 0,  
  active: false,
};

// Initial State
const MONITORS_INITIAL_STATE: IMonitorsState = {
  data: [],
  filters: {
    
  },
  errors: {
    search: '',
    create: '',
    update: '',
    delete: '',
  },
};

export default MONITORS_INITIAL_STATE;
