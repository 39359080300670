import { ISearchCategoryDTO } from '../dtos/categories/SearchCategoryDTO';
import { ICategory } from '../store/category/CategoryInterfaces';
import ChainMapper from './ChainMapper';
import { ICategoryEntityDTO } from '../dtos/categories/CategoryEntityDTO';

export default class CategoryMapper {
  public static mapSearchDtoToStore = (dto: ISearchCategoryDTO): ICategory => {
    return {
      id: dto.id,
      name: dto.name,
      active: dto.active,
      chains: dto.chains && dto.chains.map(chain => ChainMapper.mapDtoToStore(chain)),
      brands: dto.brands && dto.brands.map(brand => brand),
      screens: dto.screens && dto.screens.map(screen => screen),
      activeScreensAmount: 0,
      activeAdvertisersAmount: 0,
      awaitingApprovalCampaignsAmount: 0,
      totalAdvertisersAmount: 0,
    };
  }

  public static mapDtoToStore = (dto: ICategoryEntityDTO): ICategory => {
    return {
      id: dto.id,
      name: dto.name,
      active: dto.active,
      chains: dto.chains && dto.chains.map(chain => ChainMapper.mapDtoToStore(chain)),
      brands: dto.brands && dto.brands.map(brand => brand),
      screens: dto.screens && dto.screens.map(screen => screen),
      activeScreensAmount: 0,
      activeAdvertisersAmount: 0,
      totalAdvertisersAmount: 0,
      awaitingApprovalCampaignsAmount: 0,
    };
  }
}
