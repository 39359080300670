import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import {
  IMonitorsState,
  IMonitorsStateDataItem,
  IMonitorsStateErrors,
  IMonitorsStateFilters,
} from './MonitorsInterfaces';
import MONITORS_INITIAL_STATE from './MonitorsInitialState';

const setMonitorsData = (monitorsState: IMonitorsState, action: Action<IMonitorsStateDataItem[]>): IMonitorsState => {
  if (!action.payload) return { ...monitorsState };

  const newMonitorsData: IMonitorsStateDataItem[] = action.payload;

  return ({
    ...monitorsState,
    data: [
      ...newMonitorsData,
    ],
  });
};

const updateMonitorsErrors = (
  monitorsState: IMonitorsState,
  action: Action<Partial<IMonitorsStateErrors>>,
): IMonitorsState => {

  if (!action.payload) return { ...monitorsState };

  const newMonitorsErrors: Partial<IMonitorsStateErrors> = action.payload;

  return {
    ...monitorsState,
    errors: {
      ...monitorsState.errors,
      ...extractDefinedProps(newMonitorsErrors),
    },
  };

};

const updateMonitorsFilters = (
  monitorsState: IMonitorsState,
  action: Action<Partial<IMonitorsStateFilters>>,
): IMonitorsState => {

  if (!action.payload) return { ...monitorsState };

  const newMonitorsFilters: Partial<IMonitorsStateFilters> = action.payload;

  return {
    ...monitorsState,
    filters: {
      ...monitorsState.filters,
      ...extractDefinedProps(newMonitorsFilters),
    },
  };

};

const updateMonitorDataById = (
  monitorsState: IMonitorsState,
  action: Action<Partial<IMonitorsStateDataItem>>,
): IMonitorsState => {
  if (!action.payload || !action.payload.id) return { ...monitorsState };

  const newMonitor = action.payload;

  return {
    ...monitorsState,
    data: monitorsState.data.map((monitor) => {
      return Number(monitor.id) !== Number(newMonitor.id) ? { ...monitor } : {
        ...monitor,
        ...extractDefinedProps(newMonitor),
      };
    }),
  };
};

const resetMonitorsState = (monitorsState: IMonitorsState, action: Action<null>): IMonitorsState => ({
  ...MONITORS_INITIAL_STATE,
});

const checkMonitorCanDelete = (monitorState: IMonitorsState, action: Action<Partial<IMonitorsStateErrors>>)
  : IMonitorsState => {

  if (!action.payload) return { ...monitorState };

  const errors: Partial<IMonitorsStateErrors> = action.payload;

  return {
    ...monitorState,
    errors: {
      search: '',
      create: '',
      update: '',
      delete: '',
      canDelete: errors.canDelete,
    },
  };
};

const deleteMonitorById = (state: IMonitorsState, action: Action<Number>): IMonitorsState => {
  if (!action.payload) return { ...state };

  return {
    ...state,
    data: [
      ...state.data.filter(monitor => monitor.id !== action.payload),
    ],
  };
};


const checkMonitorCanDeleteFailed = (
  state: IMonitorsState, action: Action<Partial<IMonitorsStateErrors>>,
  ): IMonitorsState => {
  if (!action.payload) return { ...state };
  const errors: Partial<IMonitorsStateErrors> = action.payload;
  return {
    ...state,
    errors: {
      update: '',
      create: '',
      search: '',
      delete: '',
      ...errors,
    },
  };
};

const removeAssociatedScreenFromMonitors = (
  monitorsState: IMonitorsState, 
  action: Action<number>,
): IMonitorsState => {
  if (!action.payload) return { ...monitorsState };

  const screenId = action.payload;

  return {
    ...monitorsState,
    data: monitorsState.data.map((monitor) => {
      if (monitor.screen && monitor.screen.id !== screenId) return { ...monitor };

      return {
        ...monitor,
        screen: undefined,
      };
    }),
  };  
};

const suspendMonitorScreenByScreenId = (
  state: IMonitorsState,
  action: Action<Partial<number>>,
): IMonitorsState => {
  if (!action.payload) return { ...state };

  const screenId = action.payload;

  return {
    ...state,
    data: [
      ...state.data.map((monitor) => {
        return {
          ...monitor,
          screen: monitor.screen && monitor.screen.id === screenId ? {
            ...monitor.screen,
            active: false,
          } : monitor.screen,
        };
      }),
    ],
  };
};

const suspendMonitorById = (monitorsState: IMonitorsState, action: Action<number>): IMonitorsState => {
  if (!action.payload) return { ...monitorsState };

  const monitorId = action.payload;

  return {
    ...monitorsState,
    data: [
      ...monitorsState.data.map((monitor) => {
        return {
          ...monitor,
          active: monitor.id === monitorId ? false : monitor.active,
        };
      }),
    ],
  };
};


const activateMonitorById = (monitorsState: IMonitorsState, action: Action<number>): IMonitorsState => {
  if (!action.payload) return { ...monitorsState };

  const monitorId = action.payload;

  return {
    ...monitorsState,
    data: [
      ...monitorsState.data.map((monitor) => {
        return {
          ...monitor,
          active: monitor.id === monitorId ? true : monitor.active,
        };
      }),
    ],
  };
};

const activateMonitorScreenByScreenId = (
  state: IMonitorsState,
  action: Action<Partial<number>>,
): IMonitorsState => {
  if (!action.payload) return { ...state };

  const screenId = action.payload;

  return {
    ...state,
    data: [
      ...state.data.map((monitor) => {
        return {
          ...monitor,
          screen: monitor.screen && monitor.screen.id === screenId ? {
            ...monitor.screen,
            active: true,
          } : monitor.screen,
        };
      }),
    ],
  };
};


const monitorsReducer = handleActions<IMonitorsState, any>(
  {
    [ACTIONS.SET_MONITORS_DATA]: setMonitorsData,
    [ACTIONS.UPDATE_MONITOR_DATA_BY_ID]: updateMonitorDataById,
    [ACTIONS.UPDATE_MONITORS_ERRORS]: updateMonitorsErrors,
    [ACTIONS.RESET_MONITORS_STATE]: resetMonitorsState,
    [ACTIONS.CHECK_MONITOR_CAN_DELETE_SUCCEED]: checkMonitorCanDelete,
    [ACTIONS.CHECK_MONITOR_CAN_DELETE_FAILED]: checkMonitorCanDeleteFailed,
    [ACTIONS.DELETE_MONITOR_BY_ID]: deleteMonitorById,
    [ACTIONS.UPDATE_MONITORS_FILTERS]: updateMonitorsFilters,
    [ACTIONS.REMOVE_ASSOCIATED_SCREEN_FROM_MONITORS]: removeAssociatedScreenFromMonitors,
    [ACTIONS.SUSPEND_SCREEN_BY_ID]: suspendMonitorScreenByScreenId,
    [ACTIONS.SUSPEND_MONITOR_BY_ID]: suspendMonitorById,
    [ACTIONS.ACTIVATE_MONITOR_BY_ID]: activateMonitorById,
    [ACTIONS.ACTIVATE_SCREEN_BY_ID]: activateMonitorScreenByScreenId,
  },
  MONITORS_INITIAL_STATE,
);

export default monitorsReducer;
