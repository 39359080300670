import { IScreenState } from './ScreenInterfaces';

const SCREEN_INITIAL_STATE: IScreenState = {
  data: {
    id: 0,
    name: '',

    player: {
      id: 0,
    },

    monitor: {
      id: 0,
    },
  },
  errors: {

  },
};

export default SCREEN_INITIAL_STATE;
