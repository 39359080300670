import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';

export enum SideMenuItemPositions {
  queue = 1,
  bottom = 2,
}
export interface SideMenuItemProps {
  icon: JSX.Element;
  title: string;
  onClick?: Function;
  exact?: boolean;
  href: string;
  location?: any;
  isActive?: Function;
  customClassName?: string;
  position?: SideMenuItemPositions;
}

export default class SideMenuItem extends React.PureComponent<SideMenuItemProps> {

  static defaultProps: Partial<SideMenuItemProps> = {
    href: '#',
    position: SideMenuItemPositions.queue,
    onClick: () => {},
  };

  onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.props.onClick ? this.props.onClick(event, this.props) : () => {};
  }

  getClassName = () => this.props.customClassName || 'side-menu__link text-decoration-none';

  getActiveClassName = () => `${this.getClassName()}--active`;

  getPositionStyle = (): string => {
    if (this.props.position === SideMenuItemPositions.bottom) {
      return 'side-menu--bottom';
    }
    return '';
  }

  render() {
    return (
      <NavLink
        exact={this.props.exact}
        to={this.props.href}
        className={`${this.getClassName()} ${this.getPositionStyle()}`}
        location={this.props.location}
        activeClassName={this.getActiveClassName()}
        isActive={this.props.isActive as any}
      >
        <div className="side-menu__item" onClick={this.onClick}>
          <i className="side-menu__item__icon">
            {this.props.icon}
          </i>:
                <div>
            <label className="side-menu__item__title" >{this.props.title}</label>
          </div>
        </div>
      </NavLink>

    );
  }
}
