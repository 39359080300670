
import { IBrands } from './BrandsInterfaces';

const BRANDS_INITIAL_STATE: IBrands = {
  data: [],
  meta: {},
  errors: {
    delete: '',
  },
};

export default BRANDS_INITIAL_STATE;
