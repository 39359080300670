import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';

const initialState = {
    object: {}
  };
  
  const ruleEditor = (state = initialState, action: Action<any> ) => {
    switch (action.type) {
      case ACTIONS.SET_RULE_EDITOR:
        return {
          ...state,
          object: action.payload,
        };
      default:
        return state;
    }
  };

  const ruleEditors = handleActions<any, any>(
    {
      [ACTIONS.SET_RULE_EDITOR]: ruleEditor,         
    },
    initialState
  );
  

  export default ruleEditors;