export const authorization = {
  login: {
    method: 'post',
    url: '/login',
  },
  resetPassword: {
    method: 'post',
    url: '/resetPassword',
  },
  forgotPassword: {
    method: 'post',
    url: '/forgotPassword',
  },
  userExists: {
    method: 'get',
    url: '/userExists',
  },
};
