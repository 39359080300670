import FileService from '../services/third-party/FileService';

export const dataURLtoBlob = (dataurl: any) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n -= 1) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToDataURL = (blob: any): Promise<{ result: any }> => {
  return new Promise((fulfill, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e: any) => fulfill({ result: reader!.result });
    reader.readAsDataURL(blob);
  });
};


type RequestMode = 'cors' | 'no-cors' | 'navigate' | 'same-origin' | undefined;

export const base64FromImageUrl = async (name: string): Promise<string> => {
  
  const res = await FileService.fetchImage(name);
  const blob = await res.blob();


  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result || '');
      },
      false,
    );

    reader.onerror = () => {
      return reject('');
    };
    reader.readAsDataURL(blob);
  });
  
  return result as string;
};
