import React, { Component } from 'react';

interface IDottedSeperatorProps {
  className: string;
}

export default class DottedSeparator extends Component<IDottedSeperatorProps> {
  static defaultProps = {
    className: '',
  };
  
  render() {
    const { className } = this.props;
    
    return (
      <div className={`dotted-separator ${className}`} />
    );
  }
}
