import axios, { AxiosRequestConfig } from 'axios';

/**
 * Serves as a base to all the services.
 */

export interface IBaseService {
}

export const baseUrl = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;
export const mockUrl = process.env.REACT_APP_MOCK_URL;
export default class BaseService implements IBaseService {

  public static cancel: any;
  public static baseUrl = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;

  static cancelSource() {
    if (this.cancel == null) {
      this.cancel = axios.CancelToken.source();
    }
    return this.cancel;
  }

  static cancelToken() {
    return this.cancelSource().token;
  }

  static get(url = '', data = {}, axiosConfig?: AxiosRequestConfig) {
    const params = data;

    return axios.get(url, { params, ...axiosConfig, cancelToken: this.cancelToken() });
  }

  static post(url = '', data = {}, axiosConfig?: AxiosRequestConfig) {
    return axios.post(
      url,
      data,
      {
        ...axiosConfig,
        headers: {
          ...(axiosConfig ? axiosConfig!.headers : {}),
          Authorization: localStorage.getItem('token'),
        },
        cancelToken: this.cancelToken(),
      },
    );
  }

}
