import _ from "lodash";
import { handleActions, Action } from "redux-actions";

import * as ACTIONS from "../constants/actions";
import ThynkadsResponse from "../../dtos/ThynkadsResponse";
import ThynkadsResponseInitialState from "../../dtos/ThynkadsResponseInitialState";
import { IBrandData, IBrands } from "./BrandsInterfaces";
import BRANDS_INITIAL_STATE from "./BrandsInitialState";
import { extractDefinedProps } from "../../utilities/ObjectManipulation";

const setBrands = (
  brands: IBrands,
  action: Action<ThynkadsResponse<IBrandData[]>>
): IBrands => {
  const payload = action.payload! || ThynkadsResponseInitialState;
  const data = payload.data || BRANDS_INITIAL_STATE.data;

  return {
    ...brands,
    data,
  };
};

const resetBrandsState = (brands: IBrands, action: Action<null>): IBrands => ({
  ...BRANDS_INITIAL_STATE,
});

const updateBrandById = (
  brands: IBrands,
  action: Action<Partial<IBrandData>>
): IBrands => {
  if (!action.payload || !action.payload.id) return { ...brands };

  const newBrandData = action.payload;

  return {
    ...brands,
    data: brands.data.map((brand) => {
      if (brand.id !== newBrandData.id) return { ...brand };

      return {
        ...brand,
        ...extractDefinedProps(newBrandData),
      };
    }),
  };
};

const suspendBrand = (
  brandsState: IBrands,
  action: Action<{ id: number }>
): IBrands => {
  if (!action.payload) return { ...brandsState };

  const brandId = action.payload.id;
  return {
    ...brandsState,
    data: [
      ...brandsState.data.map((brand) => {
        return {
          ...brand,
          isActive: brand.id === brandId ? false : brand.isActive,
        };
      }),
    ],
  };
};

const deleteBrandSucceed = (
  brands: IBrands,
  action: Action<number>
): IBrands => {
  if (!action.payload) return { ...brands };

  const id = action.payload;

  return {
    ...brands,
    data: [...brands.data.filter((e) => e.id !== id)],
  };
};

const activateBrand = (
  brandsState: IBrands,
  action: Action<{ id: number }>
): IBrands => {
  if (!action.payload) return { ...brandsState };

  const brandId = action.payload.id;
  return {
    ...brandsState,
    data: [
      ...brandsState.data.map((brand) => {
        return {
          ...brand,
          isActive: brand.id === brandId ? true : brand.isActive,
        };
      }),
    ],
  };
};

const deleteBrandFailed = (
  brands: IBrands,
  action: Action<{ err: string }>
): IBrands => {
  if (!action.payload) return { ...brands };

  return {
    ...brands,
    errors: {
      delete: action.payload.err,
    },
  };
};

const brandsReducer = handleActions<IBrands, any>(
  {
    [ACTIONS.SET_BRANDS]: setBrands,
    [ACTIONS.RESET_BRANDS_STATE]: resetBrandsState,
    [ACTIONS.UPDATE_BRAND_BY_ID]: updateBrandById,
    [ACTIONS.SUSPEND_BRAND]: suspendBrand,
    [ACTIONS.ACTIVATE_BRAND]: activateBrand,
    [ACTIONS.DELETE_BRAND_BY_ID_SUCESS]: deleteBrandSucceed,
    [ACTIONS.DELETE_BRAND_BY_ID_FAIL]: deleteBrandFailed,
  },
  BRANDS_INITIAL_STATE
);

export default brandsReducer;
