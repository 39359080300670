import BaseService from './BaseService';
import api from './api';
import { IResetPasswordPayload } from '../payloads/session/ResetPasswordPayload';
import { IForgotPasswordPayload } from '../payloads/authentication/ForgotPasswordPayload';
/**
 * Service responsible of making authentication requests
 */
export default class AuthService extends BaseService {

  static login(username: string, password: string) {
    return api.login({ username, password });
  }

  static resetPassword(payload: IResetPasswordPayload) {
    return api.resetPassword(payload);
  }

  static forgotPassword(payload: IForgotPasswordPayload) {
    return api.forgotPassword(payload);
  }

  static userExists(payload: { email: string }) {
    return api.userExists(payload);
  }
}
