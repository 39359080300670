import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { OnImageChangeCallback, OnImageRejectCallback } from './interfaces';
import CONSTANTS from './constants';
import Icons from '../../../resources/assets/icons';

interface Props {
  onImageChange: OnImageChangeCallback;
  onImageReject: OnImageRejectCallback;
  imageUrl: string;
  instructionText: string;
  sizeText: string;
  instructionTextClass: string;
  sizeTextClass: string;
}

export default class ImageDropzone extends Component<Props> {
  static defaultProps = {
    imageUrl: '',
    instructionText: CONSTANTS.DEFAULT_INSTRUCTION_TEXT,
    sizeText: CONSTANTS.DEFAULT_SIZE_TEXT,
    instructionTextClass: '',
    sizeTextClass: '',
  };

  getAcceptedFormats = () => 'image/jpeg, image/png';

  handleOnAcceptChange = (files: File[]) => {
    const { onImageChange } = this.props;

    onImageChange(files);
  }

  handleOnRejectChange = (files: File[]) => {
    const { onImageReject } = this.props;

    onImageReject(files);
  }

  handleOnDrop = (accepted: File[], rejected: File[]) => {
    if (accepted!.length > 0) this.handleOnAcceptChange(accepted);
    if (rejected!.length > 0) this.handleOnRejectChange(rejected);
  }

  renderImage = (imageUrl: string) => {
    return (
      <img 
        className="dropzone-image" 
        src={imageUrl} 
      />
    );
  }

  renderInstructions = () => {
    const { instructionText, sizeText, instructionTextClass, sizeTextClass } = this.props;

    return (
      <div className="dropzone-instructions">
        <div className="instruction-content">
          <div className="instruction-icon">
            <Icons.UploadIcon
              width={CONSTANTS.DEFAULT_ICON_WIDTH}
              height={CONSTANTS.DEFAULT_ICON_HEIGHT}
              fill={CONSTANTS.DEFAULT_ICON_COLOR}
            />
          </div>
          <div className={instructionTextClass}>
            {instructionText}
          </div>
        </div>

        <div className="size-content">
          <div className={sizeTextClass}>
            {sizeText}
          </div>
        </div>
      </div>
    );
  }

  renderDropzoneInnerContent = () => {
    const { imageUrl } = this.props;

    const dropzoneContent: JSX.Element = imageUrl ? this.renderImage(imageUrl) : this.renderInstructions();

    return (
      <div className="dropzone-inner">
        {dropzoneContent}
      </div>
    );
  }

  render() {

    return (
      <div className="image-dropzone">
        <Dropzone
          className="dropzone-class"
          onDrop={this.handleOnDrop}
          multiple={false}
          accept={this.getAcceptedFormats()}
        >
          {this.renderDropzoneInnerContent()}
        </Dropzone>
      </div>
    );
  }
}
