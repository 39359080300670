import { IChainDTO } from "../dtos/chains/ChainDTO";
import { IChainData } from "../store/chain/ChainInterfaces";

export default class ChainMapper {
  public static mapDtoToStore = (dto: IChainDTO): IChainData => {
    return {
      id: dto.id,
      name: dto.name,
      rncCode: dto.rncCode,
      logo: dto.logo,
      active: dto.active,
      imageName: dto.imageName,
      createdAt: dto.createdAt,
      rates: dto.rates && dto.rates.map((rate) => rate),
      branches: dto.branches && dto.branches.map((branch) => branch),
      admins: dto.admins && dto.admins.map((admin) => admin),
      fatherCategories:
        dto.fatherCategories &&
        dto.fatherCategories.map((category) => category),
      screensWithCampaigns: 0,
      advertisersActive: dto.advertisersActive,
      advertisersByChain: dto.advertisersByChain,
    };
  };
}
