import React from 'react';

export interface ITextInput {
  tabIndex?: number;
  id?: string;
  disabled?: boolean;
  name?: string;
  value: string;
  onChange: Function;
  onClick?: Function;
  onFocus?: Function;
  onBlur?: Function;
  onKeyUp?: Function;
  containerClassName?: string;
  className?: string;
  required?: boolean;
  autoComplete?: string;
  min?: number;
  max?: number;
  icon?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  withLabel?: boolean;
}

export default class TextInput extends React.PureComponent<ITextInput> {

  static defaultProps: Partial<ITextInput> = {
    id: '',
    disabled: false,
    name: '',
    value: '',
    onClick: () => { },
    onFocus: () => { },
    onBlur: () => { },
    onKeyUp: () => { },
    type: 'text',
    required: true,
    autoComplete: 'on',
    icon: '',
    placeholder: '',
    tabIndex: 0,
    containerClassName: '',
    withLabel: true,
  };

  private onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (!this.props.onChange) return;
    this.props.onChange(val);
  }

  private onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (this.props.onClick) {
      this.props.onClick(event.target);
    }
  }

  private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event.target);
    }
  }

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(event.target.value);
    }
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event.target.value);
    }
  }

  renderInputContainer = () => {
    const {
      icon, value, className, tabIndex,
    } = this.props;

    return (
      <div>
        {icon && <span className={icon} />}
        <input
          id={this.props.id}
          name={this.props.name}
          type={this.props.type}
          className={`form-control ${className || ''}`}
          onChange={this.onTextChange}
          onFocus={this.onFocus}
          onKeyUp={this.onKeyUp}
          onClick={this.onClick}
          onBlur={this.onBlur}
          value={value}
          disabled={this.props.disabled}
          required={this.props.required}
          autoComplete={this.props.autoComplete}
          min={this.props.min}
          max={this.props.max}
          placeholder={this.props.placeholder}
          tabIndex={tabIndex}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={this.props.containerClassName}>
        <div className={`form-input`}>
          {this.props.withLabel &&
            <label id={`label-${this.props.id}`}>{this.props.label}</label>
          }
          {this.renderInputContainer()}
        </div>
      </div>
    );
  }
}
