export const screens = {
  searchScreens: {
    method: 'get',
    url: '/screens',
  },
  createScreen: {
    method: 'post',
    url: '/screens',
  },
  newScreen: {
    method: 'post',
    url: '/createNewScreen',
  },
  updateScreen: {
    method: 'post',
    url: '/screens/:id',
  },
  canDeleteScreen: {
    method: 'get',
    url: '/screens/:id/canDelete',
  },
  deleteScreen: {
    method: 'delete',
    url: '/screens/:id',
  },
  suspendScreen: {
    method: 'patch',
    url: '/screens/:id/suspend',
  },
  getScreensByChainId: {
    method: 'get',
    url: '/screens/chain/',
  },
  activateScreen: {
    method: 'patch',
    url: '/screens/:id/activate',
  },
};
