import React from 'react';

interface IChainCardProps {
  className: string;
  onClick?: VoidFunction;
}

export default class ChainCard extends React.PureComponent<IChainCardProps> {
  static defaultProps = {
    className: '',
  };

  handleOnClick = () => this.props.onClick && this.props.onClick();

  getClickableClass = () => this.props.onClick ? 'clickable' : '';

  render() {
    const { className } = this.props;

    return (
      <div 
        className={`chain-card ${this.getClickableClass()} ${className}`} 
        onClick={this.handleOnClick}
      >
        <div className="chain-card__content">
          {this.props.children}
        </div>
        <div className="chain-card__arrow">
          {'>'}
        </div>
      </div>
    );
  }
}
