import React, { Component, Fragment } from 'react';
import SolidSeparator from '../solid-separator';
import CONSTANTS from './constants';
import { IStep, StepCallback } from './interfaces';
import Icons from '../../../resources/assets/icons';

interface Props {
  steps: IStep[];
  currentStepIndex: number;
  className: string;
}

export default class StepTracker extends Component<Props> {
  static defaultProps = {
    steps: [],
    currentStepIndex: 0,
    className: '',
  };

  getClassFromPosition = (currentPos: number, length: number) => {
    const { currentStepIndex }: { currentStepIndex: number } = this.props;

    if (currentPos === currentStepIndex) return CONSTANTS.CURRENT;
    if (currentPos < currentStepIndex) return CONSTANTS.DONE;
    
    return CONSTANTS.PENDING;
  } 

  getCircleType = (stepStatus: string) => {
    if (stepStatus === CONSTANTS.CURRENT) return CONSTANTS.CURRENT_CIRCLE;
    if (stepStatus === CONSTANTS.DONE) return CONSTANTS.DONE_CIRCLE;
    if (stepStatus === CONSTANTS.PENDING) return CONSTANTS.PENDING_CIRCLE;

    return '';
  }

  renderCircleNumber = (pos: number, stepStatus: string) => {
    const circleType = this.getCircleType(stepStatus);
    return (
      <div className="circle-container">
        <div className={`circle ${circleType}`}>
          <div className="icon-number pdB02">
            <span>{pos}</span>
          </div>
        </div>
      </div>
    );    
  }

  renderCircleCheck = () => {
    return (
        <Icons.CircleCheck 
          width={CONSTANTS.ICON_WIDTH}
          height={CONSTANTS.ICON_HEIGHT}
          fill={CONSTANTS.ICON_COLOR}
        />
    );
  }

  renderIcon = (stepStatus: string, pos: number) => 
    stepStatus === CONSTANTS.DONE ? 
      this.renderCircleCheck() : 
        this.renderCircleNumber(pos, stepStatus)

  renderSeparator = () => (<SolidSeparator />);

  getOnclickHandle = (callback: StepCallback, pos: number) => () => callback(pos);

  render() {
    const { className, steps } = this.props;

    return (
      <div className={`step-tracker ${className}`}>
          {steps.map((step, i, arr) => {
            const stepClass = this.getClassFromPosition(i, arr.length);
            const lastClass = (i === arr.length - 1) ? CONSTANTS.LAST : '';

            return (
              <Fragment key={i}>
                <div 
                  className={`step-content ${stepClass}`} 
                >
                  <div className="step-icon square-20 mgR06">
                    {this.renderIcon(stepClass, i + 1)}
                  </div>
                  <div className="step-name mgR05">
                    {step.name}
                  </div>
                </div>
                {!lastClass && 
                  <div className="step-separator mgR05">
                    {this.renderSeparator()}
                  </div>
                }
              </Fragment>
            );
          })}
      </div>
    );
  }
}
