import { lazy } from 'react';

const UserList  = lazy(() => import('./user-list'));
const CreateUser  = lazy(() => import('./create-user'));
const UserDetail  = lazy(() => import('./user-details'));

export default {
  UserList,
  CreateUser,
  UserDetail,
};
