import { lazy } from 'react';

const AdvertiserList = lazy(() => import('./advertiser-list'));;
const AdvertiserDetail = lazy(() => import('./advertiser-detail'));;
const CreateAdvertiser = lazy(() => import('./create-advertiser'));;
const AdvertiserEdit = lazy(() => import('./advertiser-edit'));;
const AdvertiserAccount = lazy(() => import('./advertiser-account'));;
const AdvertiserAccountEdit = lazy(() => import('./advertiser-account-edit'));;

export default {
  AdvertiserList,
  AdvertiserDetail,
  CreateAdvertiser,
  AdvertiserEdit,
  AdvertiserAccount,
  AdvertiserAccountEdit,
};
