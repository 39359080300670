import { IUiState } from './IUiInterfaces';

const UI_INITIAL_STATE: IUiState = {
  chainDetail: {
    tabIndex: 0,
  },
  branchDetail: {
    tabIndex: 0,
  },
  advertiserDetail: {
    tabIndex: 0,
    currentStep: 0,
  },
  globalError: {
    show: false,
    title: '',
    message: '',
    source: '',
  },
  categoryDetail: {
    tabIndex: 0,
  },
};

export default UI_INITIAL_STATE;
