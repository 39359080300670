import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IPlayerState, IPlayerStateData, IPlayerStateErrors } from './PlayerInterfaces';
import PLAYER_INITIAL_STATE from './PlayerInitialState';

const updatePlayerData = (playerState: IPlayerState, action: Action<Partial<IPlayerStateData>>): IPlayerState => {
  if (!action.payload) return { ...playerState };

  const newPlayerData: Partial<IPlayerStateData> = action.payload;

  return ({
    ...playerState,
    data: {
      ...playerState.data,
      ...extractDefinedProps(newPlayerData),
    },
  });  
};

const updatePlayerErrors = (playerState: IPlayerState, action: Action<Partial<IPlayerStateErrors>>): IPlayerState => {
  if (!action.payload) return { ...playerState };

  const newPlayerErrors: Partial<IPlayerStateErrors> = action.payload;

  return {
    ...playerState,
    errors: {
      ...playerState.errors,
      ...extractDefinedProps(newPlayerErrors),
    },
  };  
};

const resetPlayerState = (playerState: IPlayerState, action: Action<null>): IPlayerState => ({
  ...PLAYER_INITIAL_STATE,
});

const playerReducer = handleActions<IPlayerState, any>(
  {
    [ACTIONS.UPDATE_PLAYER_DATA]: updatePlayerData,
    [ACTIONS.UPDATE_PLAYER_ERRORS]: updatePlayerErrors,
    [ACTIONS.RESET_PLAYER_STATE]: resetPlayerState,
  },
  PLAYER_INITIAL_STATE,
);

export default playerReducer;
