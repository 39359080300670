import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as serviceWorker from './serviceWorker';

require('moment/locale/es.js');
import moment from 'moment';
moment.locale('es');

import es from 'date-fns/locale/es';
import { registerLocale } from 'react-datepicker';
registerLocale('es', es);

import 'rc-tooltip/assets/bootstrap.css';

import './index.css';
import './resources/scss/main.scss';
import Routes from './routes';
import store from './common/store';

import Modal from 'react-modal';

Modal.setAppElement('#root');

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading= {null} persistor={persistor}>
      <Routes />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
