import React, { Component } from 'react';

interface ISolidSeparatorProps {
  className: string;
}

export default class SolidSeparator extends Component<ISolidSeparatorProps> {
  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <div className={`solid-separator ${this.props.className}`} />
    );
  }
}
