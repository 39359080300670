// SESSION

export const AUTHENTICATE = "AUTHENTICATE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCEED = "LOGIN_SUCCEED";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCEED = "LOGOUT_SUCCEED";
export const CLEAR_SESSION_ERROR = "CLEAR_ESSION_ERROR";
export const SET_SESSION_ERROR = "LOGIN_FAIL";
export const SET_SESSION_DATA_ATTRIBUTE = "SET_SESSION_DATA_ATTRIBUTE";
export const RESET_PASSWORD_SUCCEED = "RESET_PASSWORD_SUCCEED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const UPDATE_SESSION_DATA = "UPDATE_SESSION_DATA";

// DASHBOARD
export const SET_DASHBOARD_ADMIN_STATS = "SET_DASHBOARD_ADMIN_STATS";
export const SET_DASHBOARD_CHAIN_ADMIN_STATS =
  "SET_DASHBOARD_CHAIN_ADMIN_STATS";
export const SET_DASHBOARD_ACCOUNT_MANAGER_STATS =
  "SET_DASHBOARD_ACCOUNT_MANAGER_STATS";
export const SET_DASHBOARD_ADVERTISER_ADMIN_STATS =
  "SET_DASHBOARD_ADVERTISER_ADMIN_STATS";
export const GET_DASHBOARD_SLOTS_USAGE_SUCCESS =
  "GET_DASHBOARD_SLOTS_USAGE_SUCCESS";
export const GET_DASHBOARD_SLOTS_USAGE_FAIL = "GET_DASHBOARD_SLOTS_USAGE_FAIL";
export const GET_ADVERTISER_DASHBOARD_SLOTS_USAGE_SUCCESS =
  "GET_ADVERTISER_DASHBOARD_SLOTS_USAGE_SUCCESS";
export const GET_ADVERTISER_DASHBOARD_SLOTS_USAGE_FAIL =
  "GET_ADVERTISER_DASHBOARD_SLOTS_USAGE_FAIL";

// RNC

export const RNC_SUCCEED = "RNC_SUCCEED";
export const RNC_FAIL = "RNC_FAIL";
export const SET_RNC_ERROR = "RNC_FAIL";
export const SET_RNC_DATA_ATTRIBUTE = "SET_RNC_DATA_ATTRIBUTE";
export const CLEAR_RNC_ERROR = "CLEAR_RNC_ERROR";

// CHAIN
export const SET_CHAIN_DATA_ATTRIBUTE = "SET_CHAIN_DATA_ATTRIBUTE";
export const SET_CHAIN_META_DATA_ATTRIBUTE = "SET_CHAIN_META_DATA_ATTRIBUTE";
export const SET_CHAIN_RATES_ATTRIBUTE = "SET_CHAIN_RATES_ATTRIBUTE";
export const CREATE_CHAIN_SUCCEED = "CREATE_CHAIN_SUCCEED";
export const CREATE_CHAIN_FAIL = "CREATE_CHAIN_FAIL";
export const EDIT_CHAIN_SUCCEED = "EDIT_CHAIN_SUCCEED";
export const EDIT_CHAIN_FAIL = "EDIT_CHAIN_FAIL";

export const SET_CHAIN_ERROR = "SET_CHAIN_ERROR";
export const GET_CHAIN_BY_ID_SUCCEED = "GET_CHAIN_BY_ID_SUCCEED";
export const GET_CHAIN_BY_ID_FAIL = "GET_CHAIN_BY_ID_FAIL";
export const SET_CHAIN_ADMINS = "SET_CHAIN_ADMINS";
export const RESET_CHAIN_ATTRIBUTES = "RESET_CHAIN_ATTRIBUTES";

// CHAINS
export const SET_CHAINS = "SET_CHAINS";
export const ADD_CHAIN = "ADD_CHAIN";
export const DELETE_CHAIN_SUCCEED = "DELETE_CHAIN_SUCCEED";
export const DELETE_CHAIN_FAIL = "DELETE_CHAIN_FAIL";
export const EDIT_CHAIN_ATTRIBUTE = "EDIT_CHAIN_ATTRIBUTE";
export const SET_CHAINS_ERROR = "SET_CHAINS_ERROR";
export const RESET_CHAINS_STATE = "RESET_CHAINS_STATE";
export const UPDATE_CHAINS_BRANCH_AREAS_SLOTS_AVAILABILITY =
  "UPDATE_CHAINS_BRANCH_AREAS_SLOTS_AVAILABILITY";
export const RESET_CHAINS_SLOTS_STATE = "RESET_CHAINS_SLOTS_STATE";

// BRANCH
export const UPDATE_BRANCH_DATA = "UPDATE_BRANCH_DATA";
export const RESET_BRANCH_STATE = "RESET_BRANCH_STATE";
export const UPDATE_BRANCH_STATS = "UPDATE_BRANCH_STATS";
export const UPDATE_BRANCH_ERRORS = "UPDATE_BRANCH_ERRORS";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILED = "CREATE_BRANCH_FAILED";
export const GET_BRANCH_BY_ID_SUCCESS = "GET_BRANCH_BY_ID_SUCCESS";
export const GET_BRANCH_BY_ID_FAILED = "GET_BRANCH_BY_ID_FAILED";
export const UPDATE_BRANCH_DATA_SUCCESS = "UPDATE_BRANCH_DATA_SUCCESS";
export const UPDATE_BRANCH_DATA_FAILED = "UPDATE_BRANCH_DATA_FAILED";
export const SUSPEND_BRANCH_BY_ID = "SUSPEND_BRANCH_BY_ID";
export const ACTIVATE_BRANCH_BY_ID = "ACTIVATE_BRANCH_BY_ID";
export const ADD_BRANCH_INFORMATION = "ADD_BRANCH_INFORMATION";
export const ADD_BRANCH_INFORMATION_RESET = "ADD_BRANCH_INFORMATION_RESET";

// BRANCHES
export const SET_BRANCHES_DATA = "SET_BRANCHES_DATA";
export const UPDATE_BRANCHES_DATA = "UPDATE_BRANCHES_DATA";
export const UPDATE_BRANCHES_ERRORS = "UPDATE_BRANCHES_ERRORS";
export const RESET_BRANCHES_STATE = "RESET_BRANCHES_STATE";

// BRANDS
export const SET_BRANDS = "SET_BRANDS";
export const RESET_BRANDS_STATE = "RESET_BRANDS_STATE";
export const SET_BRANDS_ERROR = "SET_BRANDS_ERROR";
export const UPDATE_BRAND_BY_ID = "UPDATE_BRAND_BY_ID";
export const DELETE_BRAND_BY_ID_SUCESS = "DELETE_BRAND_BY_ID_SUCESS";
export const DELETE_BRAND_BY_ID_FAIL = "DELETE_BRAND_BY_ID_FAIL";

// CAMPAIGNS
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const RESET_CAMPAIGNS_STATE = "RESET_CAMPAIGNS_STATE";
export const SET_CAMPAIGNS_ERROR = "SET_CAMPAIGNS_ERROR";
export const CAMPAIGNS_SEARCH_STARTED = "CAMPAIGNS_SEARCH_STARTED";
export const CAMPAIGNS_SEARCH_FINISHED = "CAMPAIGNS_SEARCH_FINISHED";
export const CAMPAIGNS_SEARCH_SUCCESS = "CAMPAIGNS_SEARCH_SUCCESS";

// PRODUCTS
export const SET_PRODUCTS = "SET_PRODUCTS";
export const RESET_PRODUCTS_STATE = "RESET_PRODUCTS_STATE";
export const SET_PRODUCTS_ERROR = "SET_PRODUCTS_ERROR";

// RESERVATIONS
export const SET_RESERVATIONS = "SET_RESERVATIONS";
export const RESET_RESERVATIONS_STATE = "RESET_RESERVATIONS_STATE";
export const SET_RESERVATIONS_ERROR = "SET_RESERVATIONS_ERROR";
export const RESERVATIONS_SEARCH_STARTED = "RESERVATIONS_SEARCH_STARTED";
export const RESERVATIONS_SEARCH_FINISHED = "RESERVATIONS_SEARCH_FINISHED";
export const RESERVATIONS_SEARCH_SUCCESS = "RESERVATIONS_SEARCH_SUCCESS";
export const BILLED_RESERVATIONS_SEARCH_SUCCESS =
  "BILLED_RESERVATIONS_SEARCH_SUCCESS";
export const REMOVE_BRANCH_FROM_RESERVATION_CHAIN =
  "REMOVE_BRANCH_FROM_RESERVATION_CHAIN";
export const PRE_RESERVATIONS_SEARCH_SUCCESS =
  "PRE_RESERVATIONS_SEARCH_SUCCESS";


// RESERVATION
export const UPDATE_RESERVATION_DATA = "UPDATE_RESERVATION_DATA";
export const UPDATE_RESERVATION_CHAINS_DATA = "UPDATE_RESERVATION_CHAINS_DATA";
export const RESET_RESERVATION_STATE = "RESET_RESERVATION_STATE";
export const RESET_BLOCK_RESERVATION_STATE = "RESET_BLOCK_RESERVATION_STATE";
export const UPDATE_RESERVATION_ERRORS = "UPDATE_RESERVATION_ERRORS";
export const GET_RESERVATION_BY_ID_SUCCEED = "GET_RESERVATION_BY_ID_SUCCEED";
export const GET_RESERVATION_BY_ID_FAIL = "GET_RESERVATION_BY_ID_FAIL";
export const ADD_UNIQUE_AREA_TO_RESERVATION_BRANCH =
  "ADD_UNIQUE_AREA_TO_RESERVATION_BRANCH";
export const REMOVE_AREA_FROM_RESERVATION_BRANCH =
  "REMOVE_AREA_FROM_RESERVATION_BRANCH";
export const ADD_UNIQUE_BRANCH_TO_RESERVATION_CHAIN =
  "ADD_UNIQUE_BRANCH_TO_RESERVATION_CHAIN";
export const ADD_RESERVATION_BLOCK = "ADD_RESERVATION_BLOCK";
export const REMOVE_RESERVATION_BLOCK = "REMOVE_RESERVATION_BLOCK";
export const UPDATE_RESERVATION_PRICE_PER_BLOCKS_DATA =
  "UPDATE_RESERVATION_PRICE_PER_BLOCKS_DATA";

// BRAND
export const UPDATE_BRAND_DATA = "UPDATE_BRAND_DATA";
export const ACTIVATE_BRAND = "ACTIVATE_BRAND";
export const SUSPEND_BRAND = "SUSPEND_BRAND";
export const RESET_BRAND_STATE = "RESET_BRAND_STATE";
export const SET_CATEGORY_TO_BRAND = "SET_CATEGORY_OPTION_TO_BRAND";
export const SET_TYPE_TO_BRAND = "SET_TYPE_OPTION_TO_BRAND";
export const UPDATE_BRAND_STATS = "UPDATE_BRAND_STATS";
export const UPDATE_BRAND_ERRORS = "UPDATE_BRAND_ERRORS";
export const UPDATE_BRAND_META = "UPDATE_BRAND_META";
export const GET_BRAND_BY_ID_SUCCEED = "GET_BRAND_BY_ID_SUCCEED";
export const GET_BRAND_BY_ID_FAIL = "GET_BRAND_BY_ID_FAIL";
export const RESET_BRAND_ERRORS_STATE = "RESET_BRAND_ERRORS_STATE";
export const RESET_BRAND_ERRORS_NAME_STATE = "RESET_BRAND_ERRORS_NAME_STATE";
export const UPDATE_BRAND_ERRORS_CATEGORY_EDITABLE =
  "UPDATE_BRAND_ERRORS_CATEGORY_EDITABLE";

// PRODUCT
export const UPDATE_PRODUCT_DATA = "UPDATE_PRODUCT_DATA";
export const RESET_PRODUCT_STATE = "RESET_PRODUCT_STATE";
export const SET_CATEGORY_TO_PRODUCT = "SET_CATEGORY_OPTION_TO_PRODUCT";
export const UPDATE_PRODUCT_ERRORS = "UPDATE_PRODUCT_ERRORS";
export const UPDATE_PRODUCT_META = "UPDATE_PRODUCT_META";
export const RESET_PRODUCT_ERRORS_STATE = "RESET_PRODUCT_ERRORS_STATE";
export const GET_PRODUCT_BY_ID_SUCCEED = "GET_PRODUCT_BY_ID_SUCCEED";
export const GET_PRODUCT_BY_ID_FAIL = "GET_PRODUCT_BY_ID_FAIL";

// Categories
export const SEARCH_CATEGORIES_SUCCESS = "SEARCH_CATEGORIES_SUCCESS";
export const SEARCH_CATEGORIES_ERROR = "SEARCH_CATEGORIES_ERROR";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORIES_ERROR = "SET_CATEGORIES_ERROR";
export const SET_CATEGORY_CREATING_STATUS = "SET_CATEGORY_CREATING_STATUS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
export const UPDATE_CATEGORY_STATUS_SUCCESS = "UPDATE_CATEGORY_STATUS_SUCCESS";
export const UPDATE_CATEGORY_STATUS_ERROR = "UPDATE_CATEGORY_STATUS_ERROR";
export const UPDATE_EXISTING_CATEGORY_IN_LIST =
  "UPDATE_EXISTING_CATEGORY_IN_LIST";
export const RESET_CATEGORIES = "RESET_CATEGORIES";

// CATEGORY
export const SET_CATEGORY = "SET_CATEGORY";
export const ADD_CHAIN_TO_CATEGORY = "ADD_CHAIN_TO_CATEGORY";
export const REMOVE_CHAIN_FROM_CATEGORY = "REMOVE_CHAIN_FROM_CATEGORY";
export const UPDATE_CHAIN_IN_CATEGORY = "UPDATE_CHAIN_IN_CATEGORY";
export const GET_CATEGORY_BY_ID_FAIL = "GET_CATEGORY_BY_ID_FAIL";
export const RESET_CATEGORY_TO_INITIAL_STATE = "RESET_CATEGORY_INITIAL_STATE";
export const UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED";

// CONTACTS
export const SET_CONTACTS = "SET_CONTACTS";

// SCREENS
export const SET_SCREENS_DATA = "SET_SCREENS_DATA";
export const UPDATE_SCREENS_ERRORS = "UPDATE_SCREENS_ERRORS";
export const RESET_SCREENS_STATE = "RESET_SCREENS_STATE";
export const SUSPEND_SCREEN_BY_ID = "SUSPEND_SCREEN_BY_ID";
export const GET_SCREENS_BY_CHAIN_ID_SUCCESS =
  "GET_SCREENS_BY_CHAIN_ID_SUCCESS";
export const ACTIVATE_SCREEN_BY_ID = "ACTIVATE_SCREEN_BY_ID";

// SCREEN
export const UPDATE_SCREEN_DATA = "UPDATE_SCREEN_DATA";
export const RESET_SCREEN_STATE = "RESET_SCREEN_STATE";
export const UPDATE_SCREEN_ERRORS = "UPDATE_SCREEN_ERRORS";
export const CHECK_SCREEN_CAN_DELETE_SUCCESS =
  "CHECK_SCREEN_CAN_DELETE_SUCCESS";
export const CHECK_SCREEN_CAN_DELETE_FAILED = "CHECK_SCREEN_CAN_DELETE_FAILED";
export const DELETE_SCREEN_BY_ID = "DELETE_SCREEN_BY_ID";

// MONITOR
export const UPDATE_MONITOR_ERRORS = "UPDATE_MONITOR_ERRORS";
export const CHECK_MONITOR_CAN_DELETE_SUCCEED = "CHECK_MONITOR_CAN_DELETE";
export const CHECK_MONITOR_CAN_DELETE_FAILED =
  "CHECK_MONITOR_CAN_DELETE_FAILED";
export const DELETE_MONITOR_BY_ID = "DELETE_MONITOR_DATA_BY_ID";
export const UPDATE_MONITOR_DATA_BY_ID = "UPDATE_MONITOR_DATA_BY_ID";

// MONITORS
export const SET_MONITORS_DATA = "SET_MONITORS_DATA";
export const UPDATE_MONITORS_ERRORS = "UPDATE_MONITORS_ERRORS";
export const RESET_MONITORS_STATE = "RESET_MONITORS_STATE";
export const UPDATE_MONITORS_FILTERS = "UPDATE_MONITORS_FILTERS";
export const REMOVE_ASSOCIATED_SCREEN_FROM_MONITORS =
  "REMOVE_ASSOCIATED_SCREEN_FROM_MONITORS";
export const SUSPEND_MONITOR_BY_ID = "SUSPEND_MONITOR_BY_ID";
export const ACTIVATE_MONITOR_BY_ID = "ACTIVATE_MONITOR_BY_ID";

// PLAYERS
export const SET_PLAYERS_DATA = "SET_PLAYERS_DATA";
export const UPDATE_PLAYERS_ERRORS = "UPDATE_PLAYERS_ERRORS";
export const RESET_PLAYERS_STATE = "RESET_PLAYERS_STATE";
export const UPDATE_PLAYERS_DATA_BY_ID = "UPDATE_PLAYERS_DATA_BY_ID";
export const DELETE_PLAYERS_DATA_BY_ID = "DELETE_PLAYERS_DATA_BY_ID";
export const UPDATE_PLAYERS_FILTERS = "UPDATE_PLAYERS_FILTERS";
export const REMOVE_ASSOCIATED_SCREEN_FROM_PLAYERS =
  "REMOVE_ASSOCIATED_SCREEN_FROM_PLAYERS";
export const SUSPEND_PLAYER_BY_ID = "SUSPEND_PLAYER_BY_ID";
export const ACTIVATE_PLAYER_BY_ID = "ACTIVATE_PLAYER_BY_ID";

// PLAYER
export const UPDATE_PLAYER_DATA = "UPDATE_PLAYER_DATA";
export const RESET_PLAYER_STATE = "RESET_PLAYER_STATE";
export const UPDATE_PLAYER_ERRORS = "UPDATE_PLAYER_ERRORS";

// RESERVATION
export const CREATE_RESERVATION_FAIL = "CREATE_RESERVATION_FAIL";
export const CREATE_RESERVATION_SUCCESS = "CREATE_RESERVATION_SUCCESS";

// CAMPAIGN
export const UPDATE_CAMPAIGN_DATA = "UPDATE_CAMPAIGN_DATA";
export const UPDATE_OFFER_CAMPAIGN_DATA = "UPDATE_OFFER_CAMPAIGN_DATA";
export const UPDATE_CAMPAIGN_CHAINS_DATA = "UPDATE_CAMPAIGN_CHAINS_DATA";
export const RESET_CAMPAIGN_STATE = "RESET_CAMPAIGN_STATE";
export const RESET_CAMPAIGN_CHAINS_STATE = "RESET_CAMPAIGN_CHAINS_STATE";
export const UPDATE_CAMPAIGN_ERRORS = "UPDATE_CAMPAIGN_ERRORS";
export const GET_CAMPAIGN_BY_ID_SUCCEED = "GET_CAMPAIGN_BY_ID_SUCCEED";
export const GET_CAMPAIGN_BY_ID_FAIL = "GET_CAMPAIGN_BY_ID_FAIL";
export const ADD_UNIQUE_BRANCH_TO_CAMPAIGN_CHAIN =
  "ADD_UNIQUE_BRANCH_TO_CAMPAIGN_CHAIN";
export const ADD_UNIQUE_AREA_TO_CAMPAIGN_BRANCH =
  "ADD_UNIQUE_AREA_TO_CAMPAIGN_BRANCH";
export const ADD_SECTION_TO_RESERVATION = "ADD_SECTION_TO_CAMPAIGN";
export const REMOVE_SECTION_FROM_RESERVATION = "REMOVE_SECTION_FROM_CAMPAIGN";
export const REMOVE_BRANCH_FROM_CAMPAIGN_CHAIN =
  "REMOVE_BRANCH_FROM_CAMPAIGN_CHAIN";
export const REMOVE_AREA_FROM_CAMPAIGN_BRANCH =
  "REMOVE_AREA_FROM_CAMPAIGN_BRANCH";
export const APPROVE_CAMPAIGN_SUCCEED = "APPROVE_CAMPAIGN_SUCCEED";
export const APPROVE_CAMPAIGN_FAIL = "APPROVE_CAMPAIGN_FAIL";
export const DECLINE_CAMPAIGN_SUCCEED = "DECLINE_CAMPAIGN_SUCCEED";
export const DECLINE_CAMPAIGN_FAIL = "DECLINE_CAMPAIGN_FAIL";
export const DELETE_CAMPAIGN_SUCCEED = "DECLINE_CAMPAIGN_SUCCEED";
export const DELETE_CAMPAIGN_FAIL = "DECLINE_CAMPAIGN_FAIL";
export const CHECKOUT_CAMPAIGN_SUCCEED = "CHECKOUT_CAMPAIGN_SUCCEED";
export const CHECKOUT_CAMPAIGN_FAIL = "CHECKOUT_CAMPAIGN_FAIL";
export const UPDATE_CAMPAIGN_SUCCEED = "UPDATE_CAMPAIGN_SUCCEED";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";
export const VALIDATE_CAMPAIGN_PERMISSIONS_FOR_ADVERTISER_ADMIN_SUCCESS =
  "VALIDATE_CAMPAIGN_PERMISSIONS_FOR_ADVERTISER_ADMIN_SUCCESS";
export const VALIDATE_CAMPAIGN_PERMISSIONS_FOR_SUPER_ADMIN_SUCCESS =
  "VALIDATE_CAMPAIGN_PERMISSIONS_FOR_SUPER_ADMIN_SUCCESS";
export const VALIDATE_CAMPAIGN_PERMISSIONS_DEFAULT_SUCCESS =
  "VALIDATE_CAMPAIGN_PERMISSIONS_DEFAULT_SUCCESS";
export const VALIDATE_CAMPAIGN_PERMISSIONS_FOR_CURRENT_USER_FAIL =
  "VALIDATE_CAMPAIGN_PERMISSIONS_FOR_CURRENT_USER_FAIL";
export const RESET_CAMPAIGN_MEDIA_STATE = "RESET_CAMPAIGN_MEDIA_STATE";
export const GET_CAMPAIGN_COSTS_SUCCESS = "GET_CAMPAIGN_COSTS_SUCCESS";
export const GET_CAMPAIGN_COSTS_FAIL = "GET_CAMPAIGN_COSTS_FAIL";
export const DISCARD_CAMPAIGN_CHANGES_SUCCESS =
  "DISCARD_CAMPAIGN_CHANGES_SUCCESS";
export const DISCARD_CAMPAIGN_CHANGES_FAIL = "DISCARD_CAMPAIGN_CHANGES_FAIL";

// ADVERTISER
export const SET_ADVERTISER_IS_EDITING_SINGLE_STEP =
  "SET_ADVERTISER_IS_EDITING_SINGLE_STEP";
export const GET_ADVERTISER_BY_ID_SUCCEED = "GET_ADVERTISER_BY_ID_SUCCEED";
export const GET_ADVERTISER_BY_ID_FAIL = "GET_ADVERTISER_BY_ID_FAIL";
export const UPDATE_ADVERTISER_DATA = "UPDATE_ADVERTISER_DATA";
export const RESET_ADVERTISER_STATE = "RESET_ADVERTISER_STATE";
export const UPDATE_ADVERTISER_ERRORS = "UPDATE_ADVERTISER_ERRORS";
export const UPDATE_ADVERTISER_ERRORS_BY_RNC =
  "UPDATE_ADVERTISER_ERRORS_BY_RNC";
export const SET_ADVERTISER_ADMIN = "SET_ADVERTISER_ADMIN";
export const UPDATE_ADVERTISER_DATA_BRANDS_BY_INDEX =
  "UPDATE_ADVERTISER_DATA_BRANDS_BY_INDEX";
export const ADD_ADVERTISER_DATA_BRANDS_BY_INDEX =
  "ADD_ADVERTISER_DATA_BRANDS_BY_INDEX";
export const ADVERTISER_EMAIL_EXISTS = "ADVERTISER_EMAIL_EXISTS";
export const ADVERTISER_EMAIL_EXISTS_FAIL = "ADVERTISER_EMAIL_EXISTS_FAIL";
export const ADVERTISER_ID_EXISTS = "ADVERTISER_ID_EXISTS";
export const ADVERTISER_ID_EXISTS_FAIL = "ADVERTISER_ID_EXISTS_FAIL";
export const UPDATE_ADVERTISER_DATA_CHAINS_BY_INDEX =
  "UPDATE_ADVERTISER_DATA_CHAINS_BY_INDEX";
export const ADD_EMPTY_BRAND_TO_ADVERTISER = "ADD_EMPTY_BRAND_TO_ADVERTISER";
export const CREATE_ADVERTISER_SUCCEED = "CREATE_ADVERTISER_SUCCEED";
export const CREATE_ADVERTISER_FAIL = "CREATE_ADVERTISER_FAIL";
export const EDIT_ADVERTISER_BY_ID_SUCCESS = "EDIT_ADVERTISER_BY_ID_SUCCESS";
export const EDIT_ADVERTISER_BY_ID_FAIL = "EDIT_ADVERTISER_BY_ID_FAIL";
export const REMOVE_BRAND_TO_ADVERTISER = "REMOVE_BRAND_TO_ADVERTISER";
export const GET_ADVERTISER_BY_USER_ID_SUCCESS =
  "GET_ADVERTISER_BY_USER_ID_SUCCESS";
export const GET_ADVERTISER_BY_USER_ID_FAIL = "GET_ADVERTISER_BY_USER_ID_FAIL";
export const DELETE_ADVERTISER_BY_ID_SUCCESS =
  "DELETE_ADVERTISER_BY_ID_SUCCESS";
export const DELETE_ADVERTISER_BY_ID_FAIL = "DELETE_ADVERTISER_BY_ID_FAIL";
export const FETCH_CURRENT_ADVERTISER_ID_SUCCESS =
  "FETCH_CURRENT_ADVERTISER_ID_SUCCESS";
export const FETCH_CURRENT_ADVERTISER_ID_FAIL =
  "FETCH_CURRENT_ADVERTISER_ID_FAIL";
export const SET_AZUL_PAYMENT_DATA_VAULT_TOKEN =
  "SET_AZUL_PAYMENT_DATA_VAULT_TOKEN";

// ADVERTISERS
export const SET_ADVERTISERS_DATA = "SET_ADVERTISERS_DATA";
export const UPDATE_ADVERTISERS_DATA = "UPDATE_ADVERTISERS_DATA";
export const UPDATE_ADVERTISERS_ERRORS = "UPDATE_ADVERTISERS_ERRORS";
export const RESET_ADVERTISERS_STATE = "RESET_ADVERTISERS_STATE";
export const UPDATE_ADVERTISER_SUSPENDABLE_BY_ID =
  "UPDATE_ADVERTISER_SUSPENDABLE_BY_ID";
export const UPDATE_ADVERTISER_REMOVABLE_BY_ID =
  "UPDATE_ADVERTISER_REMOVABLE_BY_ID";
export const SUSPEND_ADVERTISER_BY_ID = "SUSPEND_ADVERTISER_BY_ID";
export const ACTIVATE_ADVERTISER_BY_ID = "ACTIVATE_ADVERTISER_BY_ID";

// USER
export const SET_USER_DATA_ATTRIBUTE = "SET_USER_DATA_ATTRIBUTE";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const RESET_USER_STATE = "RESET_USER_STATE";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";
export const SUSPEND_USER_BY_ID_SUCCESS = "SUSPEND_USER_BY_ID_SUCCESS";
export const SUSPEND_USER_BY_ID_FAIL = "SUSPEND_USER_BY_ID_FAIL";
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
  "SEND_PASSWORD_RESET_EMAIL_SUCCESS";
export const SEND_PASSWORD_RESET_EMAIL_FAIL = "SEND_PASSWORD_RESET_EMAIL_FAIL";
export const CHECK_USER_EXISTS_SUCCESS = "CHECK_USER_EXISTS_SUCCESS";
export const CHECK_USER_EXISTS_FAIL = "CHECK_USER_EXISTS_FAIL";
export const RESET_USER_ERRORS_EMAIL_EXISTS = "RESET_USER_ERRORS_EMAIL_EXISTS";
export const SET_CURRENT_ADVERTISER_DATA_TO_NEW_USER =
  "SET_CURRENT_ADVERTISER_DATA_TO_NEW_USER";
export const DELETE_USER_BY_ID_SUCCESS = "DELETE_USER_BY_ID_SUCCESS";
export const DELETE_USER_BY_ID_FAIL = "DELETE_USER_BY_ID_FAIL";
export const ACTIVATE_USER_BY_ID_SUCCESS = "ACTIVATE_USER_BY_ID_SUCCESS";
export const ACTIVATE_USER_BY_ID_FAIL = "ACTIVATE_USER_BY_ID_FAIL";

// USERS
export const SET_USERS_DATA_ATTRIBUTE = "SET_USERS_DATA_ATTRIBUTE";
export const SET_USERS_DATA = "SET_USERS_DATA";
export const UPDATE_USERS_ERRORS = "UPDATE_USERS_ERRORS";
export const RESET_USERS_STATE = "RESET_USERS_STATE";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const ADD_EMPTY_BRAND_TO_USER = "ADD_EMPTY_BRAND_TO_USER";
export const UPDATE_USER_DATA_BRANDS_BY_INDEX =
         "UPDATE_USER_DATA_BRANDS_BY_INDEX"; 
export const REMOVE_BRAND_TO_USER = "REMOVE_BRAND_TO_USER";

// UI
export const SET_CHAIN_DETAIL_ACTIVE_TAB = "SET_CHAIN_DETAIL_ACTIVE_TAB";
export const SET_BRANCH_DETAIL_ACTIVE_TAB = "SET_BRANCH_DETAIL_ACTIVE_TAB";
export const SET_ADVERTISER_DETAIL_ACTIVE_TAB =
  "SET_ADVERTISER_DETAIL_ACTIVE_TAB";
export const SET_ADVERTISER_DETAIL_EDIT_STEP =
  "SET_ADVERTISER_DETAIL_EDIT_STEP";
export const TOGGLE_ERROR_MODAL = "TOGGLE_ERROR_MODAL";
export const SET_CATEGORY_DETAIL_ACTIVE_TAB = "SET_CATEGORY_DETAIL_ACTIVE_TAB";

// NOTIFICATIONS
export const SET_NOTIFICATIONS_DATA_SUCCESS = "SET_NOTIFICATIONS_DATA_SUCCESS";
export const SET_NOTIFICATIONS_DATA_FAIL = "SET_NOTIFICATIONS_DATA_FAIL";
export const SET_NOTIFICATIONS_META = "SET_NOTIFICATIONS_META";

// NOTIFICATION
export const SET_NOTIFICATION_SUCCEED = "SET_NOTIFICATION_SUCCEED";
export const SET_NOTIFICATION_READED = "SET_NOTIFICATION_READED";

// CONFIGURATION
export const RESET_CONFIGURATION_STATE = "RESET_CONFIGURATION_STATE";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAIL = "GET_CONFIGURATION_FAIL";
export const CHANGE_CONFIGURATION_ITEM = "CHANGE_CONFIGURATION_ITEM";
export const UPDATE_CONFIGURATION_SUCCESS = "UPDATE_CONFIGURATION_SUCCESS";
export const UPDATE_CONFIGURATION_FAILED = "UPDATE_CONFIGURATION_FAILED";

// REPORT
export const FETCH_REPORT_URL_SUCCESS = "FETCH_REPORT_URL_SUCCESS";
export const FETCH_REPORT_URL_FAIL = "FETCH_REPORT_URL_FAIL";
export const RESET_REPORT_STATE = "RESET_REPORT_STATE";

//REPRESENTATIVE
export const UPDATE_REPRESENTATIVE_DATA = "UPDATE_REPRESENTATIVE_DATA";
export const RESET_REPRESENTATIVE_STATE = "RESET_REPRESENTATIVE_STATE";
export const CREATE_REPRESENTATIVE_SUCCEED = "CREATE_REPRESENTATIVE_SUCCEED";
export const CREATE_REPRESENTATIVE_FAIL = "CREATE_REPRESENTATIVE_FAIL";

//TERMS AND CONDITIONS
export const TERMS_AND_CONDITION_SUCCEED = "TERMS_AND_CONDITION_SUCCEED";
export const USER_CERTIFICATION_SUCCEED = "USER_CERTIFICATION_SUCCEED";
export const GET_USER_TERMS_AND_CONDITION_SUCCEED =
  "GET_USER_TERMS_AND_CONDITION_SUCCEED";

//AREAS
export const SET_BRANCH_AREAS = "SET_BRANCH_AREAS";
export const SET_BRANCH_AREAS_FAIL = "SET_BRANCH_AREAS_FAIL";

//PRE-RESERVATION BUTOON
export const SET_DATA_CHANGED = "SET_DATA_CHANGED";


//DATA PRE-RESERVATION 
export const SET_DATA_PRE_RESERVATION = "SET_DATA_PRE_RESERVATION";

//RULE EDITOR
export const SET_RULE_EDITOR = "SET_RULE_EDITOR";

//ID PRE-RESERVATION 
export const SET_ID_PRE_RESERVATION = "SET_ID_PRE_RESERVATION";


