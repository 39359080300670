export enum RateTypes {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  PaymentPercentage = 4,
  PaymentDay = 5,
  Area = 6,
  ExtraLoop = 7,
  MinimumCampaignsPerCategory = 8,
  MaximumCampaignsPerScreen = 9,
}
