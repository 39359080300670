import { handleActions, Action } from 'redux-actions';
import * as ACTIONS from '../constants/actions';
import { IUserState, IUserStateData } from './UserInterfaces';
import USER_INITIAL_STATE from './UserInitialState';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { ISetAzulPaymentDataVaultTokenPayload } from '../../payloads/advertiser-admins/SetAzulPaymentDataVaultTokenPayload';
import { DEFAULT_ADVERTISER_STATE_BRAND } from '../advertiser/AdvertiserInitialState';
import { IAdvertiserStateBrand } from '../advertiser/AdvertiserInterfaces';

const setDataAttribute = (user: IUserState, action: Action<{ key: string, value: string }>): IUserState => {
  const key = action.payload ? action.payload.key : '';
  const value = action.payload ? action.payload.value : '';
  return {
    ...user,
    data: {
      ...user.data,
      [key]: value,
    },
  };
};

const updateUserData = (userState: IUserState, action: Action<Partial<IUserStateData>>): IUserState => {
  if (!action.payload) return { ...userState };

  const newUserData: Partial<IUserStateData> = action.payload;

  return ({
    ...userState,
    data: {
      ...userState.data,
      ...extractDefinedProps(newUserData),
    },
  });
};

const resetUserState = (user: IUserState, action: Action<{}>): IUserState => {
  return {
    ...USER_INITIAL_STATE,
  };
};

const setUserDataSucceed = (user: IUserState, action: Action<IUserStateData>): IUserState => {
  if (!action.payload) return { ...user };

  return {
    ...user,
    data: {
      ...user.data,
      ...action.payload || USER_INITIAL_STATE.data,
    },
  };
};

const setUserDataFailed = (user: IUserState, action: Action<{ error: string }>): IUserState => {
  if (!action.payload) return { ...user };

  return {
    ...user,
    errors: {
      fetch: action.payload.error,
    },
  };
};

const sendPasswordResetEmailSuccess = (user: IUserState, action: Action<null>): IUserState => {
  if (!action.payload) return { ...user };

  return {
    ...user,
    errors: {
      ...user.errors,
      resetPassword: {
        ...user.errors.resetPassword || {},
        sendEmailFailed: false,
      },
    },
  };
};

const sendPasswordResetEmailFail = (user: IUserState, action: Action<null>): IUserState => {
  if (!action.payload) return { ...user };

  return {
    ...user,
    errors: {
      ...user.errors,
      resetPassword: {
        ...user.errors.resetPassword || {},
        sendEmailFailed: true,
      },
    },
  };
};

const checkUserExistsSuccess = (user: IUserState, action: Action<boolean>): IUserState => {
  return {
    ...user,
    errors: {
      ...user.errors,
      email: {
        ...user.errors.email,
        exists: !!action.payload,
      },
    },
  };
};

const checkUserExistsFail = (user: IUserState, action: Action<null>): IUserState => {
  return {
    ...user,
    errors: {
      ...user.errors,
      email: {
        ...user.errors.email,
        exists: undefined,
      },
    },
  };
};

const resetUserErrorsEmailExists = (user: IUserState, action: Action<null>): IUserState => {
  return {
    ...user,
    errors: {
      ...user.errors,
      email: {
        ...user.errors.email,
        exists: undefined,
      },
    },
  };
};

const setCurrentAdvertiserDataToNewUser = (user: IUserState, action: Action<Partial<IUserStateData>>): IUserState => {
  if (!action.payload) {
    return { ...user };
  }
  const payload = action.payload;

  return {
    ...user,
    data: {
      ...user.data,
      advertiser: payload.advertiser && {
        ...payload.advertiser,
      },
    },
  };
};

const addEmptyBrandToUser = (
  userState: IUserState,
  action: Action<null>
): IUserState => {
  return {
    ...userState,
    data: {
      ...userState.data,
      brands: [
        {
          ...DEFAULT_ADVERTISER_STATE_BRAND,
        },
        ...(userState.data.brands || []),
      ],
    },
  };
};

const updateUserDataBrandsByIndex = (
  advertiserState: IUserState,
  action: Action<{
    bIndex: number;
    brandData: Partial<IAdvertiserStateBrand>;
  }>
): IUserState => {
  if (!action.payload) return { ...advertiserState };

  const newBrandData: Partial<IAdvertiserStateBrand> = action.payload.brandData;
  const brandIndex: number = action.payload.bIndex;
  const advertiserBrands =
    advertiserState.data.brands &&
    advertiserState.data.brands.map((brand, index) => {
      return index !== brandIndex
        ? { ...brand }
        : {
            ...brand,
            newAdded: true,
            ...extractDefinedProps(newBrandData),
          };
    });
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      brands: advertiserBrands,
      toCreateBrandObj: advertiserBrands,
    },
  };
};

const removeBrandToUserSuceed = (
  state: IUserState,
  action: Action<{ bIndex: number; cIndex: number; toCreateBrandName: string }>
): IUserState => {
  const brands = state.data.brands;
  const bIndex = action.payload && action.payload.bIndex;
  const cIndex = action.payload && action.payload.cIndex;
  let newBrands: IAdvertiserStateBrand[] =
    (brands &&
      brands.map((brand) => {
        if (brand.id === bIndex) {
          const categories = brand.categories.filter((c) => cIndex !== c.id);
          brand.categories = categories;
        }
        return brand;
      })) ||
    [];
  newBrands = newBrands.filter((b) => b.categories.length !== 0);

  return {
    ...state,
    data: {
      ...state.data,
      brands: newBrands,
      toCreateBrandObj: [...newBrands],
    },
  };
};

const usersReducer = handleActions<IUserState, any>(
  {
    [ACTIONS.SET_USER_DATA_ATTRIBUTE]: setDataAttribute,
    [ACTIONS.RESET_USER_STATE]: resetUserState,
    [ACTIONS.UPDATE_USER_DATA]: updateUserData,
    [ACTIONS.GET_USER_BY_ID_SUCCESS]: setUserDataSucceed,
    [ACTIONS.GET_USER_BY_ID_FAIL]: setUserDataFailed,
    [ACTIONS.SEND_PASSWORD_RESET_EMAIL_SUCCESS]: sendPasswordResetEmailSuccess,
    [ACTIONS.SEND_PASSWORD_RESET_EMAIL_FAIL]: sendPasswordResetEmailFail,
    [ACTIONS.CHECK_USER_EXISTS_SUCCESS]: checkUserExistsSuccess,
    [ACTIONS.CHECK_USER_EXISTS_FAIL]: checkUserExistsFail,
    [ACTIONS.RESET_USER_ERRORS_EMAIL_EXISTS]: resetUserErrorsEmailExists,
    [ACTIONS.SET_CURRENT_ADVERTISER_DATA_TO_NEW_USER]: setCurrentAdvertiserDataToNewUser,
    [ACTIONS.ADD_EMPTY_BRAND_TO_USER]: addEmptyBrandToUser,
    [ACTIONS.UPDATE_USER_DATA_BRANDS_BY_INDEX]: updateUserDataBrandsByIndex,
    [ACTIONS.REMOVE_BRAND_TO_USER]: removeBrandToUserSuceed,
  },
  USER_INITIAL_STATE
);

export default usersReducer;
