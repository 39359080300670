export const categories = {
  getCategoryById: {
    method: "get",
    url: "/categories/:id",
  },
  getCategoryStats: {
    method: "get",
    url: "/categories/:id/stats",
  },
  createMultiple: {
    method: "post",
    url: "/categories",
  },
  createMultipleFatherCategories: {
    method: "post",
    url: "/fatherCategories",
  },
  searchCategories: {
    method: "get",
    url: "/categories",
  },
  searchPublicCategories: {
    method: "get",
    url: "/public/categories",
  },
  searchFatherCategories: {
    method: "get",
    url: "/fathercategories",
  },
  searchFatherPublicCategories: {
    method: "get",
    url: "/public/fathercategories",
  },
  suspendCategory: {
    method: "patch",
    url: "/categories/:id/suspend",
  },
  activateCategory: {
    method: "patch",
    url: "/categories/:id/activate",
  },
  deleteCategory: {
    method: "delete",
    url: "/categories/:id/delete",
  },
  updateCategory: {
    method: "post",
    url: "/categories/:id",
  },
  getCategoriesByBrandId: {
    method: "get",
    url: "/categories/getcategoriesbybrandId/:id",
  },
};
