import store from '../../store';
import * as ACTIONS from '../../store/constants/actions';

const UNAUTHORIZED_CODE = 401;

const apiMiddleware = (response: any) => {
  return response.then((successful: any) => {

    return new Promise((resolve, reject) => {
      resolve(successful);
    });
  }).catch((error: any) => {
    if (error.response && error.response.status === UNAUTHORIZED_CODE) {
      store.dispatch({
        type: ACTIONS.SET_SESSION_DATA_ATTRIBUTE,
        payload: { key: 'token', value: '' },
      });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  });
};

export default apiMiddleware;

