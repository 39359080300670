import { handleActions, Action } from "redux-actions";

import * as ACTIONS from "../constants/actions";
import { extractDefinedProps } from "../../utilities/ObjectManipulation";
import {
  ICampaignState,
  ICampaignStateData,
  ICampaignStateErrors,
  ICampaignStateDataChain,
  TGetCampaignCostsSuccessPayload,
} from "./CampaignInterfaces";
import CAMPAIGN_INITIAL_STATE from "./CampaignInitialState";
import { CampaignStatusTypes } from "../../enums/campaigns/CampaignStatusTypes";

const updateCampaignData = (
  campaignState: ICampaignState,
  action: Action<Partial<ICampaignStateData>>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const newCampaignData: Partial<ICampaignStateData> = action.payload;

  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      ...extractDefinedProps(newCampaignData),
    },
  };
};

const updateOfferCampaignData = (
  campaignState: ICampaignState,
  action: Action<Partial<ICampaignStateData>>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const newCampaignData: Partial<ICampaignStateData> = action.payload;
  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      offerCampaign: {
        ...extractDefinedProps(campaignState.data.offerCampaign),
        ...extractDefinedProps(newCampaignData),
      },
    },
  };
};

const updateCampaignChainsData = (
  campaignState: ICampaignState,
  action: Action<ICampaignStateDataChain[]>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const newCampaignChainsData: ICampaignStateDataChain[] = action.payload;

  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      chains: [...newCampaignChainsData],
    },
  };
};

const addUniqueBranchToCampaignChain = (
  campaignState: ICampaignState,
  action: Action<{
    branchId: number;
    ownerChainId: number;
  }>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const { branchId, ownerChainId } = action.payload;

  const currentChains = [...(campaignState.data.chains || [])];

  const ownerChain = currentChains.find((chain) => {
    return chain.id === ownerChainId;
  });

  if (!ownerChain) {
    currentChains.push({
      id: ownerChainId,
    });
  }

  const newChains = currentChains.map((chain) => {
    if (chain.id !== ownerChainId) return { ...chain };

    const existingBranch = (chain.branches || []).find(
      (branch) => branch.id === branchId
    );
    if (existingBranch) return { ...chain };

    const newBranches = [...(chain.branches || []), { id: branchId }];

    return {
      ...chain,
      branches: newBranches,
    };
  });

  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      chains: [...newChains],
    },
  };
};

const addUniqueAreaToCampaignBranch = (
  campaignState: ICampaignState,
  action: Action<{
    areaId: number;
    ownerBranchId: number;
    ownerChainId: number;
  }>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const { areaId, ownerBranchId, ownerChainId } = action.payload;
  const currentChains = [...(campaignState.data.chains || [])];
  const ownerChain = currentChains.find((chain) => {
    return chain.id === ownerChainId;
  });

  if (!ownerChain) {
    currentChains.push({
      id: ownerChainId,
    });
  }

  const newChains = currentChains.map((chain) => {
    if (chain.id !== ownerChainId) return { ...chain };

    const currentBranches = [...(chain.branches || [])];
    const ownerBranch = currentBranches.find((branch) => {
      return branch.id === ownerBranchId;
    });

    if (!ownerBranch) {
      currentBranches.push({
        id: ownerBranchId,
      });
    }

    const newBranches = currentBranches.map((branch) => {
      if (branch.id !== ownerBranchId) return { ...branch };

      const existingArea = (branch.areas || []).find(
        (area) => area.id === areaId
      );
      if (existingArea) return { ...branch };

      const newAreas = [...(branch.areas || []), { id: areaId }];

      return {
        ...branch,
        areas: newAreas,
      };
    });

    return {
      ...chain,
      branches: newBranches,
    };
  });

  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      chains: [...newChains],
    },
  };
};

const removeBranchFromCampaignChain = (
  campaignState: ICampaignState,
  action: Action<{
    branchId: number;
    ownerChainId: number;
  }>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const { ownerChainId, branchId } = action.payload;

  const newChains =
    campaignState.data.chains &&
    campaignState.data.chains.map((chain) => {
      if (chain.id !== ownerChainId) return { ...chain };

      return {
        ...chain,
        branches:
          chain.branches &&
          chain.branches.filter((branch) => branch.id !== branchId),
      };
    });

  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      chains: newChains,
    },
  };
};

const removeAreaFromCampaignBranch = (
  campaignState: ICampaignState,
  action: Action<{
    areaId: number;
    ownerBranchId: number;
    ownerChainId: number;
  }>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const { areaId, ownerBranchId, ownerChainId } = action.payload;

  const newChains =
    campaignState.data.chains &&
    campaignState.data.chains.map((chain) => {
      if (chain.id !== ownerChainId) return { ...chain };

      const newBranches =
        chain.branches &&
        chain.branches.map((branch) => {
          if (branch.id !== ownerBranchId) return { ...branch };

          return {
            ...branch,
            areas:
              branch.areas && branch.areas.filter((area) => area.id !== areaId),
          };
        });

      return {
        ...chain,
        branches: newBranches,
      };
    });

  return {
    ...campaignState,
    data: {
      ...campaignState.data,
      chains: newChains,
    },
  };
};

const updateCampaignErrors = (
  campaignState: ICampaignState,
  action: Action<Partial<ICampaignStateErrors>>
): ICampaignState => {
  if (!action.payload) return { ...campaignState };

  const newCampaignErrors: Partial<ICampaignStateErrors> = action.payload;

  return {
    ...campaignState,
    errors: {
      ...campaignState.errors,
      ...extractDefinedProps(newCampaignErrors),
    },
  };
};

const resetCampaignState = (
  campaignState: ICampaignState,
  action: Action<null>
): ICampaignState => ({
  ...CAMPAIGN_INITIAL_STATE,
});

const resetCampaignChainsState = (
  campaignState: ICampaignState,
  action: Action<null>
): ICampaignState => ({
  ...campaignState,
  data: {
    ...campaignState.data,
    chains: [],
  },
});

const resetCampaignMediaState = (
  campaignState: ICampaignState,
  action: Action<null>
): ICampaignState => ({
  ...campaignState,
  data: {
    ...campaignState.data,
    completed: false,
    canAddVideo: true,
    media: {
      name: "",
      duration: "0",
      url: "",
      thumbnail: "",
    },
    offerCampaign: {
      ...campaignState.data,
      completed: false,
      canAddVideo: true,
      media: {
        name: "",
        duration: "0",
        url: "",
        thumbnail: "",
      },
      offerSelectedDays:[]
    },
  },
});

const getCampaignByIdSucceed = (
  state: ICampaignState,
  action: Action<ICampaignStateData>
): ICampaignState => {
  const data = action.payload ? action.payload : state.data;

  return {
    ...state,
    data: {
      ...data,
    },
  };
};

const getCampaignByIdFailed = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    errors: {
      createdSuccessful: false,
    },
  };
};

const approveCampaignSucceed = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    data: {
      ...state.data,
      status: CampaignStatusTypes.Published,
    },
  };
};

const approveCampaignFail = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    errors: {
      approvedSuccessful: false,
    },
  };
};

const declineCampaignSucceed = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    data: {
      ...state.data,
      status: CampaignStatusTypes.Declined,
    },
  };
};

const declineCampaignFail = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    errors: {
      declinedSuccessful: false,
    },
  };
};

const checkoutCampaignSucceed = (
  state: ICampaignState,
  action: Action<number>
): ICampaignState => {
  const campaignId = action.payload;
  if (state.data.id !== campaignId) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      isEditing: true,
    },
    errors: {
      ...state.errors,
      checkout: {
        ...state.errors.checkout,
        success: true,
      },
    },
  };
};

const checkoutCampaignFail = (
  state: ICampaignState,
  action: Action<number>
): ICampaignState => {
  const campaignId = action.payload;
  if (state.data.id !== campaignId) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      isEditing: false,
    },
    errors: {
      ...state.errors,
      checkout: {
        ...state.errors.checkout,
        success: false,
      },
    },
  };
};

const updateCampaignSucceed = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    errors: {
      ...state.errors,
      update: {
        ...state.errors.update,
        success: true,
      },
    },
  };
};

const updateCampaignFail = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    errors: {
      ...state.errors,
      update: {
        ...state.errors.update,
        success: false,
      },
    },
  };
};

const validateCampaignPermissionsForSuperAdminSuccess = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      canEdit: false,
      canView: true,
    },
  };
};

const validateCampaignPermissionsForAdvertiserAdminSuccess = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      canEdit: true,
      canView: true,
    },
  };
};

const validateCampaignPermissionsDefaultSuccess = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      canEdit: false,
      canView: true,
    },
  };
};

const validateCampaignPermissionsForCurrentUserFail = (
  state: ICampaignState,
  action: Action<null>
): ICampaignState => {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      canEdit: false,
      canView: false,
    },
  };
};

const getCampaignCostsSuccess = (
  state: ICampaignState,
  action: Action<TGetCampaignCostsSuccessPayload>
): ICampaignState => {
  if (!action.payload) return { ...state };

  const { payload } = action;

  if (state.data.id !== payload.campaignId) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      costs: {
        ...payload.costs,
      },
    },
  };
};

const getCampaignCostsFail = (
  state: ICampaignState,
  action: Action<number>
): ICampaignState => {
  if (!action.payload) return { ...state };

  const { payload: campaignId } = action;

  if (state.data.id !== campaignId) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      costs: undefined,
    },
  };
};

const discardCampaignChangesSuccess = (
  state: ICampaignState,
  action: Action<number>
): ICampaignState => {
  const campaignId = action.payload;
  if (state.data.id !== campaignId) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      isEditing: false,
    },
  };
};

const campaignReducer = handleActions<ICampaignState, any>(
  {
    [ACTIONS.UPDATE_CAMPAIGN_DATA]: updateCampaignData,
    [ACTIONS.UPDATE_OFFER_CAMPAIGN_DATA]: updateOfferCampaignData,
    [ACTIONS.UPDATE_CAMPAIGN_CHAINS_DATA]: updateCampaignChainsData,
    [ACTIONS.ADD_UNIQUE_BRANCH_TO_CAMPAIGN_CHAIN]: addUniqueBranchToCampaignChain,
    [ACTIONS.ADD_UNIQUE_AREA_TO_CAMPAIGN_BRANCH]: addUniqueAreaToCampaignBranch,
    [ACTIONS.UPDATE_CAMPAIGN_ERRORS]: updateCampaignErrors,
    [ACTIONS.RESET_CAMPAIGN_STATE]: resetCampaignState,
    [ACTIONS.RESET_CAMPAIGN_CHAINS_STATE]: resetCampaignChainsState,
    [ACTIONS.GET_CAMPAIGN_BY_ID_SUCCEED]: getCampaignByIdSucceed,
    [ACTIONS.GET_CAMPAIGN_BY_ID_FAIL]: getCampaignByIdFailed,
    [ACTIONS.REMOVE_BRANCH_FROM_CAMPAIGN_CHAIN]: removeBranchFromCampaignChain,
    [ACTIONS.REMOVE_AREA_FROM_CAMPAIGN_BRANCH]: removeAreaFromCampaignBranch,
    [ACTIONS.APPROVE_CAMPAIGN_FAIL]: approveCampaignFail,
    [ACTIONS.DECLINE_CAMPAIGN_SUCCEED]: declineCampaignSucceed,
    [ACTIONS.DECLINE_CAMPAIGN_FAIL]: declineCampaignFail,
    [ACTIONS.CHECKOUT_CAMPAIGN_SUCCEED]: checkoutCampaignSucceed,
    [ACTIONS.CHECKOUT_CAMPAIGN_FAIL]: checkoutCampaignFail,
    [ACTIONS.UPDATE_CAMPAIGN_SUCCEED]: updateCampaignSucceed,
    [ACTIONS.UPDATE_CAMPAIGN_FAIL]: updateCampaignFail,
    [ACTIONS.VALIDATE_CAMPAIGN_PERMISSIONS_FOR_SUPER_ADMIN_SUCCESS]: validateCampaignPermissionsForSuperAdminSuccess,
    [ACTIONS.VALIDATE_CAMPAIGN_PERMISSIONS_FOR_ADVERTISER_ADMIN_SUCCESS]: validateCampaignPermissionsForAdvertiserAdminSuccess,
    [ACTIONS.VALIDATE_CAMPAIGN_PERMISSIONS_DEFAULT_SUCCESS]: validateCampaignPermissionsDefaultSuccess,
    [ACTIONS.VALIDATE_CAMPAIGN_PERMISSIONS_FOR_CURRENT_USER_FAIL]: validateCampaignPermissionsForCurrentUserFail,
    [ACTIONS.RESET_CAMPAIGN_MEDIA_STATE]: resetCampaignMediaState,
    [ACTIONS.GET_CAMPAIGN_COSTS_SUCCESS]: getCampaignCostsSuccess,
    [ACTIONS.GET_CAMPAIGN_COSTS_FAIL]: getCampaignCostsFail,
    [ACTIONS.DISCARD_CAMPAIGN_CHANGES_SUCCESS]: discardCampaignChangesSuccess,
  },
  CAMPAIGN_INITIAL_STATE
);

export default campaignReducer;
