export const monitors = {
  searchMonitors: {
    method: 'get',
    url: '/monitors',
  },
  createMonitor: {
    method: 'post',
    url: '/monitors',
  },
  updateMonitor: {
    method: 'post',
    url: '/monitors/:id',
  },
  canDelete: {
    method: 'get',
    url: '/monitors/:id/canDelete',
  },
  deleteMonitor: {
    method: 'delete',
    url: '/monitors/:id',
  },
  suspendMonitor: {
    method: 'patch',
    url: '/monitors/:id/suspend',
  },
  activateMonitor: {
    method: 'patch',
    url: '/monitors/:id/activate',
  },
};
