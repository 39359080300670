import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import {
  IReportState,
  IReportStateData,
} from './ReportInterfaces';
import REPORT_INITIAL_STATE from './ReportInitialState';

const fetchReportUrlSuccess = (
  state: IReportState, 
  action: Action<string>,
): IReportState => {
  if (!action.payload) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      externalReportUrl: action.payload,
    },
  };
};

const fetchReportUrlFail = (
  state: IReportState, 
  action: Action<string>,
): IReportState => {
  if (!action.payload) return { ...state };

  return {
    ...state,
    data: {
      ...state.data,
      externalReportUrl: undefined,
    },
  };
};

const resetReportState = (
  state: IReportState, 
  action: Action<null>,
): IReportState => {
  return { ...REPORT_INITIAL_STATE };
};

const reportReducer = handleActions<IReportState, any>(
  {
    [ACTIONS.FETCH_REPORT_URL_SUCCESS]: fetchReportUrlSuccess,    
    [ACTIONS.FETCH_REPORT_URL_FAIL]: fetchReportUrlFail,
    [ACTIONS.RESET_REPORT_STATE]: resetReportState,    
  },
  REPORT_INITIAL_STATE,
);

export default reportReducer;
