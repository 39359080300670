export const configurations = {
  getConfiguration: {
    method: 'get',
    url: '/configurations',
  },
  updateConfiguration: {
    method: 'put',
    url: '/configurations',
  },
};
