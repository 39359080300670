import * as ACTIONS from '../constants/actions';
import { IContact } from '../contact/ContactInterfaces';

export const setContactsAction = (contacts: IContact[]) => (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_CONTACTS, payload: contacts });
};


export default {
  setContactsAction,
};
