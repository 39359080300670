import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import BRANCHES_INITIAL_STATE from './BranchesInitialState';
import {
  IBranchesState,
  IBranchesStateDataItem,
  IBranchesStateErrors,
  TBranchesStateDataUpdater,
} from './BranchesInterfaces';

const setBranchesData = (branchesState: IBranchesState, action: Action<IBranchesStateDataItem[]>): IBranchesState => {
  if (!action.payload) return { ...branchesState };

  const newBranchesData: IBranchesStateDataItem[] = action.payload;

  return ({
    ...branchesState,
    data: [
      ...newBranchesData,
    ],
  });
};

const updateBranchesData = (
  branchesState: IBranchesState,
  action: Action<TBranchesStateDataUpdater>,
): IBranchesState => {

  if (!action.payload) return { ...branchesState };

  const updateData: TBranchesStateDataUpdater = action.payload;

  return {
    ...branchesState,
    data: [...branchesState.data.map(branchesItem => updateData(branchesItem))],
  };

};

const updateBranchesErrors = (
  branchesState: IBranchesState,
  action: Action<Partial<IBranchesStateErrors>>,
): IBranchesState => {

  if (!action.payload) return { ...branchesState };

  const newBranchesErrors: Partial<IBranchesStateErrors> = action.payload;

  return {
    ...branchesState,
    errors: {
      ...branchesState.errors,
      ...extractDefinedProps(newBranchesErrors),
    },
  };

};

const resetBranchesState = (branchesState: IBranchesState, action: Action<null>): IBranchesState => ({
  ...BRANCHES_INITIAL_STATE,
});

const suspendBranchById = (branchesState: IBranchesState, action: Action<{id: number}>): IBranchesState => {
  if (!action.payload) return { ...branchesState };

  const branchId = action.payload.id;
  return {
    ...branchesState,
    data: [
      ...branchesState.data.map((branch) => {
        return {
          ...branch,
          active: branch.id === branchId ? false : branch.active,
        };
      }),
    ],
  };
};

const activateBranchById = (branchesState: IBranchesState, action: Action<{id: number}>): IBranchesState => {
  if (!action.payload) return { ...branchesState };

  const branchId = action.payload.id;
  return {
    ...branchesState,
    data: [
      ...branchesState.data.map((branch) => {
        return {
          ...branch,
          active: branch.id === branchId ? true : branch.active,
        };
      }),
    ],
  };
};

const addBranchInformation = (branchesState: IBranchesState, action: Action<{id: number, data: {total: number, failedNumber: number, failRows: number[]}}>): IBranchesState => {
  if (!action.payload) return { ...branchesState };
  const failedNumber = action.payload.data.failedNumber;
  const failRows = action.payload.data.failRows;
  return {
    ...branchesState,
    errors:{
      ...branchesState.errors,
      failRows: failedNumber,
    },
    meta:{
      ...branchesState.meta,
      brandsMessage: failedNumber === 0? 'Toda la información agregada!': 'Error en agregar algunas marcas o categorias',
      newBrandsCount: action.payload.data.total - failedNumber,
      failedNumber,
      failRows,
    }
  };
};

const resetBrandsMessage = (branchesState: IBranchesState): IBranchesState => {

  return {
    ...branchesState,
    errors:{
      ...branchesState.errors,
      failRows: undefined,
    },
    meta:{
      ...branchesState.meta,
      brandsMessage: undefined,
    }
  };
};
const branchesReducer = handleActions<IBranchesState, any>(
  {
    [ACTIONS.SET_BRANCHES_DATA]: setBranchesData,
    [ACTIONS.UPDATE_BRANCHES_DATA]: updateBranchesData,
    [ACTIONS.UPDATE_BRANCHES_ERRORS]: updateBranchesErrors,
    [ACTIONS.RESET_BRANCHES_STATE]: resetBranchesState,
    [ACTIONS.SUSPEND_BRANCH_BY_ID]: suspendBranchById,
    [ACTIONS.ACTIVATE_BRANCH_BY_ID]: activateBranchById,
    [ACTIONS.ADD_BRANCH_INFORMATION]: addBranchInformation,
    [ACTIONS.ADD_BRANCH_INFORMATION_RESET]: resetBrandsMessage,
  },
  BRANCHES_INITIAL_STATE,
);

export default branchesReducer;
