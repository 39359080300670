import React, { Component } from 'react';
import { ICheckboxCard } from '../checkbox-card/interfaces';
import Checkbox from '../checkbox';
import { CheckChangeCallback, ICheckbox } from '../checkbox/interfaces';

interface Props extends ICheckboxCard {
  className: string;
  onCheckChange: CheckChangeCallback;
}

export default class CheckboxCard extends Component<Props> {
  static defaultProps = {
    className: '',
  };

  getCheckClass = (checkbox: ICheckbox) => checkbox.isChecked ? 'checked' : 'unchecked';

  render() {
    const { label, checkbox, onCheckChange, className } = this.props;

    return (
      <div className={className}>
        <div className={`checkbox-card ${this.getCheckClass(checkbox)}`}>
          <div className="card-label">
            <span>{label}</span>
          </div>
          <div className="card-checkbox">
            <Checkbox 
              {...checkbox}
              onCheckChange={onCheckChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
