import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IUsersState, IUsersStateDataItem, IUsersStateErrors } from './UsersInterfaces';
import USERS_INITIAL_STATE from './UsersInitialState';
import IActivateUserPayload from '../../payloads/users/ActivateUserPayload';
import { UserStatus } from '../../enums/users/UserStatus';

const setUsersData = (usersState: IUsersState, action: Action<IUsersStateDataItem[]>): IUsersState => {
  if (!action.payload) return { ...usersState };

  const newUsersData: IUsersStateDataItem[] = action.payload;

  return ({
    ...usersState,
    data: [
      ...newUsersData,
    ],
  });
};

const updateUsersErrors = (
  usersState: IUsersState,
  action: Action<Partial<IUsersStateErrors>>,
): IUsersState => {

  if (!action.payload) return { ...usersState };

  const newUsersErrors: Partial<IUsersStateErrors> = action.payload;

  return {
    ...usersState,
    errors: {
      ...usersState.errors,
      ...extractDefinedProps(newUsersErrors),
    },
  };
};

const resetUsersState = (state: IUsersState, action: Action<null>): IUsersState => ({
  ...USERS_INITIAL_STATE,
});


const suspendUserSuceed = (usersState: IUsersState, action: Action<{ id: string }>): IUsersState => {

  if (!action.payload) return { ...usersState };

  const suspendedUser = action.payload;

  return {
    ...usersState,
    data: [
      ...usersState.data.map((user) => {
        return {
          ...user,
          statusId: user.id === suspendedUser.id ? UserStatus.Inactive: user.statusId
        };
      }),
    ],
  };
};


const suspendUserFailed = (usersState: IUsersState, action: Action<{ error: string }>): IUsersState => {

  if (!action.payload) return { ...usersState };

  const suspendError = action.payload;

  return {
    ...usersState,
    errors: {
      ...usersState.errors,
      suspend: suspendError.error,
    },
  };
};

const updateUserById = (
  usersState: IUsersState,
  action: Action<Partial<IUsersStateDataItem>>,
): IUsersState => {
  if (!action.payload || !action.payload.id) return { ...usersState };

  const newUserData = action.payload;

  return {
    ...usersState,
    data: usersState.data.map((user) => {
      if (user.id !== newUserData.id) return { ...user };

      return {
        ...user,
        ...extractDefinedProps(newUserData),
      };
    }),
  };
};


const deleteUserSucceed = (users: IUsersState, action: Action<string>): IUsersState => {
  if (!action.payload) return { ...users };

  const id = action.payload;

  return {
    ...users,
    data: [
      ...users.data.filter(e => e.id !== id),
    ],
  };
};

const deleteUserFailed = (users: IUsersState, action: Action<{ err: string }>): IUsersState => {
  if (!action.payload) return { ...users };

  return {
    ...users,
    errors: {
      ...users.errors,
      deleted: action.payload.err,
    },
  };
};

const activatedUserSuceed = (
  usersState: IUsersState,
  action: Action<IActivateUserPayload>,
): IUsersState => {
  if (!action.payload) return { ...usersState };

  const activated = action.payload;

  return {
    ...usersState,
    data: [
      ...usersState.data.map((user) => {
        return {
          ...user,
          statusId: user.id === activated.id ? UserStatus.Active: user.statusId
        };
      }),
    ],
  };
};

const usersReducer = handleActions<IUsersState, any>(
  {
    [ACTIONS.SET_USERS_DATA]: setUsersData,
    [ACTIONS.UPDATE_USERS_ERRORS]: updateUsersErrors,
    [ACTIONS.RESET_USERS_STATE]: resetUsersState,
    [ACTIONS.SUSPEND_USER_BY_ID_SUCCESS]: suspendUserSuceed,
    [ACTIONS.SUSPEND_USER_BY_ID_FAIL]: suspendUserFailed,
    [ACTIONS.UPDATE_USER_BY_ID]: updateUserById,
    [ACTIONS.DELETE_USER_BY_ID_FAIL]: deleteUserFailed,
    [ACTIONS.DELETE_USER_BY_ID_SUCCESS]: deleteUserSucceed,
    [ACTIONS.ACTIVATE_USER_BY_ID_SUCCESS]: activatedUserSuceed,
  },
  USERS_INITIAL_STATE,
);

export default usersReducer;
