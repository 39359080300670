import React, { Component } from 'react';
import { 
  IColumnHeader, 
  TColumnHeaderArray, 
  THeader, 
  ITableConfig, 
  TRowGroupProps, 
} from './interfaces';

import ReactTable, { 
  Column, 
  SortingRule, 
  ComponentPropsGetterR, 
} from 'react-table';
import 'react-table/react-table.css';
import Icons from '../../../../resources/assets/icons';
import CONSTANTS from './constants';

interface ITableGenericProps<T> {
  dataObjects: T[];
  columns: TColumnHeaderArray<T>;
  config: ITableConfig;
  className: string;
  noDataText: string;

  headerClassName: string;
}

interface ITableGenericState {
  previousSorted: SortingRule[];
  sorted: SortingRule[];
}

export default class TableGeneric<T extends TRowGroupProps> extends Component<ITableGenericProps<T>, ITableGenericState> {
  static defaultProps = {
    config: { ...CONSTANTS.DEFAULT_TABLE_CONFIG },
    className: 'bg-white',
    noDataText: 'Su búsqueda no produjo resultados',
    headerClassName: 'default-class--header',
  };

  state = {
    previousSorted: CONSTANTS.DEFAULT_SORTED,
    sorted: CONSTANTS.DEFAULT_SORTED,
  };

  calculateNextSorted = (previousSorted: SortingRule[], nextSorted: SortingRule[]) => nextSorted.filter((nextRule) => {
    const previousColumnRule = previousSorted.find(previousRule => previousRule.id === nextRule.id);
    
    if (previousColumnRule && previousColumnRule.desc) return false;

    return true;
  })

  handleOnSortChange = (nextSorted: SortingRule[]) => {
    const { previousSorted } = this.state;
    const sorted = this.calculateNextSorted(previousSorted, nextSorted);

    this.setState({
      sorted,
      previousSorted: nextSorted,
    });
  }

  renderDownIcon = () => (
    <Icons.DropdownDown
      width={CONSTANTS.ICON_WIDTH}
      height={CONSTANTS.ICON_HEIGHT}
    />
  )

  renderUpIcon = () => (
    <Icons.DropdownUp
      width={CONSTANTS.ICON_WIDTH}
      height={CONSTANTS.ICON_HEIGHT}
    />
  )

  getIconFromRule = (columnRule: SortingRule) => (columnRule.desc ? this.renderUpIcon() : this.renderDownIcon());

  renderHeaderIcon = (columnIndex: number) => {
    const { sorted } = this.state;
    const columnRule = sorted.find(rule => rule.id === columnIndex.toString());

    return columnRule && this.getIconFromRule(columnRule);
  }

  renderColumnHeader = (header: THeader, columnIndex: number) => (
    <div className={`table-header ${this.props.headerClassName}`}>
      <div className="header-title">
        {header}
      </div>
      <div className="header-icon">
        {this.renderHeaderIcon(columnIndex)}
      </div>
    </div>
  )

  getReactTableColumn = (column: IColumnHeader<T>, columnIndex: number): Column<T> => ({
    Header: this.renderColumnHeader(column.header, columnIndex),
    accessor: d => d,
    Cell: row => column.renderer && column.renderer(row.value),
    sortable: !!(column.sorter),
    sortMethod: column.sorter,
    width: column.width || CONSTANTS.COLUMN_WIDTH,
    minWidth: column.minWidth || CONSTANTS.COLUMN_MIN_WIDTH,
    maxWidth: column.maxWidth || CONSTANTS.COLUMN_MAX_WIDTH,
    id: columnIndex.toString(),
  })

  trPropsCallback: ComponentPropsGetterR = (state, rowInfo) => {
    return {
      className: !rowInfo ? '' : rowInfo.original.className,
    };
  }

  render() {
    const { 
      className, 
      dataObjects, 
      columns,
      config,
      noDataText,
    } = this.props;

    const {
      maxTableRows, 
      minRows, 
      multiSort, 
      showPageSizeOptions,
      tableClassName,
      showPagination,
      resizable,
      
    } = config;

    const { sorted } = this.state;
    return (
      <div className={`table-generic ${className}`}>
        <ReactTable
          noDataText={noDataText}
          data={dataObjects}
          columns={[...columns.map((column, i) => this.getReactTableColumn(column, i))]}
          minRows={minRows}
          defaultPageSize={maxTableRows}
          className={tableClassName}
          showPageSizeOptions={showPageSizeOptions}
          multiSort={multiSort}
          sorted={sorted}
          onSortedChange={this.handleOnSortChange}
          showPagination={showPagination}
          getTrGroupProps={this.trPropsCallback}
          resizable={resizable}
        />
      </div>
    );
  }
}
