import React, { Component } from 'react';
import ChainGeneralInfo from '../chain-general-info';
import ChainAdministratorsInfo from '../chain-administrators-info';
import ChainRatesInfo from '../chain-rates-info';
import { IContact } from '../../../../common/store/contact/ContactInterfaces';
import { IChainRates } from '../../../../common/store/chain/ChainInterfaces';

interface IChainSummaryProps {
  onEditGeneral?: Function;
  onEditAdministrators?: Function;
  onEditRates?: Function;

  rncCode: string | number;
  logo:string;
  name: string;
  accountantEmail: string;
  administrators: IContact[];

  rates: IChainRates[];

  className: string | number;
}

export default class ChainSummary extends Component<IChainSummaryProps> {
  static defaultProps = {
    className: '',
  };

  render() {
    const { 
      className, 
      administrators,
      rncCode,
      logo,
      name, 
      rates,
      accountantEmail,
    } = this.props;

    return (
      <div className={`chain-summary ${className}`}>
        <ChainGeneralInfo
          rncCode={rncCode}
          logo={logo}
          name={name}
          accountantEmail={accountantEmail}
          onClickEdit={this.props.onEditGeneral}
        />
        <ChainAdministratorsInfo
          administrators={administrators}
          onClickEdit={this.props.onEditAdministrators}
        />
        <ChainRatesInfo
          onClickEdit={this.props.onEditRates}
          rates={rates}
        />
      </div>
    );
  }
}
