import { IPlayerState } from './PlayerInterfaces';

const PLAYER_INITIAL_STATE: IPlayerState = {
  data: {
    id: 0,
    serialCode: '',
    manufacturer: '',
    model: '',
  },
  errors: {
    create: '',
    update: '',
    delete: '',
  },
};

export default PLAYER_INITIAL_STATE;
