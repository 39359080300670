import React, { Component } from 'react';

import { TInnerBodyColumn } from './interfaces';
import Icons from '../../../../resources/assets/icons';
import { IContact } from '../../../../common/store/contact/ContactInterfaces';
import { RectangleButton, SolidSeparator, DottedSeparator } from '../../../../common/components';
import HeaderBar from '../../../../common/components/header-bar';
import { IChainRates } from '../../../../common/store/chain/ChainInterfaces';
import { RateTypes } from '../../../../common/enums/rates/RateTypes';

interface IChainRatesInfoProps {
  rates: IChainRates[];
  onClickEdit?: Function;

  className: string;
}

export default class ChainRatesInfo extends Component<IChainRatesInfoProps> {
  static defaultProps = {
    className: '',
  };

  handleOnClickEdit = () => this.props.onClickEdit && this.props.onClickEdit();

  getRateValue = (type: RateTypes) => {
    const rate = this.props.rates.find(rate => rate.type === type);

    if (rate) return rate.stringAmount || rate.amount.toString();

    return '';
  }

  renderButtonContent = () => (
    <RectangleButton
      onClick={this.handleOnClickEdit}
      className="summary-edit"
    >
      <div className="edit-button">
        <div className="edit-icon">
          <Icons.Edit
            height="100%"
            fill="rgb(25,118,210)"
          />
        </div>
        <div className="button-content">
          MODIFICAR
        </div>
      </div>
    </RectangleButton>
  )

  renderInnerBodyRow = (
    column1: TInnerBodyColumn,
    column2: TInnerBodyColumn,
    column3: TInnerBodyColumn,
    column4: TInnerBodyColumn,
  ) => (
      <div className="inner-row rates-row flex-left">
        <div className="inner-column-1 pdR10 rates-column flex-left">{column1}</div>
        <div className="inner-column-2 pdR10 rates-column flex-left">{column2}</div>
        <div className="inner-column-3 pdR10 rates-column flex-left">{column3}</div>
        <div className="inner-column-4 pdR10 rates-column flex-left">{column4}</div>
      </div>
    )

  renderMainHeader = () => (
    <>
      <HeaderBar
        className="pdG20 w-100 full-height"
        leftContent={(
          <div className="header-value">
            Tarifas
          </div>
        )}
        rightContent={this.props.onClickEdit && this.renderButtonContent()}
      />
    </>
  )

  renderInnerHeader = () => (
    <>
      <div className="inner-left rates-column flex-left">
        <div className="left-label rates-column flex-left">
          Porcentaje de venta
        </div>
        <div className="left-value rates-column flex-left">
          {`${this.getRateValue(RateTypes.PaymentPercentage)}%`}
        </div>
      </div>
      <div className="inner-right rates-column flex-left">
        <div className="right-label rates-column flex-left">
          Días de pagos
        </div>
        <div className="right-value rates-column flex-left">
          {this.getRateValue(RateTypes.PaymentDay)}
        </div>
      </div>
    </>
  )

  renderInnerBody = () => (
    <>
      <div className="body-header rates-row flex-left">
        {this.renderInnerBodyRow('Período', 'Créditos', 'Parámetros', 'Porcentajes')}
      </div>


      <div className="third-row rates-row flex-left">
        {this.renderInnerBodyRow(
          'Mensual',
          this.getRateValue(RateTypes.Monthly),
          'Máximo de anuncios por pantalla',
          this.getRateValue(RateTypes.MaximumCampaignsPerScreen),
        )}
      </div>
      <div className="fourth-row rates-row flex-left">
        {this.renderInnerBodyRow(
          '',
          '',
          'Mínimo de anuncios por categoría',
          this.getRateValue(RateTypes.MinimumCampaignsPerCategory),
        )}
      </div>
    </>
  )

  renderBody = () => (
    <>
      <div className="rates-inner-header rates-row flex-left">
        {this.renderInnerHeader()}
      </div>
      <DottedSeparator className="mgB10 mgT10" />
      <div className="rates-inner-body rates-row">
        {this.renderInnerBody()}
      </div>
    </>
  )

  render() {
    const { className } = this.props;

    return (
      <div className={`chain-rates-info ${className}`}>
        <div className="rates-header fs-20 rates-row flex-between">
          {this.renderMainHeader()}
        </div>
        <SolidSeparator />
        <div className="rates-body pdG20 rates-row flex-left">
          {this.renderBody()}
        </div>
      </div>
    );
  }
}
