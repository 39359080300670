import { lazy } from 'react';

const Login = lazy(() => import('./login'));
const RestorePassword = lazy(() => import('./restorePassword'));
const PasswordResetEmail = lazy(() => import('./password-reset-email'));
const CreateAdvertiser = lazy(() => import('../advertisers/public/create-advertiser-public'));

export default {
  Login,
  RestorePassword,
  PasswordResetEmail,
  CreateAdvertiser,
};
