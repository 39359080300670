
import { IProductState } from './ProductInterfaces';

const PRODUCT_INITIAL_STATE: IProductState = {
  data: {
    id: 0,
    name: '',
    UPC: '',
    presentation: '',
    brand: {
      id: 0,
      name: '',
      category: {
        id: 0,
        name: '',
      },
    },
    category: {
      id: 0,
      name: '',
    },
  },
  errors: {
    
  },
  meta: {
    message: '',
  },
};

export default PRODUCT_INITIAL_STATE;
