import { handleActions, Action } from 'redux-actions';
import {
  IDashboardState,
  IDashboardAdminStats,
  IDashboardSlotsUsages,
  IDashboardAdvertiserSlotsUsages,
  IDashboardAdvertiserStats,
  IDashboardAccountManagerStats,
  IDashboardChainAdminStats,
} from './DashboardInterfaces';
import DASHBOARD_INITIAL_STATE from './DashboardInitialState';
import * as ACTIONS from '../constants/actions';

const setAdminStats = (prevState: IDashboardState, action: Action<IDashboardAdminStats>): IDashboardState => {

  const newStats = (action.payload || prevState.data.admin.stats);

  return {
    ...prevState,
    data: {
      ...prevState.data,
      admin: {
        ...prevState.data.admin,
        stats: newStats && {
          ...newStats,
        },
      },
    },
  };
};

const setAccountManagerStats =
  (prevState: IDashboardState, action: Action<IDashboardAccountManagerStats>): IDashboardState => {

    const newStats = (action.payload || prevState.data.accountManager.stats);

    return {
      ...prevState,
      data: {
        ...prevState.data,
        accountManager: {
          ...prevState.data.accountManager,
          stats: newStats && {
            ...newStats,
          },
        },
      },
    };
  };

const setChainAdminStats =
  (prevState: IDashboardState, action: Action<IDashboardChainAdminStats>): IDashboardState => {

    const newStats = (action.payload || prevState.data.chainAdmin.stats);

    return {
      ...prevState,
      data: {
        ...prevState.data,
        chainAdmin: {
          ...prevState.data.chainAdmin,
          stats: newStats && {
            ...newStats,
          },
        },
      },
    };
  };

const setAdveriserAdminStats = (prevState: IDashboardState, action: Action<IDashboardAdvertiserStats>)
  : IDashboardState => {

  const newStats = (action.payload || prevState.data.advertiser.stats);

  return {
    ...prevState,
    data: {
      ...prevState.data,
      advertiser: {
        ...prevState.data.advertiser,
        stats: newStats && {
          ...newStats,
        },
      },
    },
  };
};

const getDashboardSlotsUsageSuccess = (
  prevState: IDashboardState,
  action: Action<IDashboardSlotsUsages>,
): IDashboardState => {
  const usages = action.payload;
  if (!usages) return { ...prevState };

  return {
    ...prevState,
    data: {
      ...prevState.data,
      admin: {
        ...prevState.data.admin,
        slotsUsages: {
          ...prevState.data.admin.slotsUsages,
          ...usages,
        },
      },
    },
  };
};

const getDashboardSlotsUsageFail = (
  prevState: IDashboardState,
  action: Action<null>,
): IDashboardState => {
  return {
    ...prevState,
    data: {
      ...prevState.data,
      admin: {
        ...prevState.data.admin,
        slotsUsages: undefined,
      },
    },
  };
};

const getAdvertiserDashboardSlotsUsageSuccess = (
  prevState: IDashboardState,
  action: Action<IDashboardAdvertiserSlotsUsages>,
): IDashboardState => {
  const usages = action.payload;
  if (!usages) return { ...prevState };

  return {
    ...prevState,
    data: {
      ...prevState.data,
      advertiser: {
        ...prevState.data.advertiser,
        slotsUsages: {
          ...prevState.data.advertiser.slotsUsages,
          ...usages,
        },
      },
    },
  };
};

const getAdvertiserDashboardSlotsUsageFail = (
  prevState: IDashboardState,
  action: Action<null>,
): IDashboardState => {
  return {
    ...prevState,
    data: {
      ...prevState.data,
      advertiser: {
        ...prevState.data.advertiser,
        slotsUsages: undefined,
      },
    },
  };
};

const dashboardReducer = handleActions<IDashboardState, any>(
  {
    [ACTIONS.SET_DASHBOARD_ADMIN_STATS]: setAdminStats,
    [ACTIONS.SET_DASHBOARD_ACCOUNT_MANAGER_STATS]: setAccountManagerStats,
    [ACTIONS.SET_DASHBOARD_CHAIN_ADMIN_STATS]: setChainAdminStats,
    [ACTIONS.SET_DASHBOARD_ADVERTISER_ADMIN_STATS]: setAdveriserAdminStats,
    [ACTIONS.GET_DASHBOARD_SLOTS_USAGE_SUCCESS]: getDashboardSlotsUsageSuccess,
    [ACTIONS.GET_DASHBOARD_SLOTS_USAGE_FAIL]: getDashboardSlotsUsageFail,
    [ACTIONS.GET_ADVERTISER_DASHBOARD_SLOTS_USAGE_SUCCESS]: getAdvertiserDashboardSlotsUsageSuccess,
    [ACTIONS.GET_ADVERTISER_DASHBOARD_SLOTS_USAGE_FAIL]: getAdvertiserDashboardSlotsUsageFail,
  },
  DASHBOARD_INITIAL_STATE,
);


export default dashboardReducer;
