
import { ICampaignsState, ICampaignBranch } from './CampaignsInterfaces';

const CAMPAIGNS_INITIAL_STATE: ICampaignsState = {
  data: [],
  meta: {},
};

export const BRANCH_CAMPAIGNS_INITIAL_STATE: ICampaignBranch = {
  id: 0,
};

export default CAMPAIGNS_INITIAL_STATE;
