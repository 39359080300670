
import { 
  IBranchesState, 
  IBranchesStateDataItem, 
} from './BranchesInterfaces';

// Properties defaults
export const DEFAULT_BRANCHES_STATE_DATA_ITEM: IBranchesStateDataItem = {
  id: 0,
  name: '',
  active: true,
};

// Initial State
const BRANCHES_INITIAL_STATE: IBranchesState = {
  data: [],
  errors: {
    search: '',
  },
  meta: {
    message: '',
  },
};

export default BRANCHES_INITIAL_STATE;
