import { handleActions, Action } from 'redux-actions';
import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IScreenState, IScreenStateData, IScreenStateErrors } from './ScreenInterfaces';
import SCREEN_INITIAL_STATE from './ScreenInitialState';

const updateScreenData = (screenState: IScreenState, action: Action<Partial<IScreenStateData>>): IScreenState => {
  if (!action.payload) return { ...screenState };

  const newScreenData: Partial<IScreenStateData> = action.payload;

  return ({
    ...screenState,
    data: {
      ...screenState.data,
      ...extractDefinedProps(newScreenData),
    },
  });  
};

const updateScreenErrors = (screenState: IScreenState, action: Action<Partial<IScreenStateErrors>>): IScreenState => {
  if (!action.payload) return { ...screenState };

  const newScreenErrors: Partial<IScreenStateErrors> = action.payload;

  return {
    ...screenState,
    errors: {
      ...screenState.errors,
      ...extractDefinedProps(newScreenErrors),
    },
  };  
};

const resetScreenState = (screenState: IScreenState, action: Action<null>): IScreenState => ({
  ...SCREEN_INITIAL_STATE,
});

const checkScreenCanDelete = (screenState: IScreenState, action: Action<Partial<IScreenStateErrors>>)
: IScreenState => {

  if (!action.payload) return { ...screenState };

  const errors: Partial<IScreenStateErrors> = action.payload;

  return {
    ...screenState,
    errors: {
      canDelete: errors.canDelete,
    },
  };  
};

const screenReducer = handleActions<IScreenState, any>(
  {
    [ACTIONS.UPDATE_SCREEN_DATA]: updateScreenData,
    [ACTIONS.UPDATE_SCREEN_ERRORS]: updateScreenErrors,
    [ACTIONS.RESET_SCREEN_STATE]: resetScreenState,
    [ACTIONS.CHECK_SCREEN_CAN_DELETE_SUCCESS]: checkScreenCanDelete,
  },
  SCREEN_INITIAL_STATE,
);

export default screenReducer;
