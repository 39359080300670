import React from 'react';
import { ITab } from './interfaces';

interface ITabSelectorProps {
  tabs: ITab[];
  currentIndex: number;

  className: string;
  highlightedClass: string;
  unHighlightedClass: string;
}

export default class TabSelector extends React.Component<ITabSelectorProps> {
  static defaultProps = {
    currentIndex: 0,
    className: '',
    highlightedClass: '',
    unHighlightedClass: '',
  };

  handleTabClick = (tab: ITab, index: number) => () => tab.onClick && tab.onClick(tab.id, index);

  render() {
    const { className, tabs, highlightedClass, currentIndex, unHighlightedClass } = this.props;

    return (
      <div className={`d-flex flex-between flex-nowrap ${className}`}>
        {tabs.map((tab, i) => {
          const tabHighlight = (i === currentIndex ? highlightedClass : unHighlightedClass);
          return (
            <div 
              className={`d-inline-flex flex-center flex-expand full-height clickable ${tabHighlight}`}
              onClick={this.handleTabClick(tab, i)}
              key={i}
            >
              {tab.content}
            </div>        
          );
        })}
      </div>
    );
  }
}
