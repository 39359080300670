export const dashboard = {
  searchAdminStats: {
    method: 'get',
    url: '/dashboard/admin/stats',
  },
  searchAdvertiserAdminStats: {
    method: 'get',
    url: '/dashboard/advertiserAdmin/:userId/stats/',
  },
  searchAccountManagerStats: {
    method: 'get',
    url: '/dashboard/accountManager/:userId/stats/',
  },
  searchChainAdminStats: {
    method: 'get',
    url: '/dashboard/chainAdmin/stats/',
  },
};
