import { handleActions, Action } from 'redux-actions';
import NOTIFICATIONS_INITIAL_STATE from './NotificationsInitialState';
import { INotificationsState, INotificationsMetaState } from './NotificationsInterfaces';
import * as ACTIONS from '../constants/actions';
import { INotificationDTO } from '../../dtos/notifications/INotificationDTO';

const setNotificationsSucceed = (state: INotificationsState, action: Action<INotificationDTO[]>)
  : INotificationsState => {
  return {
    ...state,
    data: [
      ...action.payload || [],
    ],
  };
};

const setNotificationReaded = (state: INotificationsState, action: Action<number>): INotificationsState => {
  const id = action.payload ? action.payload : 0;
  if (id === 0) { return state; }

  return {
    ...state,
    data: [
      ...state.data.map((notification) => {
        notification.isRead = notification.id === id ? true : notification.isRead;
        return notification;
      }),
    ],
  };
};

const setNotificationsStats = (
  state: INotificationsState,
  action: Action<Partial<INotificationsMetaState>>)
  : INotificationsState => {
  const stats = action.payload ? action.payload : state.meta;
  return {
    ...state,
    meta: {
      ...state.meta,
      ...stats,
    },
  };
};

const notificationsReducer = handleActions<INotificationsState, any>(
  {
    [ACTIONS.SET_NOTIFICATIONS_DATA_SUCCESS]: setNotificationsSucceed,
    [ACTIONS.SET_NOTIFICATION_READED]: setNotificationReaded,
    [ACTIONS.SET_NOTIFICATIONS_META]: setNotificationsStats,
  },
  NOTIFICATIONS_INITIAL_STATE,
);

export default notificationsReducer;
