import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import ADVERTISER_ROUTE_CONSTANTS from '../common/constants/routes/advertiser-routes.constants';
import lazyPages from '../pages/async';

const advertiserNotContentRoutes = () => (
  <Switch>
    <Redirect exact from={ADVERTISER_ROUTE_CONSTANTS.ROOT} to={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS} />
    <Route exact path={ADVERTISER_ROUTE_CONSTANTS.DASHBOARD} component={lazyPages.AdvertiserNotContent.AdvertiserNotContent} />
    <Route exact from={ADVERTISER_ROUTE_CONSTANTS.TERMS_AND_CONDITIONS} component={lazyPages.TermsAndConditions.TermsAndConditions} />
    <Route exact from={ADVERTISER_ROUTE_CONSTANTS.USER_CERTIFICATION} component={lazyPages.UserCertification.UserCertification} />
    <Route exact from={ADVERTISER_ROUTE_CONSTANTS.RESEND_EMAILS} component={lazyPages.ResendEmails.ResendEmails} />      
  </Switch>
);

export default advertiserNotContentRoutes;
