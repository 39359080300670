import { lazy } from 'react';
import Branches from './branches/async';

const CreateChains = lazy(() => import('./create-chain'));
const ChainList = lazy(() => import('./chain-list'));
const ChainDetail = lazy(() => import('./chain-detail'));
const ChainEdit = lazy(() => import('./chain-edit'));
const ChainAccount = lazy(() => import("./chain-account"));

export default {
  CreateChains,
  ChainList,
  ChainDetail,
  ChainEdit,
  Branches,
  ChainAccount,
};
