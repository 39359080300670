import { lazy } from 'react';

const AdminReportsAboutAdvertisers = lazy(() => import('./admin-reports-about-advertisers'));;
const AdminReportsAboutBranches = lazy(() => import('./admin-reports-about-branches'));;
const AdminReportsAboutCategories = lazy(() => import('./admin-reports-about-categories'));;
const AdminReportsAboutReservations = lazy(() => import('./admin-reports-about-reservations'));;
const AdminReportsAboutSales = lazy(() => import('./admin-reports-about-sales'));;
const AdminReportsAboutMeraki = lazy(() => import('./admin-reports-about-meraki'));;
const AdminProductLoads = lazy(() => import('./admin-product-loads'));;


export default {
  AdminReportsAboutAdvertisers,
  AdminReportsAboutBranches,
  AdminReportsAboutCategories,
  AdminReportsAboutReservations,
  AdminReportsAboutSales,
  AdminReportsAboutMeraki,
  AdminProductLoads
};
