
import _ from 'lodash';
import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import ThynkadsResponse from '../../dtos/ThynkadsResponse';
import ThynkadsResponseInitialState from '../../dtos/ThynkadsResponseInitialState';
import { IProducts, IProductsData } from './ProductsInterfaces';
import PRODUCTS_INITIAL_STATE from './ProductsInitialState';

const setProducts = (products: IProducts, action: Action<ThynkadsResponse<IProductsData[]>>): IProducts => {
  const payload = action.payload! || ThynkadsResponseInitialState;
  const data = payload.data || PRODUCTS_INITIAL_STATE.data;
  return {
    ...products,
    data,
  };
};

const resetProductsState = (products: IProducts, action: Action<null>): IProducts => ({
  ...PRODUCTS_INITIAL_STATE,
});


const productsReducer = handleActions<IProducts, any>(
  {
    [ACTIONS.SET_PRODUCTS]: setProducts,
    [ACTIONS.RESET_PRODUCTS_STATE]: resetProductsState,
  },
  PRODUCTS_INITIAL_STATE,
);

export default productsReducer;
