import React, { Component } from 'react';
import ReactCrop, { PixelCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import ImageDropzone from '../image-dropzone';

import CONSTANTS from './constants';
import Icons from '../../../resources/assets/icons';
import { blobToDataURL } from '../../utilities/ImageConverter';

export interface IImageCrop {
  onSaveCrop: Function;
  onFileRejected: Function;
  onSelectFile: Function;
  onCancelCrop: Function;
  storedImageBase64: string;
  imageName?: string;
}

// TODO: make this code nicer
class ImageCropper extends Component<IImageCrop> {
  state = {
    message: '',
    src: '',
    srcBlob: new Blob(),
    crop: {
      x: 0,
      y: 0,
      aspect: 1 / 1,
      width: 100,
      height: 100,
    },
    img: {
      width: 0,
      height: 0,
    },
    scaleEquivalent: {
      width: 1,
      height: 1,
    },
    croppedImageUrl: '',
    croppedImageData: '',
    file: null,
    blob: { data: new Blob() },
    acceptButtonDisabled: false,
  };

  private imageRef?: HTMLImageElement;
  private fileUrl: string = '';

  onSelectFile = async (files: File[]) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        const u = URL.createObjectURL(files[0]);
        const img = new Image;

        img.onload = () => this.handleImageUploaded.bind(this)(img);
        img.src = u;


        let pixelCrop: PixelCrop;
        pixelCrop = { ...this.state.crop };
        const blob = await this.getCroppedImg(img, pixelCrop, files[0].name);
        this.setState({
          blob,
          src: reader.result,
          srcBlob: files[0],
          message: files[0].name,
          file: files[0],
        });
       
      });
      await reader.readAsDataURL(files[0]);

      // TODO: Gives time to react to process the image
      setTimeout(
        () => {
          this.props.onSelectFile(files, reader.result);
        },
        200,
      );
    }
  }

  handleImageUploaded = (img: any) => {
    const imageElement = document.getElementsByClassName('ReactCrop__image')[0];

    this.setState({
      ...this.state,
      img: {
        height: img.height,
        width: img.width,
        src: img.src,
      },
      scaleEquivalent: {
        height: img.height / (imageElement ? imageElement.clientHeight : 1),
        width: img.width / (imageElement ? imageElement.clientWidth : 1),
      },
    });
  }

  onFileRejected = (file: File[]) => {
    this.setState({ message: 'Imagen debe ser aspecto 1:1 y minimo 300x300 px' });
    this.props.onFileRejected(file);
  }

  onImageLoaded = (target: HTMLImageElement, pixelCrop?: ReactCrop.PixelCrop) => {
    this.imageRef = target;

    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;

    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...crop },
      });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }
  }

  onCropComplete = (crop: ReactCrop.Crop, pixelCrop?: ReactCrop.PixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  }

  onCropChange = (crop: ReactCrop.Crop) => {
    if (!this.state.acceptButtonDisabled) {
      this.setState({ crop });
    }
  }

  async makeClientCrop(crop: ReactCrop.Crop, pixelCrop?: ReactCrop.PixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const blob = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg',
      );
      const croppedImageUrl = blob.url;
      const croppedImageData = blob.data;
      
      this.setState({ croppedImageUrl, croppedImageData });
    }
  }

  onSaveCrop = async () => {
    const { crop, croppedImageUrl, croppedImageData, srcBlob } = this.state;

    if (croppedImageUrl) {
      this.setState({
        src: croppedImageUrl,
        crop: {
          ...crop,
          x: 0,
          y: 0,
          aspect: 1 / 1,
          width: 100,
          height: 100,
        },
        acceptButtonDisabled: true,
      });
    } else {
      this.setState({ acceptButtonDisabled: true });
    }

    if (this.state.croppedImageData) {
      const base64Data = await blobToDataURL(this.state.croppedImageData);
      this.props.onSaveCrop(base64Data.result, this.state.message);
    } else {
      this.props.onSaveCrop(this.state.src, this.state.message);
    }

  }

  getCroppedImg(image: HTMLImageElement, pixelCrop?: ReactCrop.PixelCrop, fileName?: string)
    : Promise<{ url: string, data: string }> {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop!.width;
    canvas.height = pixelCrop!.height;
    const ctx = canvas.getContext('2d');

    ctx ? ctx.drawImage(
      image,
      pixelCrop!.x,
      pixelCrop!.y,
      pixelCrop!.width,
      pixelCrop!.height,
      0,
      0,
      pixelCrop!.width,
      pixelCrop!.height,
    ) : () => { };

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve({ url: this.fileUrl, data: blob });
        },
        'image/jpeg');
    });
  }

  onCancelCrop = () => {
    this.setState({
      message: '',
      src: '',
      croppedImageUrl: '',
      croppedImageData: '',
      acceptButtonDisabled: false,
    });
    this.props.onCancelCrop();
  }

  isAcceptButtonDisabled = () => {
    if (this.props.storedImageBase64) return true;
    return false;
  }

  render() {
    const { img } = this.state;
    const { imageName } = this.props;
    return (
      <div className="image-cropper wrapper">
        <div className="image-cropper__title">Logotipo</div>
        {(!this.props.storedImageBase64 && !this.state.src) &&
          <ImageDropzone
            onImageChange={this.onSelectFile}
            onImageReject={this.onFileRejected}
          />
        }
        {(this.props.storedImageBase64 || this.state.src) && (
          <div className="image-cropper">
            <ReactCrop
              imageStyle={{ display: 'flex' }}
              src={this.props.storedImageBase64 || this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              minHeight={100 * (300 / img.height)}
              minWidth={100 * (300 / img.width)}
            />
          </div>
        )}
        <div className="d-flex pdG0 col-12 pdT10">
          <div className="d-flex col-9 pdG0 image-cropper__image-name">
            {(imageName || this.state.message) && <Icons.IconPhoto  fill="gray"/>}
            <p className="pdT3 pdL10">{(imageName || this.state.message)}</p>
          </div>
          {(this.props.storedImageBase64 || this.state.src)  &&
            <div className="col-3 text--align-right pdG0">
              <button
                className="bg-transparent border-none"

                onClick={this.onCancelCrop}>
                <Icons.RemoveIcon
                  width={CONSTANTS.DEFAULT_ICON_WIDTH}
                  height={CONSTANTS.DEFAULT_ICON_HEIGHT}
                  fill={CONSTANTS.DEFAULT_ICON_COLOR}
                />
              </button>
              <button
                className=
                {`bg-transparent border-none
                    ${this.isAcceptButtonDisabled() ? 'disabled' : ''}`
                }
                disabled={this.isAcceptButtonDisabled()}
                onClick={this.onSaveCrop}>
                <Icons.CircleCheck
                  width={CONSTANTS.DEFAULT_ICON_WIDTH}
                  height={CONSTANTS.DEFAULT_ICON_HEIGHT}
                  fill={CONSTANTS.DEFAULT_ICON_COLOR}
                />
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}
export default ImageCropper;
