import { SortingRule } from 'react-table';
import { ITableConfig } from './interfaces';

const DEFAULT_TABLE_CLASSNAME: string = '-striped -highlight';
const DEFAULT_TABLE_SHOW_PAGINATION = false;


const DEFAULT_MAX_TABLE_ROWS: number = 999999;
const DEFAULT_MIN_ROWS: number = 1;
const DEFAULT_SORTED: SortingRule[] = [];
const DEFAULT_MULTISORT: boolean = false;
const DEFAULT_SHOW_PAGE_SIZE_OPTIONS: boolean = false;

const DEFAULT_TABLE_CONFIG: ITableConfig = {
  maxTableRows: DEFAULT_MAX_TABLE_ROWS,
  minRows: DEFAULT_MIN_ROWS,
  multiSort: DEFAULT_MULTISORT,
  showPageSizeOptions: DEFAULT_SHOW_PAGE_SIZE_OPTIONS,
  tableClassName: DEFAULT_TABLE_CLASSNAME,
  showPagination: DEFAULT_TABLE_SHOW_PAGINATION,
};

const ICON_WIDTH: string = '100%';
const ICON_HEIGHT: string = '100%';
const COLUMN_WIDTH: number | undefined = undefined;
const COLUMN_MIN_WIDTH: number | undefined = 1;
const COLUMN_MAX_WIDTH: number | undefined = undefined;

const TABLE_GENERIC_CONSTANTS = {
  DEFAULT_TABLE_CONFIG,
  DEFAULT_SORTED,
  ICON_WIDTH,
  ICON_HEIGHT,
  COLUMN_WIDTH,
  COLUMN_MIN_WIDTH,
  COLUMN_MAX_WIDTH,
};

export default TABLE_GENERIC_CONSTANTS;
