import { IProducts } from "./ProductsInterfaces";

const PRODUCTS_INITIAL_STATE: IProducts = {
  data: [],
  meta: {},
  errors: {
    delete: '',
  },
};

export default PRODUCTS_INITIAL_STATE;
