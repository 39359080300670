import React, { Component } from 'react';

import Icons from '../../../../resources/assets/icons';
import { IContact } from '../../../../common/store/contact/ContactInterfaces';
import { RectangleButton, SolidSeparator, DottedSeparator } from '../../../../common/components';
import HeaderBar from '../../../../common/components/header-bar';

interface IChainAdministratorsInfoProps {
  administrators: IContact[];

  onClickEdit?: Function;

  className: string;
}

export default class ChainAdministratorsInfo extends Component<IChainAdministratorsInfoProps> {
  static defaultProps = {
    className: '',
  };

  handleOnClickEdit = () => this.props.onClickEdit && this.props.onClickEdit();

  renderName = (name: string) => (
    <>
      <div className="name-label first-column font-color-dark-gray">
        Nombres
      </div>
      <div className="name-value second-column break-word">
        {name}
      </div>
    </>
  )

  renderEmail = (email: string) => (
    <>
      <div className="email-label first-column font-color-dark-gray">
        Correo electrónico
      </div>
      <div className="email-value second-column break-word">
        {email}
      </div>
    </>
  )

  renderPhone = (phone: string) => (
    <>
      <div className="phone-label first-column font-color-dark-gray">
        Teléfono
      </div>
      <div className="phone-value second-column break-word">
        {phone}
      </div>
    </>
  )

  renderRole = (position: string) => (
    <>
      <div className="role-label first-column font-color-dark-gray">
        Cargo
      </div>
      <div className="role-value second-column break-word">
        {position}
      </div>
    </>
  )

  renderButtonContent = () => (
    <RectangleButton
      onClick={this.handleOnClickEdit}
      className="summary-edit"
    >
      <div className="edit-button">
        <div className="edit-icon">
          <Icons.Edit
            height="100%"
            fill="rgb(25,118,210)"
          />
        </div>
        <div className="button-content">
          MODIFICAR
        </div>
      </div>  
    </RectangleButton>
  )

  renderAdministrator = (administrator: IContact, index: number) => (
    <div className="admin-card pdG10">
      <div className="admin-count fs-14 card-row flex-left font-color-dark-gray mgB10">
        {'Administrador ' + (index + 1)}
      </div>
      <div className="admin-name card-row flex-left">
        {this.renderName(administrator.name)}
      </div>
      <DottedSeparator className="mgB05 mgT05" />
      <div className="admin-email card-row flex-left">
        {this.renderEmail(administrator.email)}
      </div>
      <DottedSeparator className="mgB05 mgT05" />
      <div className="admin-phone card-row flex-left">
        {this.renderPhone(administrator.phone)}
      </div>
      <DottedSeparator className="mgB05 mgT05" />
      <div className="admin-role card-row flex-left">
        {this.renderRole(administrator.position)}
      </div>
    </div>
  )

  renderHeader = () => (
    <>
      <HeaderBar
        className="pdG20 w-100 full-height"
        leftContent={(
          <div className="header-value">
            Administradores
          </div>  
        )}
        rightContent={this.props.onClickEdit && this.renderButtonContent()}
      />
    </>
  )

  render() {
    const { administrators, className } = this.props;

    return (
      <div className={`chain-administrators-info ${className}`}>
        <div className="administrators-header fs-20 flex-between admin-row">
          {this.renderHeader()}
        </div>
        <SolidSeparator />
        <div className="administrators-body flex-left">
          {administrators.map((administrator, i) => (
            <div className="administrator-contact flex-left mgB10" key={i}>
              {this.renderAdministrator(administrator, i)}
            </div>
          ))}        
        </div>
      </div>
    );
  }
}
