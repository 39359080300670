import { array } from "prop-types";

/**
 * Splits up a URI by inserting in some values from the body.
 *
 * Example:
 * ```js
   const request = {
     id: 13,
     query: 'hello'
   };
   const mockURI = '/mock/:id/search';
   const result = utils.addURLParams(mockURI, request);

   expect(result).toEqual({
     uri: '/mock/13/search',
     data: {
       query: 'hello'
     }
   });
  ```
 *
 * @param {String} url  The initial URI
 * @param {[type]} body The initial body
 */
const addURLParams = (url: string, body: any) => {
  const components = url.split("/").filter((element) => element.length > 0);

  const resultComponents: any = [];
  const resultParams = { ...body };
  components.forEach((component) => {
    if (component[0] === ":") {
      const prop = component.substring(1, component.length);
      resultComponents.push(resultParams[prop]);
      delete resultParams[prop];
    } else {
      resultComponents.push(component);
    }
  });

  return {
    uri: `/${resultComponents.join("/")}`,
    data: resultParams,
  };
};

export default {
  addURLParams,
};
