import { ICampaignState } from "./CampaignInterfaces";

const CAMPAIGN_INITIAL_STATE: ICampaignState = Object.freeze({
  data: {
    id: 0,
    reservationId: 0,
    name: "",
    startDate: "",
    endDate: "",
    status: 0,
    chains: [],
    canAddVideo: false,
    costs: {
      chains: [],
    },
    offerDays: [],
    offerCampaign: {
      id: 0,
      reservationId: 0,
      name: "",
      startDate: "",
      endDate: "",
      status: 0,
      chains: [],
      canAddVideo: false,
      costs: {
        chains: [],
      },
      isOffer: true,
      offerSelectedDays: [],
    },
    offerSelectedDays:[],
  },
  errors: {},
  permissions: undefined,
});

export default CAMPAIGN_INITIAL_STATE;
