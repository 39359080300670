import React from 'react';
import Tooltip from 'rc-tooltip';

import { PlacementTypes } from '../../../../common/enums/tooltips/PlacementTypes';
import { TriggerTypes } from '../../../../common/enums/tooltips/TriggerTypes';
import { 
  IVerticalMenuTooltipOption, 
  IVerticalMenuTooltipOptionOnClick, 
} from './interfaces';

interface IVerticalMenuTooltipProps {
  placement: PlacementTypes;

  options: IVerticalMenuTooltipOption[];

  visible?: boolean;
  defaultVisible?: boolean;
}

interface IVerticalMenuTooltipState {
  isVisible?: boolean;
}

export const handleCloseTooltipAfter = (method?: VoidFunction) => () => {
  method && method();
  return true;
};

export default class VerticalMenuTooltip extends React.Component<IVerticalMenuTooltipProps, IVerticalMenuTooltipState> {
  static defaultProps = {
    options: [],
    placement: PlacementTypes.RightCenter,
  };

  public readonly state: Readonly<IVerticalMenuTooltipState> = {
    isVisible: this.props.defaultVisible,
  };

  getTooltipVisibility = () => {
    return this.props.visible === undefined ? this.state.isVisible : this.props.visible;
  }

  handleVisibilityChange = (visible?: boolean) => {
    this.setState({
      isVisible: visible,
    });
  }

  getHandleOnClickOption = (onClick?: IVerticalMenuTooltipOptionOnClick) => () => {
    const shouldCloseTooltip = onClick && onClick();

    if (shouldCloseTooltip) this.setState({ isVisible: false });
  }

  renderOption = (
    label: string, 
    onClick: IVerticalMenuTooltipOptionOnClick | undefined, 
    icon: JSX.Element, 
    index: number,
    hidden?: boolean,
    disabled?: boolean,
  ) => {
    if (hidden) return null;

    return (
      <div
        className={`
          tooltip-container 
          fs-14 
          flex-between 
          pdG10 
          border-bottom 
          ${onClick && !disabled ? 'clickable' : ''}
          ${disabled ? 'disabled' : '' }
        `} 
        onClick={disabled ? undefined : this.getHandleOnClickOption(onClick)}
        key={index}
      >
        <div className="tooltip-icon mgR10">
          {icon}
        </div>
        <div className="tooltip-label">
          {label}
        </div>
      </div>
    );
  }

  renderTooltip = () => (
    <div>
      {this.props.options.map((option, index) => {
        return this.renderOption(option.name, option.onClick, option.icon, index, option.hidden, option.disabled);
      })}
    </div>
  )

  render() {
    const { placement } = this.props;

    return (
      <Tooltip
        overlay={this.renderTooltip()}
        trigger={[TriggerTypes.OnClick]}
        placement={placement}
        visible={this.getTooltipVisibility()}
        onVisibleChange={this.handleVisibilityChange}
        overlayClassName="vertical-menu-tooltip"
      >
        <div className="clickable">
          {this.props.children}
        </div>
      </Tooltip>
    );
  }
}
