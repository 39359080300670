
import * as ACTIONS from '../constants/actions';
import { handleActions, Action } from 'redux-actions';

import UiInitialState from './UiInitialState';
import { IUiState, IUiChainDetail, IUiGlobalError } from './IUiInterfaces';

const setAdvertiserDetailActiveTab = (ui: IUiState, action: Action<number>): IUiState => {
  const tabIndex = action.payload || UiInitialState.advertiserDetail.tabIndex;

  return {
    ...ui,
    advertiserDetail: {
      ...ui.advertiserDetail,
      tabIndex,
    },
  };
};

const setAdvertiserWizardStep = (ui: IUiState, action: Action<number>): IUiState => {
  const currentStep = action.payload || UiInitialState.advertiserDetail.currentStep;

  return {
    ...ui,
    advertiserDetail: {
      ...ui.advertiserDetail,
      currentStep,
    },
  };
};

const setChainDetailActiveTab = (ui: IUiState, action: Action<number>): IUiState => {
  const tabIndex = action.payload || UiInitialState.chainDetail.tabIndex;

  return {
    ...ui,
    chainDetail: {
      ...ui.chainDetail,
      tabIndex,
    },
  };
};

const setBranchDetailActiveTab = (ui: IUiState, action: Action<number>): IUiState => {
  const tabIndex = action.payload || UiInitialState.branchDetail.tabIndex;

  return {
    ...ui,
    branchDetail: {
      ...ui.branchDetail,
      tabIndex,
    },
  };
};

const toggleErrorModal = (ui: IUiState, action: Action<Partial<IUiGlobalError>>): IUiState => {
  return {
    ...ui,
    globalError: {
      ...ui.globalError,
      title: action.payload && action.payload.title || '',
      message: action.payload && action.payload.message || '',
      show: !ui.globalError.show,
    },
  };
};

const setCategoryDetailActiveTab = (ui: IUiState, action: Action<number>): IUiState => {
  const tabIndex = action.payload || UiInitialState.advertiserDetail.tabIndex;

  return {
    ...ui,
    categoryDetail: {
      ...ui.categoryDetail,
      tabIndex,
    },
  };
};


const uiReducer = handleActions<IUiState, any>(
  {
    [ACTIONS.SET_CHAIN_DETAIL_ACTIVE_TAB]: setChainDetailActiveTab,
    [ACTIONS.SET_BRANCH_DETAIL_ACTIVE_TAB]: setBranchDetailActiveTab,
    [ACTIONS.TOGGLE_ERROR_MODAL]: toggleErrorModal,
    [ACTIONS.SET_ADVERTISER_DETAIL_ACTIVE_TAB]: setAdvertiserDetailActiveTab,
    [ACTIONS.SET_ADVERTISER_DETAIL_EDIT_STEP]: setAdvertiserWizardStep,
    [ACTIONS.SET_CATEGORY_DETAIL_ACTIVE_TAB]: setCategoryDetailActiveTab,
  },
  UiInitialState,
);

export default uiReducer;
