import StringSanitizer from './TextSanitizer';
import { DR_PHONE_LENGTH, DR_IDENTIFICATION_LENGTH } from '../../constants/data.constants';
import { toInteger } from 'lodash';

export const validateRncOrCedula = (value: string): boolean => {
  if (Number.isNaN(Number(value))) { return false; }

  if (value.length > 11) { return false; }

  if (value.includes('.') || value.includes(' ')) { return false; }

  return true;
};

export const validateDrPhone = (value: string): boolean => {
  const phoneToTest = StringSanitizer.getOnlyNumber(value);
  return phoneToTest.length === DR_PHONE_LENGTH;
};

export const validateIdentification = (value: string): number => {
  const identification = StringSanitizer.getOnlyNumber(value);
  const checker = toInteger(identification.substr(value.length - 1, 1));
  let sum = 0;
  if (identification.length < DR_IDENTIFICATION_LENGTH ) { return 0; }
  for (let i = 0; i < identification.length; i++) {
    let mod = 0;
    if ((i % 2) == 0) { 
      mod = 1 
    } else { 
      mod = 2 
    }
    let res = toInteger(identification.substr(i, 1)) * mod;

    if (res > 9 && mod == 2) {
      res = res - 9;
    }
    sum += res;
  }
  let number = 10 - ((sum % 10)% 10);
  if (number === checker || (number === 10 && checker ===0)) {
    return 1;
  }
  return 2;
};

export const validatePassport = (value: string): number => {
  if (value.length < 9) return 0;
  return 1;
}

export default {
  validateRncOrCedula,
};
