import { combineReducers, createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { Action } from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import session from './session';
import chain from './chain';
import thirdParty from './third-party';
import campaigns from './campaigns';
import campaign from './campaign';
import categories from './categories';
import category from './category';
import chains from './chains';
import contacts from './contacts';
import brands from './brands';
import brand from './brand';
import ui from './ui';
import branch from './branch';
import branches from './branches';
import screens from './screens';
import screen from './screen';
import monitors from './monitors';
import players from './players';
import player from './player';
import advertiser from './advertiser';
import advertisers from './advertisers';
import user from './user';
import users from './users';
import dashboard from './dashboard';
import notifications from './notifications';
import notification from './notification';
import configuration from './configuration';
import monitor from './monitor';
import report from './report';
import representative from './representative'
import reservation from './reservation';
import reservations from './reservations';
import product from './product';
import products from './products';
import dataReducer from './button-pre-reservation/index';
import dataPreservation from './data-pre-reservation/index';
import idPreReservation from './id-pre-reservation/index';
import ruleEditor from './rule-editor/index';


const whitelist = [
  'session',
];

const persistConfig = {
  storage,
  whitelist,
  key: 'root',
};

export interface IState {
  session: any;
  chain: any;
  chains: any;
  campaigns: any;
  campaign: any;
  category: any;
  categories: any;
  contacts: any;
  branch: any;
  branches: any;
  brand: any;
  brands: any;
  screens: any;
  screen: any;
  monitors: any;
  monitor: any;
  players: any;
  player: any;
  advertiser: any;
  advertisers: any;
  ui: any;
  thirdParty: any;
  user: any;
  users: any;
  dashboard: any;
  notifications: any;
  notification: any;
  configuration: any;
  report: any;
  representative:any;
  reservation: any;
  reservations: any;
  product: any;
  products:any;
  dataReducer:any;
  dataPreservation: any;
  idPreReservation: any;
  ruleEditor: any;
}

interface IActions extends Action<any> { }

const combinedReducers = combineReducers<IState, IActions>({
  session: session.reducers,
  chain: chain.reducers,
  chains: chains.reducers,
  thirdParty: thirdParty.reducers,
  categories: categories.reducers,
  campaigns: campaigns.reducers,
  campaign: campaign.reducers,
  category: category.reducers,
  contacts: contacts.reducers,
  branch: branch.reducers,
  branches: branches.reducers,
  brands: brands.reducers,
  brand: brand.reducers,
  screens: screens.reducers,
  screen: screen.reducers,
  monitors: monitors.reducers,
  monitor: monitor.reducers,
  players: players.reducers,
  player: player.reducers,
  advertiser: advertiser.reducers,
  advertisers: advertisers.reducers,
  ui: ui.reducers,
  user: user.reducers,
  users: users.reducers,
  dashboard: dashboard.reducers,
  notifications: notifications.reducers,
  notification: notification.reducers,
  configuration: configuration.reducers,
  report: report.reducers,
  representative: representative.reducers,
  reservation: reservation.reducers,
  reservations: reservations.reducers,
  product: product.reducers,
  products:products.reducers,
  dataReducer: dataReducer.reducers,
  dataPreservation: dataPreservation.reducers,
  idPreReservation: idPreReservation.reducers,
  ruleEditor: ruleEditor.reducers,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const composeEnhancers = composeWithDevTools({});

const store = createStore(persistedReducer, composeEnhancers(
  applyMiddleware(thunk, logger),
));

export default store;
