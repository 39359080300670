import { handleActions, Action } from "redux-actions";
import * as ACTIONS from "../constants/actions";
import { extractDefinedProps } from "../../utilities/ObjectManipulation";
import {
  IAdvertiserState,
  IAdvertiserStateData,
  IAdvertiserStateErrors,
  IAdvertiserStateBrand,
  IAdvertiserStateAdmin,
} from "./AdvertiserInterfaces";
import { IContact } from "../contact/ContactInterfaces";
import ADVERTISER_INITIAL_STATE, {
  DEFAULT_ADVERTISER_STATE_BRAND,
} from "./AdvertiserInitialState";
import { ISetAzulPaymentDataVaultTokenPayload } from "../../payloads/advertiser-admins/SetAzulPaymentDataVaultTokenPayload";

const addEmptyBrandToAdvertiser = (
  advertiserState: IAdvertiserState,
  action: Action<null>
): IAdvertiserState => {
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      brands: [
        {
          ...DEFAULT_ADVERTISER_STATE_BRAND,
        },
        ...(advertiserState.data.brands || []),
      ],
    },
  };
};

const setAdvertiser = (
  advertiserState: IAdvertiserState,
  action: Action<Partial<IAdvertiserStateData>>
): IAdvertiserState => {
  if (!action.payload) return { ...advertiserState };

  const errors = { ...advertiserState.errors };
  errors.rncCode = "VALID";

  return {
    ...advertiserState,
    ...(action.payload || ADVERTISER_INITIAL_STATE),
    errors: {
      ...errors,
    },
    meta: {
      ...ADVERTISER_INITIAL_STATE.meta,
      loading: false,
    },
  };
};

const updateAdvertiserData = (
  advertiserState: IAdvertiserState,
  action: Action<Partial<IAdvertiserStateData>>
): IAdvertiserState => {
  if (!action.payload) return { ...advertiserState };

  const newAdvertiserData: Partial<IAdvertiserStateData> = action.payload;
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      ...extractDefinedProps(newAdvertiserData),
    },
    meta: {
      ...advertiserState.meta,
    },
  };
};

const updateAdvertiserDataBrandsByIndex = (
  advertiserState: IAdvertiserState,
  action: Action<{
    bIndex: number;
    brandData: Partial<IAdvertiserStateBrand>;
  }>
): IAdvertiserState => {
  if (!action.payload) return { ...advertiserState };

  const newBrandData: Partial<IAdvertiserStateBrand> = action.payload.brandData;
  const brandIndex: number = action.payload.bIndex;
  const advertiserBrands =
    advertiserState.data.brands &&
    advertiserState.data.brands.map((brand, index) => {
      return index !== brandIndex
        ? { ...brand }
        : {
            ...brand,
             newAdded: true,
            ...extractDefinedProps(newBrandData),
          };
    });
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      brands: advertiserBrands,
      toCreateBrandObj: advertiserBrands,
    },
  };
};

const addAdvertiserDataBrandsByIndex = (
  advertiserState: IAdvertiserState,
  action: Action<{
    brandData: Partial<IAdvertiserStateBrand>;
  }>
): IAdvertiserState => {
  if (!action.payload) return { ...advertiserState };

  const newBrandData: Partial<IAdvertiserStateBrand> = action.payload.brandData;
  const newBrands = advertiserState.data.brands || [];
  newBrands.unshift({
    ...DEFAULT_ADVERTISER_STATE_BRAND,
    ...extractDefinedProps(newBrandData),
  });
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      brands: newBrands,
    },
  };
};

const updateAdvertiserErrors = (
  advertiserState: IAdvertiserState,
  action: Action<Partial<IAdvertiserStateErrors>>
): IAdvertiserState => {
  if (!action.payload) return { ...advertiserState };

  const newAdvertiserErrors: Partial<IAdvertiserStateErrors> = action.payload;

  return {
    ...advertiserState,
    errors: {
      ...advertiserState.errors,
      ...extractDefinedProps(newAdvertiserErrors),
    },
  };
};

const updateAdvertiserErrorsByRnc = (
  advertiserState: IAdvertiserState,
  action: Action<{
    rncCode: string;
    advertiserErrors: Partial<IAdvertiserStateErrors>;
  }>
): IAdvertiserState => {
  if (
    !action.payload ||
    action.payload.rncCode !== advertiserState.data.rncCode
  ) {
    return { ...advertiserState };
  }

  const newAdvertiserErrors: Partial<IAdvertiserStateErrors> =
    action.payload.advertiserErrors;

  return {
    ...advertiserState,
    errors: {
      ...advertiserState.errors,
      ...extractDefinedProps(newAdvertiserErrors),
    },
  };
};

const resetAdvertiserState = (
  advertiserState: IAdvertiserState,
  action: Action<null>
): IAdvertiserState => ({
  ...ADVERTISER_INITIAL_STATE,
});

const setAdvertaiserAdmin = (
  advertiserState: IAdvertiserState,
  action: Action<IContact>
): IAdvertiserState => {
  const admin = action.payload || advertiserState.data.admin;
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      admin: {
        ...advertiserState.data.admin,
        ...admin,
      },
    },
  };
};

const createAdvertiserSucceed = (
  advertiser: IAdvertiserState,
  action: Action<IAdvertiserState>
): IAdvertiserState => {
  return {
    ...advertiser,
    errors: {
      rncCode: advertiser.errors.rncCode,
      createdSuccessful: true,
    },
  };
};

const updateEmailExists = (
  advertiserState: IAdvertiserState,
  action: Action<boolean>
): IAdvertiserState => {
  const emailExists = action.payload;
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      admin: {
        ...advertiserState.data.admin,
        emailExists: emailExists,
      },
    },
  };
};

const updateIdExists = (
  advertiserState: IAdvertiserState,
  action: Action<boolean>
): IAdvertiserState => {
  const idExists = action.payload;
  return {
    ...advertiserState,
    data: {
      ...advertiserState.data,
      admin: {
        ...advertiserState.data.admin,
        idExists: idExists ? 1 : 2,
      },
    },
  };
};

const fetchCurrentAdvertiserIdFail = (
  state: IAdvertiserState,
  action: Action<null>
): IAdvertiserState => {
  return {
    ...state,
  };
};

const createAdvertiserFail = (
  advertiser: IAdvertiserState,
  action: Action<IAdvertiserState>
): IAdvertiserState => {
  return {
    ...advertiser,
    errors: {
      rncCode: advertiser.errors.rncCode,
      createdSuccessful: false,
    },
  };
};

const setAdvetiserIsEditingSingleStep = (
  advertiser: IAdvertiserState,
  action: Action<{ value: boolean }>
): IAdvertiserState => {
  return {
    ...advertiser,
    meta: {
      isEditingSingleStep: action.payload
        ? action.payload.value
        : ADVERTISER_INITIAL_STATE.meta.isEditingSingleStep,
    },
  };
};

const editAdvertiserSucceed = (
  advertiser: IAdvertiserState,
  action: Action<IAdvertiserStateData>
): IAdvertiserState => {
  const data = action.payload || advertiser.data;
  return {
    ...advertiser,
    data: {
      ...data,
    },
    errors: {
      rncCode: advertiser.errors.rncCode,
      createdSuccessful: true,
    },
  };
};

const removeBrandToAdvertiserSuceed = (
  state: IAdvertiserState,
  action: Action<{ bIndex: number; cIndex: number; toCreateBrandName: string }>
): IAdvertiserState => {
  const brands = state.data.brands;
  const toCreateBrandNames = state.data.toCreateBrandName;
  const toCreateBrandName = action.payload && action.payload.toCreateBrandName;
  const bIndex = action.payload && action.payload.bIndex;
  const cIndex = action.payload && action.payload.cIndex;
  let newBrands: IAdvertiserStateBrand[] =
    (brands &&
      brands.map((brand) => {
        if (brand.id === bIndex) {
          const categories = brand.categories.filter((c) => cIndex !== c.id);
          brand.categories = categories;
        }
        return brand;
      })) ||
    [];
  newBrands = newBrands.filter((b) => b.categories.length !== 0);

  let newBrandNames =
    toCreateBrandNames &&
    toCreateBrandNames.filter((name) => name !== toCreateBrandName);

  return {
    ...state,
    data: {
      ...state.data,
      brands: newBrands,
      toCreateBrandObj: [...newBrands],
      toCreateBrandName: [...newBrandNames || []],
    },
  };
};

const fetchCurrentAdvertiserIdSuccess = (
  state: IAdvertiserState,
  action: Action<number>
): IAdvertiserState => {
  if (!action.payload) return { ...state };

  return {
    ...state,
    data: {
      ...ADVERTISER_INITIAL_STATE.data,
      id: action.payload,
    },
  };
};

const updateEmailExistsFail = (
  state: IAdvertiserState,
  action: Action<null>
): IAdvertiserState => {
  return {
    ...state,
    data: {
      ...ADVERTISER_INITIAL_STATE.data,
    },
  };
};

const updateIdExistsFail = (
  state: IAdvertiserState,
  action: Action<null>
): IAdvertiserState => {
  return {
    ...state,
    data: {
      ...ADVERTISER_INITIAL_STATE.data,
    },
  };
};

const setAzulPaymentDataVaultTokenToAdvertiserAdmin = (
  advertiser: IAdvertiserState,
  action: Action<ISetAzulPaymentDataVaultTokenPayload>
): IAdvertiserState => {
  let currentAdvertiserAdmin;
  let newAdvertiserAdmins: IAdvertiserStateAdmin[] = [];
  const setAzulPaymentDataVaultTokenPayload = action.payload;
  if (setAzulPaymentDataVaultTokenPayload) {
    const currentAdvertiserAdmins = advertiser.data.admins;
    if (currentAdvertiserAdmins && currentAdvertiserAdmins.length > 0) {
      newAdvertiserAdmins = [...currentAdvertiserAdmins];
      currentAdvertiserAdmin = newAdvertiserAdmins.find(
        (x) => x.id === setAzulPaymentDataVaultTokenPayload.advertiserAdminId
      );
      if (currentAdvertiserAdmin) {
        const newAdvertiserAdmin = { ...currentAdvertiserAdmin };
        newAdvertiserAdmin.azulPaymentDataVaultToken =
          setAzulPaymentDataVaultTokenPayload.azulPaymentDataVaultToken;
        newAdvertiserAdmin.azulPaymentDataVaultTokenExpiration =
          setAzulPaymentDataVaultTokenPayload.azulPaymentDataVaultTokenExpiration;

        const index = newAdvertiserAdmins
          .map((x) => x.id)
          .indexOf(currentAdvertiserAdmin.id);
        if (index > -1) {
          newAdvertiserAdmins.splice(index, 1);
        }

        newAdvertiserAdmins.push(newAdvertiserAdmin);
      }
    }
  }

  return {
    ...advertiser,
    data: {
      ...advertiser.data,
      admins: newAdvertiserAdmins,
    },
  };
};

const advertiserReducer = handleActions<IAdvertiserState, any>(
  {
    [ACTIONS.GET_ADVERTISER_BY_ID_SUCCEED]: setAdvertiser,
    [ACTIONS.UPDATE_ADVERTISER_DATA]: updateAdvertiserData,
    [ACTIONS.UPDATE_ADVERTISER_ERRORS]: updateAdvertiserErrors,
    [ACTIONS.UPDATE_ADVERTISER_ERRORS_BY_RNC]: updateAdvertiserErrorsByRnc,
    [ACTIONS.RESET_ADVERTISER_STATE]: resetAdvertiserState,
    [ACTIONS.SET_ADVERTISER_ADMIN]: setAdvertaiserAdmin,
    [ACTIONS.UPDATE_ADVERTISER_DATA_BRANDS_BY_INDEX]: updateAdvertiserDataBrandsByIndex,
    [ACTIONS.ADD_ADVERTISER_DATA_BRANDS_BY_INDEX]: addAdvertiserDataBrandsByIndex,
    [ACTIONS.ADVERTISER_EMAIL_EXISTS]: updateEmailExists,
    [ACTIONS.ADVERTISER_EMAIL_EXISTS_FAIL]: updateEmailExistsFail,
    [ACTIONS.ADVERTISER_ID_EXISTS]: updateIdExists,
    [ACTIONS.ADVERTISER_ID_EXISTS_FAIL]: updateIdExistsFail,
    [ACTIONS.ADD_EMPTY_BRAND_TO_ADVERTISER]: addEmptyBrandToAdvertiser,
    [ACTIONS.REMOVE_BRAND_TO_ADVERTISER]: removeBrandToAdvertiserSuceed,
    [ACTIONS.CREATE_ADVERTISER_SUCCEED]: createAdvertiserSucceed,
    [ACTIONS.CREATE_ADVERTISER_FAIL]: createAdvertiserFail,
    [ACTIONS.EDIT_ADVERTISER_BY_ID_SUCCESS]: editAdvertiserSucceed,
    [ACTIONS.GET_ADVERTISER_BY_USER_ID_SUCCESS]: setAdvertiser,
    [ACTIONS.SET_ADVERTISER_IS_EDITING_SINGLE_STEP]: setAdvetiserIsEditingSingleStep,
    [ACTIONS.FETCH_CURRENT_ADVERTISER_ID_SUCCESS]: fetchCurrentAdvertiserIdSuccess,
    [ACTIONS.FETCH_CURRENT_ADVERTISER_ID_FAIL]: fetchCurrentAdvertiserIdFail,
    [ACTIONS.SET_AZUL_PAYMENT_DATA_VAULT_TOKEN]: setAzulPaymentDataVaultTokenToAdvertiserAdmin,
  },
  ADVERTISER_INITIAL_STATE
);

export default advertiserReducer;
