type TDelayedFunctionCallback<T extends any[]> = (...args: T) => void;

export default class DelayedFunction<T extends any[]> {
  callback: TDelayedFunctionCallback<T>;
  args: T | undefined;
  delay: number;
  lastExecuteTime: number;
  timeoutId: number;

  constructor(delay: number, callback: TDelayedFunctionCallback<T>) {
    this.delay = delay;
    this.callback = callback;
    this.lastExecuteTime = Date.now();
    this.timeoutId = 0;
  }

  handleTimeout = () => {
    const currentTime = Date.now();

    if (currentTime - this.lastExecuteTime < this.delay) {
      this.createNewTimeout(this.lastExecuteTime + this.delay - currentTime);
      return;
    }
    
    this.timeoutId = 0;
    this.args && this.callback(...this.args);
  }

  createNewTimeout = (timeout: number) => {
    this.timeoutId = window.setTimeout(
      this.handleTimeout, 
      timeout,
    );
  }

  execute: TDelayedFunctionCallback<T> = (...args) => {
    this.args = args;
    this.lastExecuteTime = Date.now();

    if (this.timeoutId) return;
    
    this.createNewTimeout(this.delay);
  }

  halt = () => {
    if (!this.timeoutId) return; 
    
    clearTimeout(this.timeoutId);
  }
}
