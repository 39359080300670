import { IReservationState } from './ReservationInterfaces';

const RESERVATION_INITIAL_STATE: IReservationState = {
  data: {
    id: 0,
    brand:{
      id: 0,
      name: '',
    },
    category:{
      id: 0,
      name: '',
    },
    brands:[],
    categories:[],
    blocks:[],
    sblock:{
      brandId: 0,
      categoryId: 0,
      startDate: '',
      endDate: '',
      category:{
        id:0,
        name:''
      },
      brand:{
        id:0,
        name:''
      },
      chains:[],
      branches: [],
      areas:[],
      sections: [],
    },
    reservationType: 'date',
    campaignCount: 0,
    offerCount: 0,
    status: 0,
    advertiser: {
      id: 0,
      name: ''
    },
    campaigns:[],
    prices:{
      standardPrice: 0,
      areaPrice: 0,
      loopExtraPrice: 0
    },
    createdAt: '',
    updatedAt: '',
  },
  errors: {
  },
  permissions: undefined,
};

export default RESERVATION_INITIAL_STATE;
