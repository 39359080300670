import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import {
  IAdvertisersState,
  IAdvertisersStateDataItem,
  IAdvertisersStateErrors,
  TAdvertisersStateDataUpdater,
} from './AdvertisersInterfaces';
import ADVERTISERS_INITIAL_STATE from './AdvertisersInitialState';

const setAdvertisersData = (advertisersState: IAdvertisersState, action: Action<IAdvertisersStateDataItem[]>)
  : IAdvertisersState => {
  if (!action.payload) return { ...advertisersState };

  const newAdvertisersData: IAdvertisersStateDataItem[] = action.payload;

  return ({
    ...advertisersState,
    data: [
      ...newAdvertisersData,
    ],
  });
};

const updateAdvertisersData = (
  advertisersState: IAdvertisersState,
  action: Action<TAdvertisersStateDataUpdater>,
): IAdvertisersState => {

  if (!action.payload) return { ...advertisersState };

  const updateData: TAdvertisersStateDataUpdater = action.payload;

  return {
    ...advertisersState,
    data: [...advertisersState.data.map(advertisersItem => updateData(advertisersItem))],
  };

};

const updateAdvertisersErrors = (
  advertisersState: IAdvertisersState,
  action: Action<Partial<IAdvertisersStateErrors>>,
): IAdvertisersState => {

  if (!action.payload) return { ...advertisersState };

  const newAdvertisersErrors: Partial<IAdvertisersStateErrors> = action.payload;

  return {
    ...advertisersState,
    errors: {
      ...advertisersState.errors,
      ...extractDefinedProps(newAdvertisersErrors),
    },
  };

};

const resetAdvertisersState = (advertisersState: IAdvertisersState, action: Action<null>): IAdvertisersState => ({
  ...ADVERTISERS_INITIAL_STATE,
});

const updateAdvertiserSuspendableById = (
  advertisersState: IAdvertisersState,
  action: Action<{
    id: number,
    suspendable: boolean | undefined,
  }>,
): IAdvertisersState => {

  if (!action.payload) return { ...advertisersState };

  const {
    suspendable,
    id: advertiserId,
  } = action.payload;

  return {
    ...advertisersState,
    data: advertisersState.data.map((advertiser) => {
      return {
        ...advertiser,
        isSuspendable: advertiser.id === advertiserId ? suspendable : advertiser.isSuspendable,
      };
    }),
  };
};


const updateAdvertiserRemovableById = (
  advertisersState: IAdvertisersState,
  action: Action<{
    id: number,
    removable: boolean | undefined,
  }>,
): IAdvertisersState => {

  if (!action.payload) return { ...advertisersState };

  const { removable, id } = action.payload;

  return {
    ...advertisersState,
    data: advertisersState.data.map((advertiser) => {
      return {
        ...advertiser,
        isRemovable: advertiser.id === id ? removable : advertiser.isRemovable,
      };
    }),
  };
};


const suspendAdvertiserById = (advertisersState: IAdvertisersState, action: Action<number>): IAdvertisersState => {
  if (!action.payload) return { ...advertisersState };

  const advertiserId = action.payload;

  return {
    ...advertisersState,
    data: [
      ...advertisersState.data.map((advertiser) => {
        return {
          ...advertiser,
          active: advertiser.id === advertiserId ? false : advertiser.active,
        };
      }),
    ],
  };
};

const activateAdvertiserById = (advertisersState: IAdvertisersState, action: Action<number>): IAdvertisersState => {
  if (!action.payload) return { ...advertisersState };

  const advertiserId = action.payload;

  return {
    ...advertisersState,
    data: [
      ...advertisersState.data.map((advertiser) => {
        return {
          ...advertiser,
          active: advertiser.id === advertiserId ? true : advertiser.active,
        };
      }),
    ],
  };
};

const deleteAdvertiserSucceed = (advertisersState: IAdvertisersState, action: Action<number>): IAdvertisersState => {
  if (!action.payload) return { ...advertisersState };

  const id = action.payload;

  return {
    ...advertisersState,
    data: [
      ...advertisersState.data.filter(e => e.id !== id),
    ],
  };
};

const deleteAdvertiserFailed = (
  advertisersState: IAdvertisersState,
  action: Action<{ err: string }>,
): IAdvertisersState => {
  if (!action.payload) return { ...advertisersState };

  return {
    ...advertisersState,
    errors: {
      search: '',
      delete: action.payload.err,
    },
  };
};


const advertisersReducer = handleActions<IAdvertisersState, any>(
  {
    [ACTIONS.SET_ADVERTISERS_DATA]: setAdvertisersData,
    [ACTIONS.UPDATE_ADVERTISERS_DATA]: updateAdvertisersData,
    [ACTIONS.UPDATE_ADVERTISERS_ERRORS]: updateAdvertisersErrors,
    [ACTIONS.RESET_ADVERTISERS_STATE]: resetAdvertisersState,
    [ACTIONS.UPDATE_ADVERTISER_SUSPENDABLE_BY_ID]: updateAdvertiserSuspendableById,
    [ACTIONS.SUSPEND_ADVERTISER_BY_ID]: suspendAdvertiserById,
    [ACTIONS.ACTIVATE_ADVERTISER_BY_ID]: activateAdvertiserById,
    [ACTIONS.DELETE_ADVERTISER_BY_ID_SUCCESS]: deleteAdvertiserSucceed,
    [ACTIONS.DELETE_ADVERTISER_BY_ID_FAIL]: deleteAdvertiserFailed,
    [ACTIONS.UPDATE_ADVERTISER_REMOVABLE_BY_ID]: updateAdvertiserRemovableById,
  },
  ADVERTISERS_INITIAL_STATE,
);

export default advertisersReducer;
