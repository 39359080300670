import React, { Component } from "react";

import TableGeneric from "../table-generic";

import {
  TRowChainArray,
  IRowChain,
  TOptionOnClickCallback,
  TClickRowCallback,
} from "./interfaces";
import CONSTANTS from "./constants";
import {
  ITableConfig,
  IColumnHeader,
  TColumnHeaderArray,
} from "../table-generic/interfaces";
import icons from "../../../../resources/assets/icons";
import { ChainOptionTooltip } from "../../../../containers/components/chains";
import { compareCaseInsensitive } from "../../../utilities/sorting";

interface ITableChainProps {
  dataObjects: TRowChainArray;
  config: ITableConfig;
  onClickRow: TClickRowCallback;
  onClickEdit: TClickRowCallback;
  className: string;
  toggleActiveSuspend: TClickRowCallback;
  onClickDelete: TClickRowCallback;
  optionsTooltipNotVisible?: boolean;
}

export default class TableChain extends Component<ITableChainProps> {
  static defaultProps = {
    config: { ...CONSTANTS.DEFAULT_TABLE_CONFIG },
    className: "",
  };

  handleOptionOnClick = (
    value: IRowChain,
    onClick: TOptionOnClickCallback
  ) => () => {
    onClick(value);
  };
  handleOnClickRow = (row: IRowChain) => () => this.props.onClickRow(row);

  getColumnName = () => ({
    header: <div className="mgL10">Nombre</div>,
    renderer: (value: IRowChain) => (
      <div
        className="mgL10 d-flex flex-left fill-container clickable"
        onClick={this.handleOnClickRow(value)}
      >
        {value.name}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      compareCaseInsensitive(first.name, second.name),
    minWidth: 2,
  });

  getColumnInitialDate = () => ({
    header: "Ingreso",
    renderer: (value: IRowChain) => (
      <div
        className="clickable d-flex flex-left fill-container text--capitalize"
        onClick={this.handleOnClickRow(value)}
      >
        {value.initialDate.format("MMMM DD, YYYY")}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      first.initialDate > second.initialDate ? 1 : -1,
    minWidth: 1.5,
  });

  getColumnBranchCount = () => ({
    header: "Sucursales",
    renderer: (value: IRowChain) => (
      <div
        className="d-flex flex-left fill-container clickable"
        onClick={this.handleOnClickRow(value)}
      >
        {value.branchCount}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      first.branchCount > second.branchCount ? 1 : -1,
  });

  getColumnScreenCount = () => ({
    header: "Pantallas",
    renderer: (value: IRowChain) => (
      <div
        className="d-flex flex-left fill-container clickable"
        onClick={this.handleOnClickRow(value)}
      >
        {value.screenCount}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      first.screenCount > second.screenCount ? 1 : -1,
  });

  getColumnAdvertiserCount = () => ({
    header: "Anunciantes",
    renderer: (value: IRowChain) => (
      <div
        className="d-flex flex-left fill-container clickable"
        onClick={this.handleOnClickRow(value)}
      >
        {value.advertiserCount}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      first.advertiserCount > second.advertiserCount ? 1 : -1,
  });

  getColumnCategoryCount = () => ({
    header: "Categorías",
    renderer: (value: IRowChain) => (
      <div
        className="d-flex flex-left fill-container clickable"
        onClick={this.handleOnClickRow(value)}
      >
        {value.fatherCategoriesCount}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      first.fatherCategoriesCount > second.fatherCategoriesCount ? 1 : -1,
  });

  getColumnSlotCount = () => ({
    header: "Slots",
    renderer: (value: IRowChain) => (
      <div
        className="d-flex flex-left fill-container clickable"
        onClick={this.handleOnClickRow(value)}
      >
        {value.slotCount}
      </div>
    ),
    sorter: (first: IRowChain, second: IRowChain) =>
      first.slotCount > second.slotCount ? 1 : -1,
    maxWidth: 71,
    minWidth: 70,
  });

  isTooltipVisible = (value: IRowChain) => {
    if (this.props.optionsTooltipNotVisible) {
      return false;
    }
    return undefined;
  };

  getColumnOption = () => ({
    header: "",
    renderer: (value: IRowChain) => (
      <ChainOptionTooltip
        active={value.active}
        visible={this.isTooltipVisible(value)}
        onClickEdit={() => this.props.onClickEdit(value)}
        toggleActiveSuspend={() => this.props.toggleActiveSuspend(value)}
        onClickDelete={() => this.props.onClickDelete(value)}
        className="fill-container"
      >
        <div
          className="d-flex justify-content-center"
          onClick={this.handleOptionOnClick(value, () => {})}
        >
          {<icons.IconMore />}
        </div>
      </ChainOptionTooltip>
    ),
    maxWidth: 31,
    minWidth: 30,
  });

  getColumns = (): TColumnHeaderArray<IRowChain> => [
    this.getColumnName(),
    this.getColumnInitialDate(),
    this.getColumnBranchCount(),
    this.getColumnScreenCount(),
    this.getColumnAdvertiserCount(),
    this.getColumnCategoryCount(),
    this.getColumnSlotCount(),
    this.getColumnOption(),
  ];

  render() {
    const { dataObjects, config, className } = this.props;

    return (
      <div className={`table-chain ${className}`}>
        <TableGeneric
          dataObjects={dataObjects}
          columns={this.getColumns()}
          config={config}
          className={className}
        />
      </div>
    );
  }
}
