import React, { Component } from "react";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPickerSingleDateController } from "react-dates";

import moment, { Moment } from "moment";

interface IDatePickerProps {
  startDate: Moment | null;
  endDate: Moment | null;
  numberOfMonths: number;
  onDatesChange: (date: Moment) => void;
  isOutsideRange?: (day: Moment) => boolean;
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  minimumNights?: number;
  className: string;
  selectedDays: Moment[];
}

interface State {
  focused: boolean;
  containerWidth: number;
  selectedDays: Moment[];
}

export default class DatePicker extends Component<IDatePickerProps, State> {
  static defaultProps = {
    numberOfMonths: 1,
    className: "",
  };

  public readonly state: Readonly<State> = {
    focused: false,
    containerWidth: 0,
    selectedDays: [],
  };

  container: HTMLDivElement | null = null;

  componentDidMount() {
    window.addEventListener("resize", this.updateContainerWidth);
    this.updateContainerWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateContainerWidth);
  }

  updateContainerWidth = () => {
    this.setState({
      containerWidth: this.container ? this.container.offsetWidth : 0,
    });
  };

  getDaySize = () => {
    return Math.trunc(this.state.containerWidth / 15.20139);
  };

  handleOnFocusChange = () => {
    this.setState({
      focused: true,
    });
  };

  handleOnDateChange = (date: Moment | null) => {
    if (date) {
      this.props.onDatesChange(date);
    }
  };

  isDateSelected = (date: any) => {
    return this.props.selectedDays.some((day) => day.isSame(date));
  };

  RenderDayContent = (date: any) =>
    this.isDateSelected(date) ? "✅" : date.format("D");

  render() {
    const {
      startDate,
      numberOfMonths,
      onDatesChange,
      isOutsideRange,
      className,
    } = this.props;

    return (
      <div
        className={`
          date-picker 
          text-capitalize
          ${className}
        `}
        ref={(element) => (this.container = element)}>
        {!!this.state.containerWidth && (
          <DayPickerSingleDateController
            date={null}
            onDateChange={(day) => this.handleOnDateChange(day)}
            focused={true}
            onFocusChange={this.handleOnFocusChange}
            isOutsideRange={isOutsideRange}
            initialVisibleMonth={() => startDate || moment()}
            numberOfMonths={numberOfMonths}
            keepOpenOnDateSelect
            renderDayContents={this.RenderDayContent}
          />
        )}
      </div>
    );
  }
}
