import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import PRODUCT_INITIAL_STATE from './ProductInitialState';
import {
  IProductState,
  IProductStateData,
  IProductStateCategory,
  IProductStateErrors,
  IProductStateMeta,
} from './ProductInterfaces';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';


const setProduct = (productState: IProductState, action: Action<IProductState>): IProductState => ({
  ...productState,
  ...action.payload || PRODUCT_INITIAL_STATE,
});

const updateProductData = (productState: IProductState, action: Action<Partial<IProductStateData>>): IProductState => {
  if (!action.payload) return { ...productState };

  const newBrandData: Partial<IProductStateData> = action.payload;

  return {
    ...productState,
    data: {
      ...productState.data,
      ...extractDefinedProps(newBrandData),
    },
  };
};

const setCategoryToProduct = (productState: IProductState, action: Action<IProductStateCategory>): IProductState => {
  if (!action.payload) return { ...productState };

  return {
    ...productState,
    data: {
      ...productState.data,
      category: action.payload,
    },
  };
};

const updateProductErrors = (productState: IProductState, action: Action<Partial<IProductStateErrors>>): IProductState => {
  if (!action.payload) return { ...productState };

  const newBrandErrors: Partial<IProductStateErrors> = action.payload;

  return {
    ...productState,
    errors: {
      ...productState.errors,
      ...extractDefinedProps(newBrandErrors),
    },
  };
};

const updateProductMeta = (productState: IProductState, action: Action<Partial<IProductStateMeta>>): IProductState => {
  if (!action.payload) return { ...productState };

  const newBrandMeta: Partial<IProductStateMeta> = action.payload;

  return {
    ...productState,
    meta: {
      ...productState.meta,
      ...extractDefinedProps(newBrandMeta),
    },
  };
};

const resetProductState = (productState: IProductState, action: Action<null>): IProductState => ({
  ...PRODUCT_INITIAL_STATE,
});

const resetBrandErrorsState = (productState: IProductState, action: Action<{ key: string }>): IProductState => {
  if (action.payload && action.payload.key) {
    return {
      ...productState,
      errors: {
        ...productState.errors,
        [action.payload.key]: '',
      },
    };
  }
  return {

    ...productState,
    errors: {
      ...PRODUCT_INITIAL_STATE.errors,
    },
  };
};

const productReducer = handleActions<IProductState, any>(
  {
    [ACTIONS.UPDATE_PRODUCT_DATA]: updateProductData,
    [ACTIONS.RESET_PRODUCT_STATE]: resetProductState,
    [ACTIONS.SET_CATEGORY_TO_PRODUCT]: setCategoryToProduct,
    [ACTIONS.UPDATE_PRODUCT_ERRORS]: updateProductErrors,
    [ACTIONS.UPDATE_PRODUCT_META]: updateProductMeta,
    [ACTIONS.GET_PRODUCT_BY_ID_SUCCEED]: setProduct,
    [ACTIONS.GET_PRODUCT_BY_ID_FAIL]: resetProductState,
    [ACTIONS.RESET_PRODUCT_ERRORS_STATE]: resetBrandErrorsState,
  },
  PRODUCT_INITIAL_STATE,
);

export default productReducer;
