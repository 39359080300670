import { IUserState, IUserStateData } from './UserInterfaces';
// Properties defaults
export const DEFAULT_USER_STATE: IUserStateData = {
  id: '',
  name: '',
  lastName: '',
  email: '',
  phone: '',
  roleId: 0,
  createdAt: '',
  activity: [],
};

// Initial State
const USERS_INITIAL_STATE: IUserState = {
  data: DEFAULT_USER_STATE,
  errors: {
    search: '',
  },
  meta: {
    message: '',
  },
};

export default USERS_INITIAL_STATE;
