import * as ACTIONS from "../constants/actions";
import { handleActions, Action } from "redux-actions";

import ChainInitialState from "./ChainInitialState";
import {
  IChain,
  IChainRates,
  IChainData,
} from "./ChainInterfaces";
import { IContact } from "../contact/ContactInterfaces";

const setDataAttribute = (
  chain: IChain,
  action: Action<{ key: string; value: string }>
): IChain => {
  const key = action.payload ? action.payload.key : "";
  const value = action.payload ? action.payload.value : "";
  return {
    ...chain,
    data: {
      ...chain.data,
      [key]: value,
    },
  };
};

const setMetaDataAttribute = (
  chain: IChain,
  action: Action<{ key: string; value: string }>
): IChain => {
  const key = action.payload ? action.payload.key : "";
  const value = action.payload ? action.payload.value : "";
  return {
    ...chain,
    meta: {
      ...chain.meta,
      [key]: value,
    },
  };
};

const setRatesAttribute = (
  chain: IChain,
  action: Action<{ rates: IChainRates[] }>
): IChain => {
  const rates = action.payload ? action.payload.rates : [];
  return {
    ...chain,
    data: {
      ...chain.data,
      rates: [...rates],
    },
  };
};

const resetChainData = (chain: IChain): IChain => ({
  ...chain,
  data: {
    ...ChainInitialState.data,
  },
  meta: {
    ...ChainInitialState.meta,
  },
});

const createChainSucceed = (
  chain: IChain,
  action: Action<{ key: string; value: string }>
): IChain => {
  return {
    ...chain,
    meta: {
      ...chain.meta,
      successful: false,
      logoChanged: false,
    },
  };
};

const createChainFail = (
  chain: IChain,
  action: Action<{ key: string; value: string }>
): IChain => {
  return {
    ...chain,
    meta: {
      ...chain.meta,
      successful: false,
    },
  };
};

const getChainSucceed = (chain: IChain, action: Action<IChainData>): IChain => {
  let chainData = action.payload || ChainInitialState.data;
  chainData = {
    ...chainData, rates: chainData.rates.length > 0 ? chainData.rates : ChainInitialState.data.rates
  }
  return {
    ...chain,
    data: {
      ...chainData,
    },
    meta: {
      ...chain.meta,
      successful: true,
      logoChanged: false,
      loading:false
    },
  };
};

const editChainSucceed = (
  chain: IChain,
  action: Action<IChainData>
): IChain => {
  const chainData = action.payload || ChainInitialState.data;

  return {
    ...chain,
    data: {
      ...chainData,
    },
    meta: {
      ...chain.meta,
      successful: true,
      logoChanged: false,
    },
  };
};

const editChainFail = (chain: IChain, action: Action<any>): IChain => {
  return {
    ...chain,
  };
};

const getChainFail = (chain: IChain, action: Action<any>): IChain => {
  return {
    ...chain,
    meta: {
      ...chain.meta,
      successful: false,
    },
  };
};

const setChainAdmins = (chain: IChain, action: Action<IContact[]>): IChain => {
  const newAdmins = action.payload || [];

  return {
    ...chain,
    data: {
      ...chain.data,
      admins: [...newAdmins],
    },
  };
};

const chainReducer = handleActions<IChain, any>(
  {
    [ACTIONS.SET_CHAIN_DATA_ATTRIBUTE]: setDataAttribute,
    [ACTIONS.SET_CHAIN_META_DATA_ATTRIBUTE]: setMetaDataAttribute,
    [ACTIONS.SET_CHAIN_RATES_ATTRIBUTE]: setRatesAttribute,
    [ACTIONS.CREATE_CHAIN_SUCCEED]: createChainSucceed,
    [ACTIONS.CREATE_CHAIN_FAIL]: createChainFail,
    [ACTIONS.GET_CHAIN_BY_ID_SUCCEED]: getChainSucceed,
    [ACTIONS.GET_CHAIN_BY_ID_FAIL]: getChainFail,
    [ACTIONS.EDIT_CHAIN_SUCCEED]: editChainSucceed,
    [ACTIONS.EDIT_CHAIN_FAIL]: editChainFail,
    [ACTIONS.SET_CHAIN_ADMINS]: setChainAdmins,
    [ACTIONS.RESET_CHAIN_ATTRIBUTES]: resetChainData,
  },
  ChainInitialState
);

export default chainReducer;
