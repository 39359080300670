import { INotificationsState } from './NotificationsInterfaces';

const NOTIFICATIONS_INITIAL_STATE: INotificationsState = {
  data: [],
  meta: {
    pendingToRead: 0,
  },
};

export default NOTIFICATIONS_INITIAL_STATE;
