import { lazy } from 'react';

const BranchList = lazy(() => import('./branch-list'));;
const CreateBranch = lazy(() => import('./create-branch'));;
const BranchDetail = lazy(() => import('./branch-detail'));;
const BranchEdit = lazy(() => import('./branch-edit'));;

export default {
  BranchList,
  CreateBranch,
  BranchDetail,
  BranchEdit,
};
