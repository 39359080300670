
import { IBranchState, IBranchStateContact } from './BranchInterfaces';
import { ValueTypes } from '../../enums/rates/ValueTypes';
import { RateTypes } from '../../enums/rates/RateTypes';

export const DEFAULT_BRANCH_STATE_CONTACT: IBranchStateContact = {
  id: 0,
  name: '',
  position: '',
  email: '',
  phone: '',
};

const BRANCH_INITIAL_STATE: IBranchState = {
  data: {
    id: 0,
    name: '',
    city: '',
    address: '',
    phone: '',

    screens: [],
    schedules: [],
    contacts: [],
    rates: [
      {
        type: RateTypes.Daily,
        valueType: ValueTypes.number,
        amount: 3,
      },
      {
        type: RateTypes.Weekly,
        valueType: ValueTypes.number,
        amount: 2,
      },
      {
        type: RateTypes.Monthly,
        valueType: ValueTypes.number,
        amount: 1,
      },
      {
        type: RateTypes.Area,
        valueType: ValueTypes.percent,
        amount: 0,
      },
      {
        type: RateTypes.ExtraLoop,
        valueType: ValueTypes.percent,
        amount: 0,
      },  
    ],
    areas: [],
    alleys: [],
  },
  stats: {
    activeScreensCount: 0,
    totalScreensCount: 0,
    advertisersCount: 0,
    campaignsWaitingForApprovalCount: 0,
    activeCategoriesCount: 0,
    totalCategoriesCount: 0,  
    totalAdvertisersCount: 0,
  },
  errors: {
    create: '',
    name: '',
    stats: '',
    update:'',
  },
  meta: {
    message: '',
    loading: true,
  },
};

export default BRANCH_INITIAL_STATE;
