const CURRENT: string = 'current-step';
const DONE: string = 'done-step';
const PENDING: string = 'pending-step';
const LAST: string = 'last-step';

const ICON_COLOR: string = 'rgb(0,99,173)';
const ICON_WIDTH: string = '100%';
const ICON_HEIGHT: string = '100%';

const CURRENT_CIRCLE: string = 'current-circle';
const DONE_CIRCLE: string = 'done-circle';
const PENDING_CIRCLE: string = 'pending-circle';

const CONSTANTS = {
  CURRENT,
  DONE,
  PENDING,
  LAST,
  ICON_COLOR,
  ICON_WIDTH,
  ICON_HEIGHT,
  CURRENT_CIRCLE,
  DONE_CIRCLE,
  PENDING_CIRCLE,
};

export default CONSTANTS;
