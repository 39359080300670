import moment from "moment";
import { RateTypes } from "../../enums/rates/RateTypes";
import { ValueTypes } from "../../enums/rates/ValueTypes";
import { IChain } from "./ChainInterfaces";

const CHAIN_INITIAL_STATE: IChain = {
  data: {
    id: 0,
    name: "",
    rncCode: "",
    logo: "",
    active: true,
    imageName: "",
    createdAt: moment(),
    screensWithCampaigns: 0,
    advertisersActive: 0,
    advertisersByChain: 0,
    admins: [],
    rates: [
      {
        type: RateTypes.Daily,
        valueType: ValueTypes.number,
        amount: 3,
      },
      {
        type: RateTypes.Weekly,
        valueType: ValueTypes.number,
        amount: 2,
      },
      {
        type: RateTypes.Monthly,
        valueType: ValueTypes.number,
        amount: 1,
      },
      {
        type: RateTypes.Area,
        valueType: ValueTypes.percent,
        amount: 0,
      },
      {
        type: RateTypes.ExtraLoop,
        valueType: ValueTypes.percent,
        amount: 0,
      },
      {
        type: RateTypes.MinimumCampaignsPerCategory,
        valueType: ValueTypes.number,
        amount: 10,
      },
      {
        type: RateTypes.MaximumCampaignsPerScreen,
        valueType: ValueTypes.number,
        amount: 10,
      },
      {
        type: RateTypes.PaymentPercentage,
        valueType: ValueTypes.percent,
        amount: 10,
      },
      {
        type: RateTypes.PaymentDay,
        valueType: ValueTypes.number,
        amount: 30,
      },
    ],
    branches: [],
    fatherCategories: [],
    accountantEmail: ''
  },
  meta: {
    successful: false,
    logoChanged: false,
    loading: false,
  },
};

export default CHAIN_INITIAL_STATE;
