import { TFormatFunction } from './interfaces';

const DEFAULT_PRECISION: number = 4;
const DEFAULT_MAX: number = Number.POSITIVE_INFINITY;
const DEFAULT_MIN: number = Number.NEGATIVE_INFINITY;
const DEFAULT_STEP: number = 1;
const DEFAULT_ID: string = '';
const ICON_HEIGHT: string = '100%';
const ICON_WIDTH: string = '100%';
const ICON_COLOR: string = 'rgb(100, 100, 100)';
const FOCUS_CLASS: string = 'focused';
const BLUR_CLASS: string = 'blurred';
const PRE_ICON: string = '';
const AFTER_ICON: string = '';
const BACKSPACE_CODE: number = 8;
const DEFAULT_FORMAT: TFormatFunction = (value, latestKey) => {
  if (value === null) return '';
  if (latestKey === BACKSPACE_CODE && value < 0.01) return '';

  return value.toString();
};

const CONSTANTS = {
  DEFAULT_PRECISION,
  DEFAULT_STEP,
  DEFAULT_MAX,
  DEFAULT_MIN,
  ICON_HEIGHT,
  ICON_WIDTH,
  ICON_COLOR,
  FOCUS_CLASS,
  BLUR_CLASS,
  PRE_ICON,
  AFTER_ICON,
  DEFAULT_ID,
  DEFAULT_FORMAT,
  BACKSPACE_CODE,
};

export default CONSTANTS;
