export const advertiserAdmins = {
  getAdvertiserAdminById: {
    method: 'get',
    url: '/advertiserAdmins/:id',
  },
  setAzulPaymentDataVaultToken: {
    method: 'put',
    url: '/advertiserAdmins/setAzulPaymentDataVaultToken',
  },
};
