
import * as ACTIONS from '../constants/actions';
import { IUiChainDetail, IUiState } from './IUiInterfaces';

export const setChainDetailActiveTab = (tabIndex: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_CHAIN_DETAIL_ACTIVE_TAB, payload: tabIndex });
};
export const setAdvertiserDetailActiveTab = (tabIndex: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_CHAIN_DETAIL_ACTIVE_TAB, payload: tabIndex });
};
export const setAdvertiserWizardStep = (tabIndex: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_ADVERTISER_DETAIL_EDIT_STEP, payload: tabIndex });
};
export const setBranchDetailActiveTab = (tabIndex: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_BRANCH_DETAIL_ACTIVE_TAB, payload: tabIndex });
};
export const toggleErrorModal = (show: boolean) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.TOGGLE_ERROR_MODAL, payload: show });
};
export const resetToDefaultActiveTab = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_CHAIN_DETAIL_ACTIVE_TAB, payload: 0 });
};
export const setCategoryDetailActiveTab = (tabIndex: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_CATEGORY_DETAIL_ACTIVE_TAB, payload: tabIndex });
};

export default {
  setChainDetailActiveTab,
  setBranchDetailActiveTab,
  setAdvertiserDetailActiveTab,
  resetToDefaultActiveTab,
  setAdvertiserWizardStep,
  setCategoryDetailActiveTab,
};
