export const termsAndConditions = {
  submitTermsAndCondition: {
    method: 'post',
    url: '/termsandconditions',
  },
  submitUserCertification: {
    method: 'post',
    url: '/usercertification',
  },
  submitNewBrandsAdded: {
    method: 'post',
    url: '/newBrandsAdded',
  },
  submitBrandsRemoved: {
    method: 'post',
    url: '/brandsRemoved',
  },
  getTermsAndConditionsAcepted: {
    method: 'get',
    url: '/termsandconditions/:token',
  },
};