import React from 'react';

export interface ArrowInformationBoxProps {
  header: string;
  content: string;
  onClick: Function;
}

export default class ArrowInformationBox extends React.PureComponent<ArrowInformationBoxProps> {

  onClick = () => {
    if (this.props.onClick) return this.props.onClick();
  }

  render() {
    return (
      <div className="information-box" onClick={this.onClick}>
        <div>
          <div>
            <label className="information-box__header font-size-medium" >{this.props.header}</label>
          </div>
          <div>
            <h6 className="information-box__content font-size-large" >{this.props.content}</h6>
          </div>
        </div>
        <i className="information-box__nav "></i>
      </div>
    );
  }
}
