
import * as ACTIONS from '../constants/actions';
import { handleActions, Action } from 'redux-actions';

import { ICategory } from './CategoryInterfaces';
import CATEGORY_INITIAL_STATE from './CategoryInitialState';
import { IChainData } from '../chain/ChainInterfaces';

export const setCategory = (category: ICategory, action: Action<ICategory>) => ({
  ...(action.payload || CATEGORY_INITIAL_STATE),
});

const resetCategoryToInitialState = (category: ICategory, action: Action<null>) => ({
  ...CATEGORY_INITIAL_STATE,
});

const addChainToCategory = (category: ICategory, action: Action<IChainData>) => {
  if (!action.payload) return { ...category };

  const chainData = action.payload;
  const newCategoryChains = [...category.chains];
  newCategoryChains.push({ ...chainData });

  return {
    ...category,
    chains: [
      ...newCategoryChains,  
    ],   
  };
};

const updateChainInCategory = (category: ICategory, action: Action<IChainData>) => {
  if (!action.payload) return { ...category };

  const chainData = action.payload;
  const newCategoryChains = category.chains.map(chain => ({
    ...(chain.id !== chainData.id ? chain : chainData),
  }));

  return {
    ...category,
    chains: [
      ...newCategoryChains,  
    ],   
  };
};

const removeChainFromCategory = (category: ICategory, action: Action<number>) => {
  if (!action.payload) return { ...category };

  const chainId = action.payload;
  const newCategoryChains = category.chains.filter(chain => chain.id !== chainId);

  return {
    ...category,
    chains: [
      ...newCategoryChains,  
    ],
  };
};

const updateCategoryFailed = (category: ICategory, action: Action<null>) => ({
  ...category,
});

const categoryReducer = handleActions<ICategory, any>(
  {
    [ACTIONS.SET_CATEGORY]: setCategory,
    [ACTIONS.ADD_CHAIN_TO_CATEGORY]: addChainToCategory,
    [ACTIONS.REMOVE_CHAIN_FROM_CATEGORY]: removeChainFromCategory,
    [ACTIONS.UPDATE_CHAIN_IN_CATEGORY]: updateChainInCategory,
    [ACTIONS.RESET_CATEGORY_TO_INITIAL_STATE]: resetCategoryToInitialState,
    [ACTIONS.RESET_CATEGORY_TO_INITIAL_STATE]: resetCategoryToInitialState,
    [ACTIONS.UPDATE_CATEGORY_FAILED]: updateCategoryFailed,
  },
  CATEGORY_INITIAL_STATE,
);

export default categoryReducer;
