import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import ADVERTISER_ROUTE_CONSTANTS from "../common/constants/routes/advertiser-routes.constants";
import lazyPages from "../pages/async";

const advertiserRoutes = () => (
  <Switch>
    <Redirect
      exact
      from={ADVERTISER_ROUTE_CONSTANTS.ROOT}
      to={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.DASHBOARD}
      component={lazyPages.Dashboard.AdvertiserDashboard}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESTORE_PASSWORD}
      component={lazyPages.Authorization.RestorePassword}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.NOTIFICATIONS}
      component={lazyPages.Notifications.NotificationList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS}
      component={lazyPages.Campaigns.CampaignList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_DETAIL}
      component={lazyPages.Campaigns.CampaignDetail}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_EDIT}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.CAMPAIGNS_CREATE}
      component={lazyPages.Campaigns.CreateCampaign}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS}
      component={lazyPages.Reservations.ReservationList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS_CREATE}
      component={lazyPages.Reservations.CreateReservation}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS_CART}
      component={lazyPages.Reservations.CartReservation}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESERVATIONS_DETAIL}
      component={lazyPages.Reservations.ReservationDetail}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS}
      component={lazyPages.Reports.ChainReports}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS_SALES}
      component={lazyPages.Reports.AdminReportsAboutSales}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS_RESERVATIONS}
      component={lazyPages.Reports.AdminReportsAboutReservations}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.REPORTS_MERAKI}
      component={lazyPages.Reports.AdminReportsAboutMeraki}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.ACCOUNT}
      component={lazyPages.Advertisers.AdvertiserAccount}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.ACCOUNT_EDIT}
      component={lazyPages.Advertisers.AdvertiserAccountEdit}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.PRODUCTS}
      component={lazyPages.Products.ProductList}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.TERMS_AND_CONDITIONS}
      component={lazyPages.TermsAndConditions.TermsAndConditions}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.USER_CERTIFICATION}
      component={lazyPages.UserCertification.UserCertification}
    />
    <Route
      exact
      path={ADVERTISER_ROUTE_CONSTANTS.RESEND_EMAILS}
      component={lazyPages.ResendEmails.ResendEmails}
    />

    <Route
      path="*"
      component={() => <Redirect to={ADVERTISER_ROUTE_CONSTANTS.DASHBOARD} />}
    />
  </Switch>
);

export default advertiserRoutes;
