import { lazy } from 'react';

const AdvertiserReports = lazy(() => import('./AdvertiserReports'));;
const AdvertiserReportsAboutBrands = lazy(() => import('./advertiser-reports-about-brands'));;
const AdvertiserReportsAboutCampaigns = lazy(() => import('./advertiser-reports-about-campaigns'));;
const AdvertiserReportsAboutCredits = lazy(() => import('./advertiser-reports-about-credits'));;

export default {
  AdvertiserReports,
  AdvertiserReportsAboutBrands,
  AdvertiserReportsAboutCampaigns,
  AdvertiserReportsAboutCredits,
};
