export const campaigns = {
  searchCampaigns: {
    method: "get",
    url: "/campaigns",
  },
  getCampaignById: {
    method: "get",
    url: "/campaigns/:id",
  },
  createCampaign: {
    method: "post",
    url: "/campaigns",
  },
  updateCampaign: {
    method: "put",
    url: "/campaigns/:id",
  },
  approveCampaign: {
    method: "put",
    url: "/campaigns/:id/approve",
  },
  deleteCampaign: {
    method: "delete",
    url: "/campaigns/:id",
  },
  declineCampaign: {
    method: "put",
    url: "/campaigns/:id/decline",
  },
  cancelCampaign: {
    method: "put",
    url: "/campaigns/:id/cancel",
  },
  checkoutCampaign: {
    method: "patch",
    url: "/campaigns/:id/checkout",
  },
  calculateCampaignPrices: {
    method: "get",
    url: "/campaigns/prices",
  },
  getCampaignCostsBreakdown: {
    method: "get",
    url: "/campaigns/:id/costs",
  },
  discardCampaignChanges: {
    method: "patch",
    url: "/campaigns/:id/discard",
  },
};
