import Users from './users/async';
import Chains from './chains/async';
import Brands from './brands/async';
import Screens from './screens/async';
import Reports from './reports/async';
import Products from './products/async';
import Dashboard from './dashboard/async';
import Campaigns from './campaigns/async';
import Categories from './categories/async';
import Advertisers from './advertisers/async';
import Reservations from './reservations/async';
import ResendEmails from './resendemails/async';
import Authorization from './authorization/async';
import Notifications from './notifications/async';
import Configuration from './configuration/async';
import UnapprovalUser from './unactivate-user/async';
import UserCertification from './usercertification/async';
import TermsAndConditions from './termsandconditions/async';
import AdvertiserNotContent from './advertiser-not-content/async';

const lazyPages = {
  Users,
  Chains,
  Brands,
  Screens,
  Reports,
  Products,
  Dashboard,
  Campaigns,
  Categories,
  Advertisers,
  Reservations,
  ResendEmails,
  Authorization,
  Notifications,
  Configuration,
  UnapprovalUser,
  UserCertification,
  TermsAndConditions,
  AdvertiserNotContent,
};

export default lazyPages;
