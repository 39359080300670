import { IMonitorState } from './MonitorInterfaces';

const MONITOR_INITIAL_STATE: IMonitorState = {
  data: {
    id: 0,
    serialCode: '',
    manufacturer: '',
    model: '',
    size: 0,
  },
  errors: {
    create: '',
    update: '',
    delete: '',
  },
};

export default MONITOR_INITIAL_STATE;
