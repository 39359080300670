import React from "react";
import Tooltip, { RCTooltip } from "rc-tooltip";

import icons from "../../../../resources/assets/icons";
import { PlacementTypes } from "../../../../common/enums/tooltips/PlacementTypes";
import { TriggerTypes } from "../../../../common/enums/tooltips/TriggerTypes";
import { IRowChain } from "../../../../common/components/tables/table-chain/interfaces";

interface IChainOptionTooltip {
  onClickEdit?: VoidFunction;
  toggleActiveSuspend?: VoidFunction;
  onClickDelete?: VoidFunction;
  placement: PlacementTypes;
  active: boolean;
  visible?: boolean;

  className: string;
}

export default class ChainOptionTooltip extends React.Component<
  IChainOptionTooltip
> {
  static defaultProps = {
    placement: PlacementTypes.LeftCenter,
    active: true,
    className: "",
  };

  renderOption = (
    label: string,
    onClick: VoidFunction | undefined,
    icon: JSX.Element
  ) => {
    const clickable = onClick ? "clickable" : "";

    return (
      <div
        className={`chain-option--container fs-14 flex-between pdG10 border-bottom ${clickable}`}
        onClick={onClick}
      >
        <div className="chain-option--icon mgR10">{icon}</div>
        <div className="chain-option--label">{label}</div>
      </div>
    );
  };

  renderActiveOrSuspendOption = () => {
    if (this.props.active) {
      return this.renderOption(
        "Suspender cadena",
        this.props.toggleActiveSuspend,
        <icons.Suspend width="100%" height="100%" />
      );
    }
    return this.renderOption(
      "Activar cadena",
      this.props.toggleActiveSuspend,
      <icons.UploadIcon width="100%" height="100%" />
    );
  };

  renderTooltip = () => (
    <div>
      {this.props.active &&
        this.renderOption(
          "Editar cadena",
          this.props.onClickEdit,
          <icons.Edit width="100%" height="100%" />
        )}
      {this.renderActiveOrSuspendOption()}
      {this.props.active &&
        this.renderOption(
          "Eliminar cadena",
          this.props.onClickDelete,
          <icons.Delete width="100%" height="100%" />
        )}
    </div>
  );

  render() {
    const { placement, visible, className } = this.props;
    const optionalParams: Partial<RCTooltip.Props> = {};

    if (visible !== undefined) optionalParams["visible"] = visible;

    return (
      <Tooltip
        {...optionalParams}
        overlay={this.renderTooltip()}
        trigger={[TriggerTypes.OnClick]}
        placement={placement}
        overlayClassName="chain-option-tooltip"
      >
        <div className={`d-flex flex-center clickable ${className}`}>
          {this.props.children}
        </div>
      </Tooltip>
    );
  }
}
