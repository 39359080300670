import BaseService from '../BaseService';
import api from '../api';
import { AxiosPromise, AxiosResponse } from 'axios';

export const rncServiceUrl = process.env.REACT_APP_RNC_API_URL;
export const rncServiceUser = process.env.REACT_APP_RNC_API_USER;
export const rncServicePassword = process.env.REACT_APP_RNC_API_PASS;

export default class RncService extends BaseService {

  static login(): Promise<any> {
    const url = `${rncServiceUrl}/v1/login`;
    return RncService.post(url, { username: rncServiceUser, password: rncServicePassword });
  }

  static async getBusinessInfo(rncCode: string): Promise<any> {
    const url = `${rncServiceUrl}/v1/rnc/${rncCode}`;
    const authentication = await RncService.login();
    const { token } = authentication.data;
    const axiosConfig = {
      headers: {
        Authorization: `${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    };
    return this.get(url, {}, axiosConfig);
  }
}
