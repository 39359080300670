import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import HomeRoutes from "./HomeRoutes";
import AdvertiserRoutes from "./AdvertiserRoutes";
import ChainAdminRoutes from "./ChainAdminRoutes";
import AdvertiserNotContentRoutes from "./AdvertiserWaitingRoutes";
import { ISession } from "../common/store/session/SessionInterfaces";
import { IUiState } from "../common/store/ui/IUiInterfaces";
import GeneralErrorModal from "../containers/errors/general-error-modal";
import { UserTypes } from "../common/enums/users/UserTypes";
import { UserStatus } from "../common/enums/users/UserStatus";
import Loading from "../common/components/loading/Loading";

export interface IRoutesProps {
  session: ISession;
  ui: IUiState;
}

class Routes extends React.Component<IRoutesProps, any> {
  constructor(props: IRoutesProps) {
    super(props);
  }

  renderGuestRoutes = () => <HomeRoutes />;

  renderAuthRoutes = () => {
    const role = this.props.session.data.roleId;
    const statusId = this.props.session.data.statusId;

    if (statusId === UserStatus.Active) {
      switch (role) {
        case UserTypes.Admin:
          return <AdminRoutes />;
        case UserTypes.AdvertiserAdmin:
          return <AdvertiserRoutes />;
        case UserTypes.AccountsManager:
          return <AdvertiserNotContentRoutes />;
        case UserTypes.ChainAdmin:
          return <ChainAdminRoutes />;
        default:
          return <HomeRoutes />;
      }
    }
  };

  switchRoles = () => {
    if (
      this.props.session.data.roleId &&
      this.props.session.data.token.length > 0
    ) {
      return this.renderAuthRoutes();
    }
    return this.renderGuestRoutes();
  };

  render() {
    return (
      <div id="main" className="h-100">
        <Router>
          <React.Suspense fallback={<Loading />}>
            <Switch>{this.switchRoles()}</Switch>
          </React.Suspense>
        </Router>
        <GeneralErrorModal />
      </div>
    );
  }
}

const mapStateToProps = ({ session, ui }: { session: any; ui: IUiState }) => ({
  session,
  ui,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
