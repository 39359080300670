import { IConfigurationState } from "./ConfigurationInterfaces";

const CONFIGURATION_INITIAL_STATE: IConfigurationState = {
  data: {
    monthlyPrice: 0,
    suddenPublicationRequiredDays: 0,
    suddenPublicationExtraCostPercentage: 0,
    itbisPercentage: 0,
    blocksStartDate: "",
    dollarCurrently: 0,
  },
  errors: {
    fetch: "",
    udpate: "",
  },
};

export default CONFIGURATION_INITIAL_STATE;
