export const branches = {
  searchBranches: {
    method: 'get',
    url: '/branches',
  },
  searchBranchesForStats: {
    method: 'get',
    url: '/branches/stats',
  },
  getFreeSlotsByBranches: {
    method: 'get',
    url: '/branches/slots/free',
  },
  createBranch: {
    method: 'post',
    url: '/branches/',
  },
  getBranchById: {
    method: 'get',
    url: '/branches/:id',
  },
  editBranch: {
    method: 'put',
    url: '/branches/:id',
  },
  suspendBranch: {
    method: 'patch',
    url: '/branches/:id/suspend',
  },
  activateBranch: {
    method: 'patch',
    url: '/branches/:id/activate',
  },
  addBranchInformation: {
    method: 'post',
    url: '/branches/:id/information',
  },
};
