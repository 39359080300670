import { lazy } from 'react';
import AdminReports from './admin-reports/async';
import AdvertiserReports from './advertiser-reports/async';
import ChainReports from './chain-reports/async';

const ReportList = lazy(() => import('./report-list'));;

export default {
  ...AdminReports,
  ...AdvertiserReports,
  ...ChainReports,
  ReportList,
};
