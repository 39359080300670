import { lazy } from 'react';

const CampaignList = lazy(() => import('./campagin-list'));;
const CreateCampaign = lazy(() => import('./create-campaign'));;
const CampaignDetail = lazy(() => import('./campaign-detail'));;
const EditCampaign = lazy(() => import('./edit-campaign'));;

export default {
  CampaignList,
  CreateCampaign,
  CampaignDetail,
  EditCampaign,
};
