
const HOME_ROUTES_CONSTANTS = Object.freeze({
  FORGOT_PASSWORD: '/forgotPassword',
  CREATE_ADVERTISER: '/createAdvertiser',
  RESTORE_PASSWORD: '/restorePassword',
  LOGIN: '/',
  TERMS_AND_CONDITIONS:'/termsandconditions',
  USER_CERTIFICATION: '/usercertification',
  RESEND_EMAILS:'/resendemails',
});

export default HOME_ROUTES_CONSTANTS;
