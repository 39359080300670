import React from 'react';

export interface IContentBox {
  header: string;
  content: string;
}

export default class ContentBox extends React.PureComponent<IContentBox> {
  
  render = () => {
    return (
      <div className="information-box">
        <div>
          <label className="information-box__header" >{this.props.header}</label>
        </div>
        <div>
          <h6 className="information-box__content" >{this.props.content}</h6>
        </div>
      </div>
    );
  }
}
