import { INotificationState, INotificationStateData } from './NotificationInterfaces';
import { handleActions, Action } from 'redux-actions';
import NOTIFICATION_INITIAL_STATE from './NotificationInitialState';
import * as ACTIONS from '../constants/actions';


const setNotificationSucceed = (state: INotificationState, action: Action<INotificationStateData>)
  : INotificationState => {
  if (!action.payload) {
    return { ...state };
  }

  return {
    ...state,
    data: {
      ...action.payload,
    },
  };
};

const notificationsReducer = handleActions<INotificationState, any>(
  {
    [ACTIONS.SET_NOTIFICATION_SUCCEED]: setNotificationSucceed,
  },
  NOTIFICATION_INITIAL_STATE,
);

export default notificationsReducer;
