import React from 'react';
import { IFloatingLabelTextInputProps, IFloatingLabelTextInputState } from './interfaces';

export default class FloatingLabelTextInput extends React.PureComponent<IFloatingLabelTextInputProps, IFloatingLabelTextInputState> {

  public readonly state: Readonly<IFloatingLabelTextInputState> = {
    showPlaceHolder: true,
  };

  static defaultProps = {
    id: '',
    disabled: false,
    name: '',
    value: '',
    onClick: () => { },
    onFocus: () => { },
    onBlur: () => { },
    onKeyUp: () => { },
    onChange: () => { },
    type: 'text',
    required: true,
    autoComplete: 'on',
    icon: '',
    placeholder: '',
    tabIndex: 0,
    containerClassName: '',
    withLabel: true,
    readOnly: false,
    inputContainerClassName: '',
  };

  private onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (!this.props.onChange) return;
    this.props.onChange(val);
  }

  private onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (this.props.onClick) {
      this.props.onClick(event.target);
    }
  }

  private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event.target);
    }
  }

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(event.target.value);
    }

    if (this.state.showPlaceHolder && !this.props.readOnly) {
      this.setState({ showPlaceHolder: false });
    }
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event.target.value);
    }

    if (!this.state.showPlaceHolder) {
      this.setState({ showPlaceHolder: true });
    }
  }

  render() {
    const {
      icon, 
      value, 
      tabIndex, 
      placeHolder,
      inputContainerClassName,
    } = this.props;

    let { showPlaceHolder } = this.state;

    let inputClassName = 'form-control email-input';
    let labelClassName = 'email-input__label';

    if (this.props.value) {
      inputClassName = 'form-control email-input__not-empty';

      if (showPlaceHolder) {
        showPlaceHolder = false;
        labelClassName = 'email-input__label__not-empty';
      }
    }

    const placeHolderValue = showPlaceHolder ? placeHolder : '';

    return (
      <div className={this.props.containerClassName}>
        <div className={`${inputContainerClassName} form-input`}>
          <label className={labelClassName}>
            {showPlaceHolder ? null : this.props.label}
          </label>

          {icon && <span className={icon} />}
          <input
            id={this.props.id}
            name={this.props.name}
            type={this.props.type}
            className={`${inputContainerClassName} ${inputClassName}`}
            onChange={this.onTextChange}
            onFocus={this.onFocus}
            onKeyUp={this.onKeyUp}
            onClick={this.onClick}
            onBlur={this.onBlur}
            value={value}
            disabled={this.props.disabled}
            required={this.props.required}
            autoComplete={this.props.autoComplete}
            min={this.props.min}
            max={this.props.max}
            placeholder={placeHolderValue}
            tabIndex={tabIndex}
            readOnly={this.props.readOnly}
            onKeyPress={this.props.onKeyPress}
            onKeyDown={this.props.onKeyDown}
          />
        </div>
      </div>
    );
  }
}
