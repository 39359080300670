import { INotificationState } from './NotificationInterfaces';

const NOTIFICATION_INITIAL_STATE: INotificationState = {
  data: {
    id: 0,
    type: 0,
    entityType: 0,
    userNotificationId: 0,
    user: {
      id: '',
      name: '',
      number: '',
      email: '',
    },
  },
  errors: {

  },
};

export default NOTIFICATION_INITIAL_STATE;
