import React, { Component } from 'react';
import { CheckChangeCallback, CheckboxValue, ICheckbox } from './interfaces';
import CONSTANTS from './constants';

import Icons from '../../../../resources/assets/icons';

interface Props extends ICheckbox {
  onCheckChange?: CheckChangeCallback;
}

export default class Checkbox extends Component<Props> {
  static defaultProps = {
    isChecked: false,
  };

  static create(id: string, value: CheckboxValue, isChecked = false): ICheckbox {
    return {
      id, value, isChecked,
    };
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { onCheckChange } = this.props;
    const newValue = e.target.checked;

    onCheckChange && onCheckChange(newValue, id);
  }

  getHandleInputChange = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, id);

  renderIcon = (isChecked: boolean) => {
    if (!isChecked) return null;

    return (
      <Icons.OutlineCheck
        width={CONSTANTS.DEFAULT_ICON_WIDTH}
        height={CONSTANTS.DEFAULT_ICON_HEIGHT}
        fill={CONSTANTS.DEFAULT_ICON_COLOR}
      />
    );
  }

  renderContent = (isChecked: boolean) => {
    const checkClass = isChecked ? 'checked' : 'unchecked';
    const iconContent = this.renderIcon(isChecked);

    return (
      <div className={`checkmark ${checkClass}`}>
        {iconContent}
      </div>
    );
  }

  render() {
    const { value, isChecked, id, disabled } = this.props;
    return (
      <div className="checkbox-component">
        {this.renderContent(isChecked)}
        <input 
          id={id}
          type="checkbox" 
          value={value} 
          checked={isChecked}
          disabled={disabled}
          onChange={this.getHandleInputChange(id || '')}
        />
      </div>
    );
  }
}
