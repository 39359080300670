import { handleActions, Action } from 'redux-actions';
import * as ACTIONS from '../constants/actions';

const initialState = {
    id: 0
};

const idPrereservation = (state = initialState, action: Action<any>) => {
    switch (action.type) {
        case ACTIONS.SET_ID_PRE_RESERVATION:
            return {
                ...state,
                id: action.payload,
            };
        default:
            return state;
    }
};

const idPrereservations = handleActions<any, any>(
    {
        [ACTIONS.SET_ID_PRE_RESERVATION]: idPrereservation,
    },
    initialState
);

export default idPrereservations;
