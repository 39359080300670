import { lazy } from 'react';

const ChainReports = lazy(() => import('./ChainReports'));
const ChainSellsReports = lazy(() => import('./ChainReports'));
const ChainReportsAboutBranches = lazy(() => import('./chain-reports-about-branches'));
const ChainReportsAboutCategories = lazy(() => import('./chain-reports-about-categories'));
const AdminReportsAboutReservations = lazy(() => import('../admin-reports/admin-reports-about-reservations'));;
const AdminReportsAboutSales = lazy(() => import('../admin-reports/admin-reports-about-sales'));;

export default {
  ChainReports,
  ChainSellsReports,
  ChainReportsAboutBranches,
  ChainReportsAboutCategories,
  AdminReportsAboutReservations,
  AdminReportsAboutSales
};
