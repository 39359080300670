import { ICategoriesState } from './CategoriesInterfaces';

const CATEGORIES_INITIAL_STATE: ICategoriesState = {
  data: [],
  isCreating: false,
  meta: {
    message: '',
  },
};

export default CATEGORIES_INITIAL_STATE;
