import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HOME_ROUTES_CONSTANTS from '../common/constants/routes/home-routes.constants';
import lazyPages from '../pages/async';

const homeRouter = () => (
  <Switch>
    <Route exact path="/" component={lazyPages.Authorization.Login} />
    <Route exact path={HOME_ROUTES_CONSTANTS.RESTORE_PASSWORD} component={lazyPages.Authorization.RestorePassword} />
    <Route exact path={HOME_ROUTES_CONSTANTS.LOGIN} component={lazyPages.Authorization.Login} />
    <Route exact path={HOME_ROUTES_CONSTANTS.FORGOT_PASSWORD} component={lazyPages.Authorization.PasswordResetEmail} />
    <Route exact path={HOME_ROUTES_CONSTANTS.CREATE_ADVERTISER} component={lazyPages.Authorization.CreateAdvertiser}  />
    <Route exact from={HOME_ROUTES_CONSTANTS.TERMS_AND_CONDITIONS} component={lazyPages.TermsAndConditions.TermsAndConditions} />
    <Route exact from={HOME_ROUTES_CONSTANTS.USER_CERTIFICATION} component={lazyPages.UserCertification.UserCertification} />
    <Route exact from={HOME_ROUTES_CONSTANTS.RESEND_EMAILS} component={lazyPages.ResendEmails.ResendEmails} />
    <Redirect to="/" />
  </Switch>
);

export default homeRouter;
