import { IChains } from './ChainsInterfaces';
import ThynkadsResponse from '../../dtos/ThynkadsResponse';
import { IChainData } from '../chain/ChainInterfaces';

const CHAINS_INITIAL_STATE: ThynkadsResponse<IChainData[]> = {
  statusCode: 0,
  message: undefined,
  data: [],
  meta: {},
};

export default CHAINS_INITIAL_STATE;
