import { IDashboardState } from './DashboardInterfaces';

const DASHBOARD_INITIAL_STATE: IDashboardState = {
  data: {
    admin: {
      stats: {
        activityPercent: 0,
        activeScreens: {
          current: 0,
          total: 0,
        },
        campaigns: {
          active: 0,
          total: 0,
        },
        extraBilling: 0,
      },
    },
    advertiser: {
      stats: {
        brandsAmount: 0,
        campaignsInProgressAmount: 0,
        campaignsWaitingForApproval: 0,
        availableCredits: 0,
      },
    },
    accountManager: {
      stats: {
        campaigns: {
          approved: 0,
          declined: 0,
          pendingApproval: 0,
        },
      },
    },
    chainAdmin: {
      stats: {
        campaigns: {
          inProgress: 0,
        },
        availableSlots: 0,
        activeScreens: 0,
        totalScreens: 0,
      },
    },
  },
  errors: {
  },
};

export default DASHBOARD_INITIAL_STATE;
