import React from 'react';
import Modal from 'react-modal';
import { RectangleButton } from '..';
import icons from '../../../resources/assets/icons';

interface IFormModal {
  isOpen: boolean;
  header: string | JSX.Element;

  onSubmit: Function;
  submitButtonLabel: string;
  submitButtonEnabled: boolean;
  hideSubmitButton: boolean;

  onCancel: Function;
  hideExitButton: boolean;
  exitButtonEnabled: boolean;

  closeButtonLabel: string;
  onClose: Function;
  closeButtonEnabled: boolean;
  hideCloseButton: boolean;

  footerClassName: string;
  cancelClassName: string;
  submitClassName: string;
  modalBodyClassName: string;
  mainClassName: string;
  contentClassName: string;
  centerin: boolean;
}

export default class FormModal extends React.Component<IFormModal> {

  static defaultProps = {
    isOpen: false,
    onSubmit: () => { },
    onClose: () => { },
    onCancel: () => { },
    header: '',
    closeButtonLabel: 'Cancel',
    submitButtonLabel: 'Submit',
    submitButtonEnabled: true,
    closeButtonEnabled: true,
    exitButtonEnabled: true,
    hideSubmitButton: false,
    hideCloseButton: false,
    hideExitButton: false,
    footerClassName: 'modal-footer',
    cancelClassName: 'mgL16',
    submitClassName: 'mgL16',  
    modalBodyClassName: 'pdT0 pdB0 pdL16 pdR16',
    mainClassName: '',
    contentClassName: '',
    centerin: false
  };

  renderCloseButton = () => !this.props.hideCloseButton && (
    <RectangleButton
      disabled={!this.props.closeButtonEnabled}
      className={`btn btn-outline-primary fs-14 ${this.props.cancelClassName}`}
      onClick={this.props.onCancel ? this.props.onCancel : this.props.onClose}
    >
      {this.props.closeButtonLabel}
    </RectangleButton>
  )

  renderSubmitButton = () => !this.props.hideSubmitButton && (
    <RectangleButton
      disabled={!this.props.submitButtonEnabled}
      onClick={this.props.onSubmit}
      className={`btn btn-primary fs-14 ${this.props.submitClassName}`}
    >
      {this.props.submitButtonLabel}
    </RectangleButton>
  )
 //modal-dialog-centered
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="Modal z-index-10"
        aria-labelledby="ScrollingLongContentExampleLabel"
      >
        {this.props.centerin ?  <div className="modal-dialog modal-dialog-centered justify-content-center" role="document" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <div className={`modal--main ${this.props.mainClassName}`} role="document">
            <div className={`modal-content ${this.props.contentClassName}`}>
              <div className="modal-header">
                <h4>
                  {this.props.header}
                </h4>
                {!this.props.hideExitButton && (
                  <RectangleButton
                    className={`btn btn-link`}
                    disabled={!this.props.exitButtonEnabled}
                    onClick={this.props.onClose}
                  >
                    {<icons.Close />}
                  </RectangleButton>
                )}
              </div>

              <div className={`modal-body ${this.props.modalBodyClassName}`}>
                {this.props.children}
              </div>

              <div className={this.props.footerClassName}>
                {this.renderCloseButton()}
                {this.renderSubmitButton()}
              </div>
            </div>
          </div>
        </div> :  <div className="modal-dialog justify-content-center" role="document" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <div className={`modal--main ${this.props.mainClassName}`} role="document">
            <div className={`modal-content ${this.props.contentClassName}`}>
              <div className="modal-header">
                <h4>
                  {this.props.header}
                </h4>
                {!this.props.hideExitButton && (
                  <RectangleButton
                    className={`btn btn-link`}
                    disabled={!this.props.exitButtonEnabled}
                    onClick={this.props.onClose}
                  >
                    {<icons.Close />}
                  </RectangleButton>
                )}
              </div>

              <div className={`modal-body ${this.props.modalBodyClassName}`}>
                {this.props.children}
              </div>

              <div className={this.props.footerClassName}>
                {this.renderCloseButton()}
                {this.renderSubmitButton()}
              </div>
            </div>
          </div>
        </div> }
       
      </Modal>
    );
  }
}
