import React, { Component, ChangeEvent } from 'react';
import { TInputPercentageOnChangeCallback, TFormatFunction } from './interfaces';
import CONSTANTS from './constants';
import NumericInput from 'react-numeric-input';

import Icons from '../../../../resources/assets/icons';

interface IInputPercentageProps {
  max?: number;
  min: number;
  step: number;
  precision: number;
  currentValue: string;
  preIcon: JSX.Element | string;
  afterIcon?: JSX.Element | string;
  onChange: TInputPercentageOnChangeCallback;
  className: string;
  id?: string;
  format?: TFormatFunction;

  onFocus: VoidFunction;
  onBlur: VoidFunction;
}

interface IInputPercentageState {
  focusClass: string;
}

export default class InputPercentage extends Component<IInputPercentageProps, IInputPercentageState> {
  static defaultProps = {
    className: '',
    preIcon: CONSTANTS.PRE_ICON,
    afterIcon: CONSTANTS.AFTER_ICON,
    precision: CONSTANTS.DEFAULT_PRECISION,
    step: CONSTANTS.DEFAULT_STEP,
    max: CONSTANTS.DEFAULT_MAX,
    min: CONSTANTS.DEFAULT_MIN,
    id: CONSTANTS.DEFAULT_ID,
    format: CONSTANTS.DEFAULT_FORMAT,
    onFocus: () => {},
    onBlur: () => {},
  };

  state = {
    focusClass: CONSTANTS.BLUR_CLASS,
  };

  latestKey: number = -1;

  isOverLimit = (value: number) => {
    const { max = CONSTANTS.DEFAULT_MAX, min } = this.props;

    return (value > max || value < min);
  }

  stringToNum = (value: string) => Number(value);

  decimalCount = (value: string) => (value.split('.')[1] || []).length;

  exceedsPrecision = (value: string) => (this.decimalCount(value) > this.props.precision);

  valueIsValid = (value: string) => {
    const nextNumber = this.stringToNum(value);

    if (isNaN(nextNumber)) return false;
    if (this.isOverLimit(nextNumber)) return false;
    if (this.exceedsPrecision(value)) return false;

    return true;
  }

  handleInputOnChange = (numericValue: number | null, stringValue: string) =>
    this.valueIsValid(stringValue)
    && numericValue !== null
    && this.props.onChange(stringValue, numericValue)

  incrementValue = (value: string) => {
    const { step, precision } = this.props;
    return (this.stringToNum(value) + step).toFixed(precision);
  }

  decrementValue = (value: string) => {
    const { step, precision } = this.props;
    return (this.stringToNum(value) - step).toFixed(precision);
  }

  handleIncrementValue = () => {
    const { currentValue, onChange, id } = this.props;
    const incrementedValue = this.incrementValue(currentValue);
    const numericValue = this.stringToNum(incrementedValue);

    if (this.valueIsValid(incrementedValue)) onChange(incrementedValue, numericValue);
  }

  handleDecrementValue = () => {
    const { currentValue, onChange, id } = this.props;
    const decrementedValue = this.decrementValue(currentValue);
    const numericValue = this.stringToNum(decrementedValue);

    if (this.valueIsValid(decrementedValue)) onChange(decrementedValue, numericValue);
  }

  handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) =>
    this.latestKey = event.keyCode

  getHandleFormat = () => (value: number | null) =>
    this.props.format ? this.props.format(value, this.latestKey) : ''

  onFocus = () => {
    this.setState({
      focusClass: CONSTANTS.FOCUS_CLASS,
    });
  }

  onBlur = () => {
    this.setState({
      focusClass: CONSTANTS.BLUR_CLASS,
    });
  }

  render() {
    const {
      currentValue,
      className,
      preIcon,
      afterIcon,
      min,
      max,
      step,
      precision,
    } = this.props;
    const { focusClass } = this.state;

    return (
      <div className={`input-percentage ${className} ${focusClass}`}>
        <div className="number-field">
          <div className="pre-icon">
            <span>{preIcon}</span>
          </div>
          <div className="number-input">
            <NumericInput
              value={currentValue}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.handleInputOnChange}
              min={min}
              max={max}
              precision={precision}
              step={step}
              style={false}
              strict={true}
              format={this.getHandleFormat()}
              onKeyDown={this.handleOnKeyDown}
              onFocusCapture={this.props.onFocus}
              onBlurCapture={this.props.onBlur}
            />
          </div>
          <div className="after-icon">
            <span>{afterIcon}</span>
          </div>
        </div>
        <div className="modifier-icons">
          <div className="increment-icon">
            <button onClick={this.handleIncrementValue}>
              <Icons.ChevronUp
                width={CONSTANTS.ICON_WIDTH}
                height={CONSTANTS.ICON_HEIGHT}
                fill={CONSTANTS.ICON_COLOR}
              />
            </button>
          </div>
          <div className="decrement-icon">
            <button onClick={this.handleDecrementValue}>
              <Icons.ChevronDown
                width={CONSTANTS.ICON_WIDTH}
                height={CONSTANTS.ICON_HEIGHT}
                fill={CONSTANTS.ICON_COLOR}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
