import * as ACTIONS from '../constants/actions';
import { handleActions, Action } from 'redux-actions';

import ThynkadsResponse from '../../dtos/ThynkadsResponse';
import { ICategory } from '../category/CategoryInterfaces';
import { IContact } from '../contact/ContactInterfaces';
import ContactsInitialState from './ContactsInitialState';

export const setContactAttribute = (
  response: IContact[],
  action: Action<IContact[]>,
): any => {
  return action.payload;
};

const contactsReducer = handleActions<any, any>(
  {
    [ACTIONS.SET_CONTACTS]: setContactAttribute,
  },
  ContactsInitialState,
);

export default contactsReducer;
