import Axios from "axios";
import _ from "lodash";
import Endpoints from "./endpoints/index";
import Utils from "./utils";
import { REQUEST_TIMEOUT_MS } from "../../constants/setting.constants";
import apiMiddleware from "./middleware";

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`,
  timeout: REQUEST_TIMEOUT_MS,
  responseType: "json",
});

const createAPI = (sources: any) =>
  _.reduce(
    sources,
    (result: any, value: any, key: any) => {
      result[key] = (body: any) => {
        const request = {
          ...value,
          headers: {
            ...value.headers,
            Authorization: localStorage.getItem("token"),
          },
        };
        const obj = Utils.addURLParams(request.url, body);
        request.url = obj.uri;
        if (request.method === "get") {
          // THIS FIX QUERYSTRING TYPES ISSUE
          request.params = Object.entries(obj.data).reduce(
            (params, [k, v]) => ({
              ...params,
              [k]:
                typeof v === "string" || _.isObject(v) ? JSON.stringify(v) : v,
            }),
            {}
          );
        } else {
          request.data = obj.data;
        }
        return apiMiddleware(axios(request));
      };
      return result;
    },
    {}
  );

const api = createAPI(Endpoints);

export default api;
