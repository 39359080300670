import { IReportState } from './ReportInterfaces';

// Initial State
const REPORT_INITIAL_STATE: IReportState = Object.freeze({
  data: {
    externalReportUrl: undefined,
  },
});

export default REPORT_INITIAL_STATE;
