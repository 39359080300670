import React from 'react';
import { DayTypes } from '../../../enums/date-time/DayTypes';
import { CheckboxRow } from '../..';
import { TDaysActiveDictionary } from '../../../interfaces/date-time';

interface IDayCheckboxCardProps {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  holiday: boolean;

  daysDisabled: Partial<TDaysActiveDictionary>;

  onChange?: (willBeChecked: boolean, day: DayTypes) => void;
  onChangeAll?: (willBeChecked: boolean) => void;

  className: string;
}

class DayCheckboxCard extends React.Component<IDayCheckboxCardProps> {
  static defaultProps = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    holiday: false,  

    daysDisabled: {},

    className: '',
  };

  checkIfAllSelected = () => 
    this.props.monday &&
    this.props.tuesday &&
    this.props.wednesday &&
    this.props.thursday &&
    this.props.friday &&
    this.props.saturday &&
    this.props.sunday &&
    this.props.holiday

  shouldDisableCheckboxAll = () => {
    if (this.props.daysDisabled[DayTypes.Monday]) return true;
    if (this.props.daysDisabled[DayTypes.Tuesday]) return true;
    if (this.props.daysDisabled[DayTypes.Wednesday]) return true;
    if (this.props.daysDisabled[DayTypes.Thursday]) return true;
    if (this.props.daysDisabled[DayTypes.Friday]) return true;
    if (this.props.daysDisabled[DayTypes.Saturday]) return true;
    if (this.props.daysDisabled[DayTypes.Sunday]) return true;
    if (this.props.daysDisabled[DayTypes.Holiday]) return true;
    
    return false;
  }

  getHandleOnChange = (day: DayTypes) => (willBeChecked: boolean) => 
    this.props.onChange && this.props.onChange(willBeChecked, day)

  handleOnChangeAll = (willBeChecked: boolean) => this.props.onChangeAll && this.props.onChangeAll(willBeChecked);

  getCheckboxClassNames = (isChecked: boolean) => ([
    isChecked ? 'border-all--blue' : 'border-all',
    'd-inline-flex',
    'pdG08',
    'border-radius-3',
    'flex-expand',
    'max-width-190',
    'mgG05',
  ].join(' '))

  renderCheckboxAll = (disabled?: boolean) => this.props.onChangeAll && (
    <CheckboxRow
      onCheckChange={this.handleOnChangeAll}
      label="Todos"
      checkbox={{
        disabled,
        isChecked: this.checkIfAllSelected(),
      }}
      className={this.getCheckboxClassNames(this.checkIfAllSelected())}
      labelClassName="pdR10"
    />
  )

  renderCheckboxRow = (isChecked: boolean, day: DayTypes, label: string, disabled?: boolean) => {
    return (
      <CheckboxRow
        onCheckChange={this.getHandleOnChange(day)}
        label={label}
        checkbox={{
          isChecked,
          disabled,
        }}
        className={this.getCheckboxClassNames(isChecked)}
        labelClassName="pdR10"
      />
    );
  }

  render() {
    const { className, daysDisabled } = this.props;

    return (
      <div className={`d-flex flex-left flex-wrap ${className}`}>
        {this.renderCheckboxAll(this.shouldDisableCheckboxAll())}
        {this.renderCheckboxRow(this.props.monday, DayTypes.Monday, 'Lunes', daysDisabled[DayTypes.Monday])}
        {this.renderCheckboxRow(this.props.tuesday, DayTypes.Tuesday, 'Martes', daysDisabled[DayTypes.Tuesday])}
        {this.renderCheckboxRow(
          this.props.wednesday, 
          DayTypes.Wednesday, 
          'Miércoles', 
          daysDisabled[DayTypes.Wednesday],
        )}
        {this.renderCheckboxRow(this.props.thursday, DayTypes.Thursday, 'Jueves', daysDisabled[DayTypes.Thursday])}
        {this.renderCheckboxRow(this.props.friday, DayTypes.Friday, 'Viernes', daysDisabled[DayTypes.Friday])}
        {this.renderCheckboxRow(this.props.saturday, DayTypes.Saturday, 'Sábado', daysDisabled[DayTypes.Saturday])}
        {this.renderCheckboxRow(this.props.sunday, DayTypes.Sunday, 'Domingo', daysDisabled[DayTypes.Sunday])}
        {this.renderCheckboxRow(this.props.holiday, DayTypes.Holiday, 'Feriado', daysDisabled[DayTypes.Holiday])}
      </div>
    );
  }
}

export default DayCheckboxCard;
