import { handleActions, Action } from 'redux-actions';
import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IRepresentativeState, IRepresentativeStateData } from './RepresentativeInterface';
import REPRESENIVE_INITIAL_STATE from './RepresentativeInicialState';

const updateRepresentativeData = (
    advertiserRepresentativeState: IRepresentativeState,
    action: Action<Partial<IRepresentativeStateData>>,
  ): IRepresentativeState => {
    if (!action.payload) return { ...advertiserRepresentativeState };
  
    const newAdvertiserRepresentativeData: Partial<IRepresentativeStateData> = action.payload;
  
    return ({
      ...advertiserRepresentativeState,
      data: {
        ...advertiserRepresentativeState.data,
        ...extractDefinedProps(newAdvertiserRepresentativeData),
      },
    });
};

const resetRepresentativeState = (
    representativeState: IRepresentativeState,
    action: Action<null>,
  ): IRepresentativeState => ({
    ...REPRESENIVE_INITIAL_STATE,
  });

  const createAdvertiserSucceed = (representative: IRepresentativeState, action: Action<IRepresentativeState>): IRepresentativeState => {
    return {
      ...representative,
      errors: {
        rncCode: representative.errors.rncCode,
        createdSuccessful: true,
      },
    };
  };

const representativeReducer = handleActions<IRepresentativeState, any>(
    {
      [ACTIONS.UPDATE_REPRESENTATIVE_DATA]: updateRepresentativeData,
      [ACTIONS.RESET_REPRESENTATIVE_STATE]: resetRepresentativeState,
      [ACTIONS.CREATE_REPRESENTATIVE_SUCCEED]: createAdvertiserSucceed,
    },
    REPRESENIVE_INITIAL_STATE
);
  
export default representativeReducer;
