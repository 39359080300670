export const chains = {
  searchChains: {
    method: 'get',
    url: '/chains',
  },
  searchPublicChains: {
    method: 'get',
    url: '/public/chains',
  },
  searchCampaignAvailableChains: {
    method: 'get',
    url: '/chains/forNewCampaign',
  },
  searchReservationAvailableChains: {
    method: 'get',
    url: '/chains/forNewReservation',
  },
  searchReservationAvailableChainsAreas: {
    method: 'get',
    url: '/chains/forNewReservationAreas',
  },
  createChain: {
    method: 'post',
    url: '/chains',
  },
  editChain: {
    method: 'post',
    url: '/chains/:id',
  },
  editChainField: {
    method: 'patch',
    url: '/chains/:id/fields',
  },
  deleteChain: {
    method: 'delete',
    url: '/chains/:id/',
  },
  getChainById: {
    method: 'get',
    url: '/chains/:id',
  },
  getChainsBranchesSlotsUsage: {
    method: 'get',
    url: '/chains/branches/slotsUsage',
  },
};
