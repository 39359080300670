import { IRepresentativeState } from "./RepresentativeInterface";

export const REPRESENIVE_INITIAL_STATE: IRepresentativeState = {
    data: {
      id: 0,
      city: '',
      position: '',
      phoneNumber: '',
      name: '',
      lastName: '',
      email:'',
      identification:'',
      identificationType: 'ID'
    },
    errors: {
    },
    meta: {
      isEditingSingleStep: false,
    },
  };

export default REPRESENIVE_INITIAL_STATE
