
import { IBrandState } from './BrandInterfaces';

const BRAND_INITIAL_STATE: IBrandState = {
  data: {
    id: 0,
    name: '',
    type: 0,
    category: {
      id: 0,
      name: '',
    },
    advertiser: {
      id: 0,
      name: '',
    },
  },
  stats: {
    pendingApprovalCampaignCount: 0,
    publishedCampaignCount: 0,
  },
  errors: {
    
  },
  meta: {
    message: '',
  },
};

export default BRAND_INITIAL_STATE;
