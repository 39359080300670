export const advertisers = {
  searchAdvertisers: {
    method: "get",
    url: "/generalusers",
  },
  searchPublicAdvertisers: {
    method: "get",
    url: "/public/generalusers",
  },
  searchAdvertiserForStats: {
    method: "get",
    url: "/generalusers/stats",
  },
  createAdvertiser: {
    method: "post",
    url: "/generalusers",
  },
  updateRepresentative: {
    method: "post",
    url: "/updateRepresentative",
  },
  createPublicAdvertiser: {
    method: "post",
    url: "/public/generalusers",
  },
  getAdvertiserById: {
    method: "get",
    url: "/generalusers/:id",
  },
  editAdvertiser: {
    method: "post",
    url: "/generalusers/:id",
  },
  editPublicAdvertiser: {
    method: "post",
    url: "/public/generalusers/:id",
  },
  getAdvertiserByUserId: {
    method: "get",
    url: "/generalusers/getByUserId/:id",
  },
  checkAdvertiserSuspendable: {
    method: "get",
    url: "generalusers/:id/suspendable",
  },
  checkAdvertiserRemovable: {
    method: "get",
    url: "generalusers/:id/removable",
  },
  suspendAdvertiser: {
    method: "patch",
    url: "/generalusers/:id/suspend",
  },
  activateAdvertiser: {
    method: "patch",
    url: "/generalusers/:id/activate",
  },
  deleteAdvertiser: {
    method: "delete",
    url: "/generalusers/:id",
  },
  getAdvertiserBrandsSlotsUsage: {
    method: "get",
    url: "/generalusers/admins/:id/brands/slotsUsage",
  },
  getAdvertiserAdminEmailExist: {
    method: "post",
    url: "/public/emailexists",
  },
  getAdvertiserAdminIdExists: {
    method: "post",
    url: "/public/idexists",
  },
  resentRepresentativeEmails: {
    method: "post",
    url: "/resendemails",
  },
};
