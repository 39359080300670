import React, { Component } from 'react';
import Checkbox from '../checkbox';
import { ICheckboxRow } from '../checkbox-row/interfaces';
import CheckboxRow from '../checkbox-row';
import DottedSeparator from '../../dotted-separator';
import SolidSeparator from '../../solid-separator';
import { CheckChangeCallback } from '../checkbox/interfaces';

interface ICheckboxTableProps {
  title?: string;
  isReadonly?: boolean;
  hideCheckBox?: boolean;

  checkboxRows: ICheckboxRow[];
  onAllSelectedClick: CheckChangeCallback;
  onRowSelected: CheckChangeCallback;
}

export default class CheckboxTable extends Component<ICheckboxTableProps> {
  static defaultProps = {
    onAllSelectedClick: () => { },
    onRowSelected: () => { },
  };

  checkIfAllSelected = () => {
    for (const key in this.props.checkboxRows) {
      if (!this.props.checkboxRows[key].checkbox.isChecked) return false;
    }
    return true;
  }

  renderTableTitle = () => (
    <>
      <div className="table-header">
        <div className="w-100 d-flex">
          <div className="header-title">
            <span>{this.props.title}</span>
          </div>
          <div className="header-checkbox">
            <Checkbox
              id="all-checkbox"
              isChecked={this.checkIfAllSelected()}
              value={'all-selected'}
              onCheckChange={this.props.onAllSelectedClick}
              disabled={this.props.isReadonly}
            />
          </div>
        </div>
      </div>
      <SolidSeparator />
    </>
  )

  render() {
    const { checkboxRows, title, isReadonly, hideCheckBox } = this.props;

    return (
      <div className="checkbox-table">
        {title && this.props.checkboxRows.length > 0 && this.renderTableTitle()}
        <div className="table-content">
          <div className="list">
            {
              checkboxRows.map((checkboxRow, i) => {
                return (
                  <div key={i}>
                    {i > 0 && <DottedSeparator />}
                    <CheckboxRow
                      checkbox={checkboxRow.checkbox}
                      label={checkboxRow.label}
                      onCheckChange={this.props.onRowSelected}
                      className="pdG08"
                      isReadOnly={isReadonly}
                      hideCheckBox={hideCheckBox}
                    />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
