import React, { Component } from 'react';

import { RectangleButton, SolidSeparator, DottedSeparator } from '../../../../common/components';
import HeaderBar from '../../../../common/components/header-bar';
import Icons from '../../../../resources/assets/icons';

interface IChainGeneralInfoProps {
  rncCode: string | number;
  name: string;
  logo: string;
  accountantEmail: string;
  onClickEdit?: Function;

  className: string;
}

export default class ChainGeneralInfo extends Component<IChainGeneralInfoProps> {
  static defaultProps = {
    className: '',
  };

  handleOnClickEdit = () => this.props.onClickEdit && this.props.onClickEdit();

  renderRNC = () => (
    <>
      <div className="rnc-label first-column font-color-dark-gray">
        RNC
      </div>
      <div className="rnc-value second-column">
        {this.props.rncCode}
      </div>
    </>
  )

  renderName = () => (
    <>
      <div className="name-label first-column font-color-dark-gray">
        Nombre de la cadena
      </div>
      <div className="name-value second-column">
        {this.props.name}
      </div>
    </>
  )

  renderAccountantEmail = () => (
    <>
      <div className="name-label first-column font-color-dark-gray">
        Correo del contable
      </div>
      <div className="name-value second-column">
        {this.props.accountantEmail}
      </div>
    </>
  )
  renderLogo = () => (
    <>
      <div className="logo-label first-column font-color-dark-gray">
        Logotipo
      </div>
      <div className="logo-value second-column">
        <img src={this.props.logo}>
        </img>
      </div>
    </>
  )

  renderButtonContent = () => (
    <RectangleButton
      onClick={this.handleOnClickEdit}
      className="summary-edit"
    >
      <div className="edit-button">
        <div className="edit-icon">
          <Icons.Edit
            height="100%"
            fill="rgb(25,118,210)"
          />
        </div>
        <div className="button-content">
          MODIFICAR
        </div>
      </div>  
    </RectangleButton>
  )

  renderHeader = () => (
    <>
      <HeaderBar
        className="pdG20 w-100 full-height"
        leftContent={(
          <div className="header-value">
            Información general
          </div>            
        )}
        rightContent={this.props.onClickEdit && this.renderButtonContent()}
      />
    </>
  )

  render() {
    const { className } = this.props;
    // console.log("All data", this.props)
    return (
      <div className={`chain-general-info ${className}`}>
        <div className="general-header fs-20 info-row flex-between">
          {this.renderHeader()}
        </div>
        <SolidSeparator />
        <div className="pdG20">
          <div className="chain-rnc info-row flex-left">
            {this.renderRNC()}
          </div>
          <DottedSeparator className="mgB10 mgT10" />
          <div className="chain-name info-row flex-left">
            {this.renderName()}
          </div>
          <DottedSeparator className="mgB10 mgT10" />
          <div className="chain-name info-row flex-left">
            {this.renderAccountantEmail()}
          </div>
          <DottedSeparator className="mgB10 mgT10" />
          <div className="chain-logo info-row flex-left">
            {this.renderLogo()}
          </div>
        </div>
      </div>
    );
  }
}
