
const COLOR_CONSTANTS = {
  WHITE: 'rgb(255,255,255)',
  BLACK: 'rgb(0, 0, 0)',
  TRANSLUCENT_BLACK: 'rgba(0, 0, 0, 0.75)',
  DARKER_GRAY: 'rgb(100, 100, 100)',
  GRAY: 'rgba(0,0,0, 0.38)',
  BLUE: 'rgb(25,118,210)',
  RED: 'rgb(211,47,47)',
  GREEN: 'rgb(131,162,118)',
};

export default COLOR_CONSTANTS;
