import _ from "lodash";
import { handleActions, Action } from "redux-actions";

import * as ACTIONS from "../constants/actions";
import ThynkadsResponseInitialState from "../../dtos/ThynkadsResponseInitialState";
import CHAINS_INITIAL_STATE from "./ChainsInitialState";
import {
  IChainsActionUpdateBranchAreaSlotsPayload,
  TChainsState,
} from "./ChainsInterfaces";

const setChains = (chains: TChainsState, action: Action<TChainsState>): any => {
  const payload = action.payload! || ThynkadsResponseInitialState;
  return {
    ...payload,
  };
};

const SetChainBranchesAreas = (
  chains: TChainsState,
  action: Action<TChainsState>
): any => {
  const chainsAreas = action.payload ? action.payload.data : [];
  if (!chainsAreas) return;
  let chainsToUpdate = chains.data;

  chainsAreas.forEach((chainArea) => {
    const existingChain =
      chainsToUpdate &&
      chainsToUpdate.find((chain) => chain.id === chainArea.id);
    if (existingChain) {
      chainArea.branches.map((branchArea) => {
        const branchAdded = existingChain.branches.find(
          (branch) => branch.id === branchArea.id
        );
        if (!branchAdded) {
          existingChain.branches.push(branchArea);
        }else{
          branchAdded.areas = [...branchAdded.areas, ...branchArea.areas];
        }
      });
    } else {
      chainsToUpdate && chainsToUpdate.push(chainArea);
    }
  });

  return {
    ...chains,
    data: chainsToUpdate,
  };
};

const addChain = (chains: TChainsState, action: Action<TChainsState>): any => {
  const payload = action.payload;
  return {
    ...chains,
    data: [...(chains.data || []), payload],
  };
};

const editChainAttribute = (
  chains: TChainsState,
  action: Action<{ id: number; field: string; value: any }>
): any => {
  const { id, field, value } = action.payload || {
    id: null,
    field: "",
    value: null,
  };
  const chainIndex = chains.data
    ? chains.data.findIndex((chain) => chain.id === id)
    : null;
  const data = [...(chains.data ? chains.data : [])];

  if (chainIndex) {
    data[chainIndex] = {
      ...data[chainIndex],
      [field]: value,
    };
    return {
      ...chains,
      data,
    };
  }
};

const resetChainsState = (
  chains: TChainsState,
  action: Action<null>
): TChainsState => {
  return {
    ...CHAINS_INITIAL_STATE,
  };
};

const updateChainsBranchAreasSlots = (
  chains: TChainsState,
  action: Action<IChainsActionUpdateBranchAreaSlotsPayload[]>
): TChainsState => {
  const { payload } = action;

  if (!payload) return { ...chains };

  return {
    ...chains,
    data:
      chains.data &&
      chains.data.map((chainData) => {
        return {
          ...chainData,
          branches:
            chainData.branches &&
            chainData.branches.map((branchData) => {
              return {
                ...branchData,
                areas:
                  branchData.areas &&
                  branchData.areas.map((areaData) => {
                    const slotData = payload.find(
                      (area) => area.id === areaData.id
                    );
                    const { freeSlots = 0, freeExtraSlots = 0 } =
                      slotData || {};

                    return {
                      ...areaData,
                      freeSlots,
                      freeExtraSlots,
                    };
                  }),
              };
            }),
        };
      }),
  };
};

const resetChainsSlotsState = (
  chains: TChainsState,
  action: Action<null>
): TChainsState => {
  return {
    ...chains,
    data:
      chains.data &&
      chains.data.map((chainData) => {
        return {
          ...chainData,
          branches:
            chainData.branches &&
            chainData.branches.map((branchData) => {
              return {
                ...branchData,
                areas:
                  branchData.areas &&
                  branchData.areas.map((areaData) => {
                    return {
                      ...areaData,
                      freeSlots: undefined,
                      freeExtraSlots: undefined,
                    };
                  }),
              };
            }),
        };
      }),
  };
};

const chainsReducer = handleActions<TChainsState, any>(
  {
    [ACTIONS.SET_CHAINS]: setChains,
    [ACTIONS.ADD_CHAIN]: addChain,
    [ACTIONS.EDIT_CHAIN_ATTRIBUTE]: editChainAttribute,
    [ACTIONS.RESET_CHAINS_STATE]: resetChainsState,
    [ACTIONS.UPDATE_CHAINS_BRANCH_AREAS_SLOTS_AVAILABILITY]: updateChainsBranchAreasSlots,
    [ACTIONS.RESET_CHAINS_SLOTS_STATE]: resetChainsSlotsState,
    [ACTIONS.SET_BRANCH_AREAS]: SetChainBranchesAreas,
  },
  ThynkadsResponseInitialState
);

export default chainsReducer;
