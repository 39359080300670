import BaseService from './BaseService';
import api from './api';
import ISearch from '../interfaces/ISearch';
import ISearchUsersParams from '../payloads/users/ISearchUsersParams';
import { ICreateUserAdminPayload } from '../payloads/users/CreateUserAdminPayload';
import { ICreateUserAccountsManagerPayload } from '../payloads/users/CreateUserAccountsManagerPayload';
import { ICreateUserAdvertiserAdminPayload } from '../payloads/users/CreateUserAdvertiserAdminPayload';
import { ICreateUserChainAdminPayload } from '../payloads/users/CreateUserChainAdminPayload';
import { IUpdateUserPayload } from '../payloads/users/UpdateUserPayload';
import ThynkadsResponse from '../dtos/ThynkadsResponse';
import { SearchUserActivitiesDTO } from '../dtos/users/SearchUserActivityDTO';
import ISearchUsersActivitiesParams from '../payloads/users/ISearchUsersActivitiesParams';
import { IDeleteUserPayload } from '../payloads/users/IDeleteUserPayload';
import IActivateUserPayload from '../payloads/users/ActivateUserPayload';
import ISendDocumentPayload from '../payloads/users/SendDocumentPayload';

export default class UsersService extends BaseService {

  static search(filter: ISearch<ISearchUsersParams>) {
    return api.searchUsers(filter);
  }

  static getById(id: string) {
    return api.getUserById({ id });
  }

  static getUserActivity(id: string, filter: ISearch<ISearchUsersActivitiesParams>)
    : ThynkadsResponse<{ data: SearchUserActivitiesDTO }> {
    return api.getUserActivity({ id, ...filter });
  }

  static suspend(id: string) {
    return api.suspendUser({ id });
  }

  static createAdmin({
    firstName,
    lastName,
    email,
    phone,
  }: ICreateUserAdminPayload) {
    return api.createAdmin({
      firstName,
      lastName,
      email,
      phone,
    });
  }

  static createAccountsManager({
    firstName,
    lastName,
    email,
    phone,
  }: ICreateUserAccountsManagerPayload) {
    return api.createAccountsManager({
      firstName,
      lastName,
      email,
      phone,
    });
  }

  static createAdvertiserAdmin({
    firstName,
    lastName,
    email,
    phone,
    advertiserId,
  }: ICreateUserAdvertiserAdminPayload) {
    return api.createAdvertiserAdmin({
      firstName,
      lastName,
      email,
      phone,
      advertiserId,
    });
  }

  static createChainAdmin({
    firstName,
    lastName,
    email,
    phone,
    chainId,
    brands,
  }: ICreateUserChainAdminPayload) {
    return api.createChainAdmin({
      firstName,
      lastName,
      email,
      phone,
      chainId,
      brands,
    });
  }

  static update(payload: IUpdateUserPayload) {
    return api.updateUser(payload);
  }

  static delete(payload: IDeleteUserPayload) {
    return api.deleteUser(payload);
  }

  static activate(payload: IActivateUserPayload) {
    return api.activateUser(payload);
  }
  
  static sendDocument(payload: ISendDocumentPayload) {
    return api.sendDocument(payload);
  }

  static getChainAdminBrandsById(id: number){
    return api.getChainAdminBrandsById({id});
  }
}
