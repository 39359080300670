import React, { ReactElement } from 'react';
import { ITextInput } from '../text-input/TextInput';
import { TextInput } from '../';
import { RectangleButton } from '../..';

export interface ITextInputButton extends ITextInput {
  buttonIcon?: ReactElement<any>;
  buttonClassName?: string;
  onButtonClick: Function;
  isEnabled: boolean;
  alignRight: boolean;
}

export default class TextInputButton extends React.PureComponent<ITextInputButton> {

  static defaultProps = {
    alignRight: true,
  };

  renderButton = () => {
    return (
      <RectangleButton
          className={this.props.buttonClassName}
          onClick={this.props.onButtonClick}
          disabled={!this.props.isEnabled}
        >
          <div className="d-flex align-items-center">
            <div className="mgR05">
              {this.props.children}
            </div>
            {this.props.buttonIcon}

          </div>
        </RectangleButton>
    );
  }

  render() {
    return (
      <div className="d-flex w-100">
        {!this.props.alignRight && this.renderButton()}
        <TextInput {... this.props} />
        {this.props.alignRight && this.renderButton()}
      </div>
    );
  }
}
