import React, { Component } from 'react';

interface IDoubleBoxProps {
  header: string | JSX.Element;
  firstBox: JSX.Element;
  secondBox: JSX.Element;
  
  className: string;
  headerClassName: string;
  contentClassName: string;
  firstBoxClass: string;
  secondBoxClass: string;
}

export default class TableGeneric<T> extends Component<IDoubleBoxProps> {
  static defaultProps = {
    className: '',
    headerClassName: '',
    contentClassName: '',
    firstBoxClass: 'w-50',
    secondBoxClass: 'w-50',  
  };

  render() {
    const { 
      header,
      firstBox,
      secondBox,
      className,
      headerClassName,
      contentClassName, 
      firstBoxClass,
      secondBoxClass,
    } = this.props;

    return (
      <div className={`double-box ${className}`}>
        <div className={`box-header fs-18 ${headerClassName}`}>
          {header}
        </div>
        <div className={`box-content ${contentClassName}`}>
          <div className={`first-box ${firstBoxClass}`}>
            {firstBox}
          </div>
          <div className={`second-box ${secondBoxClass}`}>
            {secondBox}
          </div>
        </div>
      </div>
    );
  }
}
