export default {
  data: {
    id: '',
    email: '',
    token: '',
    name: '',
    lastName: '',
    roleId: 0,
    statusId:0,
    document: {
      data:[], type:''
    },
    metaData: {
      adminContact: {
        email: '',
        phone: '',
      },
    },
  },
  errors: {
    email: '',
    password: '',
    token: '',
  },
};
