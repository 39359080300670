import React, { ChangeEvent, CSSProperties } from "react";
import Autocomplete from "react-autocomplete";

import {
  ISearchInputOption,
  TSearchInputOnSelectChange,
  TSearchInputOnChange,
} from "./interfaces";

interface ISearchableInputSelectProps<T extends ISearchInputOption> {
  id: string;
  children: T[];
  inputValue: string;
  displayPluss?: string;
  placeholder?: string;

  onSelectChange?: TSearchInputOnSelectChange<T>;
  onInputChange?: TSearchInputOnChange;
  onBlurInput?: (event: React.FocusEvent<HTMLInputElement>) => void;

  inputClassName: string;
  inputContainerClassName: string;
}

export default class SearchableInputSelect<
  T extends ISearchInputOption
> extends React.Component<ISearchableInputSelectProps<T>> {
  static defaultProps = {
    id: "",
    inputClassName: "",
    inputContainerClassName: "",
  };

  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onInputChange && this.props.onInputChange(e.target.value);
  };

  handleSelectChange = (value: string, item: T) => {
    this.props.onSelectChange && this.props.onSelectChange(item);
  };

  handleSearch = (item: T, value: string) => {
    return item.searchValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
  };

  handleGetOptionValue = (item: T) => {
    return item.label;
  };

  handleRenderOption = (item: T, isHighlighted: boolean) => {
    return (
      <div
        id={item.id}
        key={item.id}
        className={`
          ${isHighlighted ? "bg-highlight-blue" : ""}
          w-100
          pdG10
        `}>
        {item.label}
      </div>
    );
  };

  handleRenderMenu = (items: T[], value: string, styles: CSSProperties) => {
    return (
      <div
        className="border-all bg-white searchable-input__select"
        style={{
          ...styles,
          top: "auto",
          left: "auto",
          minWidth: "100%",
        }}
        children={items}
      />
    );
  };

  render() {
    return (
      <div>
        <Autocomplete
          items={[...this.props.children]}
          value={this.props.inputValue}
          autoHighlight={true}
          selectOnBlur={false}
          onChange={this.handleInputChange}
          onSelect={this.handleSelectChange}
          shouldItemRender={this.handleSearch}
          getItemValue={this.handleGetOptionValue}
          renderItem={this.handleRenderOption}
          renderMenu={this.handleRenderMenu}
          inputProps={{
            id: this.props.id,
            className: this.props.inputClassName,
            placeholder: this.props.placeholder,
            onBlur: this.props.onBlurInput,
          }}
          wrapperProps={{
            className: `position-relative ${
              this.props.inputContainerClassName
            }`,
          }}
        />
      </div>
    );
  }
}
