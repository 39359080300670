import * as ACTIONS from '../constants/actions';

import { handleActions, Action } from 'redux-actions';
import { IMonitorState, IMonitorStateErrors } from './MonitorInterfaces';
import MONITOR_INITIAL_STATE from './MonitorInitialState';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IMonitorsState } from '../monitors/MonitorsInterfaces';


const updateMonitorErrors = (monitorState: IMonitorState, action: Action<Partial<IMonitorStateErrors>>)
: IMonitorState => {
  if (!action.payload) return { ...monitorState };

  const newMonitorErrors: Partial<IMonitorStateErrors> = action.payload;

  return {
    ...monitorState,
    errors: {
      ...monitorState.errors,
      ...extractDefinedProps(newMonitorErrors),
    },
  };  
};

const monitorReducer = handleActions<IMonitorState, any>(
  {
    [ACTIONS.UPDATE_MONITOR_ERRORS]: updateMonitorErrors,
  },
  MONITOR_INITIAL_STATE,
);


export default monitorReducer;
