export const notifications = {
  searchNotifications: {
    method: 'get',
    url: '/notifications',
  },
  readNotification: {
    method: 'patch',
    url: '/notifications/:id/read/:userId',
  },
  notificationDetails: {
    method: 'get',
    url: '/notifications/:id/details',
  },
  pendingNotification: {
    method: 'get',
    url: '/notifications/:id/pending',
  },
};
