import React, { ComponentType } from 'react';
import MaskedInput from 'react-text-mask';
import icons from '../../../../resources/assets/icons';
import { ValidationStatus } from '../../../enums/validation-status/ValidationStatus';
import COLOR_CONSTANTS from '../../../utilities/colors/constants';
import DelayedFunction from '../../../utilities/timeouts/DelayedFunction';
import StringSanitizer from '../../../utilities/validation/TextSanitizer';
import { validateDrPhone } from '../../../utilities/validation/TextValidator';

interface IPhoneInputWithLabelProps {
  id: string;
  label: string;
  value: string;
  placeholder: string;

  labelClassName: string;
  inputClassName: string;

  focusedLabelClassName: string;
  blurredLabelClassName: string;

  className: string;
  disabled?:boolean;
  onChange?: (phone: string) => void;
  validation?: boolean;
}

interface IPhoneInputWithLabelState {
  isFocused: boolean;
  renderValidationIcon: boolean;
}

export default class PhoneInputWithLabel
 extends React.Component<IPhoneInputWithLabelProps, IPhoneInputWithLabelState> 
{
  static defaultProps = {
    id: '',
    label: '',
    placeholder: '',
    labelClassName: '',
    inputClassName: '',  
    className: '',
    focusedLabelClassName: 'font-color-blue',
    blurredLabelClassName: 'font-color-dark-gray', 
    validation: false, 
  };

  public readonly state: Readonly<IPhoneInputWithLabelState> = {
    isFocused: false,
    renderValidationIcon: false,
  };

  handleOnFocus = () => this.setState({
    isFocused: true,
  })
  handleOnBlur = () => this.setState({
    isFocused: false,
  })
  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.delayedValidation.execute();
    const val = event.target.value;
    this.props.onChange && this.props.onChange(val);
  }

  shouldShowPlaceholder = () => !this.state.isFocused && !this.props.value;
  shouldShowLabel = () => this.state.isFocused || this.props.value;

  getPlaceholder = () => this.shouldShowPlaceholder() ? this.props.placeholder : '';
  getLabelText = () => this.shouldShowLabel() ? this.props.label : ' ';
  getLabelClassName = () => this.state.isFocused ? this.props.focusedLabelClassName : this.props.blurredLabelClassName;

  getLabelClassNames = () => ([
    this.getLabelClassName(),
    this.props.labelClassName,
    'mgB0',
  ].join(' '))

  getInputClassNames = () => ([
    this.props.inputClassName,
    'form-control',
    'mgB0',
    'pdG0',
    'font-color-black',
  ].join(' '))

  renderLabel = () => (
    <label className={this.getLabelClassNames()}>
      {this.getLabelText()}
    </label>
  )

  renderValidationIcon = () => {
    if(!this.state.renderValidationIcon)return null;
    const phoneToTest = StringSanitizer.getOnlyNumber(this.props.value);
    let validationIconStatus = phoneToTest.length == 0 ? ValidationStatus.Empty : ValidationStatus.Incorrect;
    if(validateDrPhone(this.props.value)){
      validationIconStatus = ValidationStatus.Correct;
    }
    switch (validationIconStatus) {
      case ValidationStatus.Correct:
        return (
          <icons.CircleCheck
            fill={COLOR_CONSTANTS.GREEN}
          />
        );
      case ValidationStatus.Incorrect:
        return (
          <icons.Alert
            fill={COLOR_CONSTANTS.RED}
          />
        );
      default:
        return (
          <icons.Alert
            visibility="hidden"
          />
        );
    }
  }
  
  delayedValidation = new DelayedFunction(3000, () => this.setState({renderValidationIcon: true})); 

  render() {
    const { className } = this.props;

    return (
      <div className={`${className}`}>
        <div className="form-input form-input-lined">
          {this.renderLabel()}
          {this.props.validation && this.renderValidationIcon()}
          <MaskedInput
            id={this.props.id}
            disabled={this.props.disabled}
            type="text"
            value={this.props.value}
            placeholder={this.getPlaceholder()}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}        
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
            className={this.getInputClassNames()}
          />
        </div>
      </div>
    );
  }
}
