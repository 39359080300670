export const reports = {
  getAdminReportsUrl: {
    method: 'get',
    url: '/reports/admins/:topic',
  },
  getAdvertiserReportsUrl: {
    method: 'get',
    url: '/reports/advertisers/:id/:topic',
  },
  getChainReportsUrl: {
    method: 'get',
    url: 'reports/chains/:id/:topic',
  },
};
