export const players = {
  searchPlayers: {
    method: 'get',
    url: '/players',
  },
  createPlayer: {
    method: 'post',
    url: '/players',
  },
  updatePlayer: {
    method: 'post',
    url: '/players/:id',
  },
  deletePlayer: {
    method: 'delete',
    url: '/players/:id',
  },  
  suspendPlayer: {
    method: 'patch',
    url: '/players/:id/suspend',
  },
  activatePlayer: {
    method: 'patch',
    url: '/players/:id/activate',
  },
};
