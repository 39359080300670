import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import {
  IBranchState,
  IBranchStateData,
  IBranchStateStats,
  IBranchStateErrors,
} from './BranchInterfaces';
import BRANCH_INITIAL_STATE from './BranchInitialState';

const updateBranchData = (branchState: IBranchState, action: Action<Partial<IBranchStateData>>): IBranchState => {
  if (!action.payload) return { ...branchState };

  const newBranchData: Partial<IBranchStateData> = action.payload;

  const newData = { ...extractDefinedProps(newBranchData) };

  return ({
    ...branchState,
    data: {
      ...branchState.data,
      ...newData,
    },
  });
};

const updateBranchStats = (branchState: IBranchState, action: Action<Partial<IBranchStateStats>>): IBranchState => {
  if (!action.payload) return { ...branchState };

  const newBranchStats: Partial<IBranchStateStats> = action.payload;

  return {
    ...branchState,
    stats: {
      ...branchState.stats,
      ...extractDefinedProps(newBranchStats),
    },
  };
};

const updateBranchErrors = (branchState: IBranchState, action: Action<Partial<IBranchStateErrors>>): IBranchState => {
  if (!action.payload) return { ...branchState };

  const newBranchErrors: Partial<IBranchStateErrors> = action.payload;

  return {
    ...branchState,
    errors: {
      ...branchState.errors,
      ...extractDefinedProps(newBranchErrors),
    },
  };
};

const resetBranchState = (branchState: IBranchState, action: Action<null>): IBranchState => ({
  ...BRANCH_INITIAL_STATE,
});

const craeteBranchFailed = (branchState: IBranchState, action: Action<any>): IBranchState => {
  const errors = action.payload;
  return {
    ...branchState,
    errors: {
      create: errors.msg,
      name: '',
      stats: '',
      update:'',
    },
  };
};

const getBranchByIdSucceed = (branchState: IBranchState, action: Action<IBranchStateData>): IBranchState => {
  const data = action.payload || BRANCH_INITIAL_STATE.data;
  return {
    ...branchState,
    data: {
      ...data,
    },
    meta: {
      ...branchState.meta,
      loading: false,
    },
  };
};

const getBranchByIdFailed = (branchState: IBranchState, action: Action<string>): IBranchState => {
  const error = action.payload || '';
  return {
    ...branchState,
    errors: {
      name: error,
      create: '',
      stats: '',
      update:'',
    },
  };
};

const updateBranchDataFailed = (branchState: IBranchState, action: Action<{msg: string}>): IBranchState => {
  const msg = action.payload ? action.payload.msg : '';
  return {
    ...branchState,
    errors:{
      name: '',
      create: '',
      stats: '',
      update: msg,
    },
  };
};

const branchReducer = handleActions<IBranchState, any>(
  {
    [ACTIONS.UPDATE_BRANCH_DATA]: updateBranchData,
    [ACTIONS.UPDATE_BRANCH_STATS]: updateBranchStats,
    [ACTIONS.UPDATE_BRANCH_ERRORS]: updateBranchErrors,
    [ACTIONS.RESET_BRANCH_STATE]: resetBranchState,
    [ACTIONS.CREATE_BRANCH_SUCCESS]: resetBranchState,
    [ACTIONS.CREATE_BRANCH_FAILED]: craeteBranchFailed,
    [ACTIONS.GET_BRANCH_BY_ID_SUCCESS]: getBranchByIdSucceed,
    [ACTIONS.GET_BRANCH_BY_ID_FAILED]: getBranchByIdFailed,
    [ACTIONS.UPDATE_BRANCH_DATA_SUCCESS]: resetBranchState,
    [ACTIONS.UPDATE_BRANCH_DATA_FAILED]: updateBranchDataFailed,
  },
  BRANCH_INITIAL_STATE,
);

export default branchReducer;
