import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';

const initialState = {
    array: []
  };
  
  const dataPreservation = (state = initialState, action: Action<any> ) => {
    switch (action.type) {
      case ACTIONS.SET_DATA_PRE_RESERVATION:
        return {
          ...state,
          array: action.payload,
        };
      default:
        return state;
    }
  };

  const dataPreservations = handleActions<any, any>(
    {
      [ACTIONS.SET_DATA_PRE_RESERVATION]: dataPreservation,         
    },
    initialState
  );
  

  export default dataPreservations;