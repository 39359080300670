export const users = {
  searchUsers: {
    method: 'get',
    url: '/users',
  },
  getUserById: {
    method: 'get',
    url: '/users/:id',
  },
  getUserActivity: {
    method: 'get',
    url: '/users/:id/activity',
  },
  suspendUser: {
    method: 'patch',
    url: '/users/:id/suspend',
  },
  createAdmin: {
    method: 'post',
    url: '/users/admin',
  },
  createAccountsManager: {
    method: 'post',
    url: '/users/accountsManager',
  },
  createAdvertiserAdmin: {
    method: 'post',
    url: '/users/advertiserAdmin',
  },
  createChainAdmin: {
    method: 'post',
    url: '/users/chainAdmin',
  },
  updateUser: {
    method: 'post',
    url: '/users/:id',
  },
  deleteUser: {
    method: 'delete',
    url: '/users/:id',
  },
  activateUser: {
    method: 'patch',
    url: '/users/:id/activate', 
  },
  sendDocument: {
    method: 'post',
    url: '/public/sendDocument', 
  },
  getChainAdminBrandsById:{
    method: 'get',
    url: '/users/:id/chainadminbrands', 
  }
};
