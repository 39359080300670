
import { 
  IAdvertisersState, 
  IAdvertisersStateDataItem, 
} from './AdvertisersInterfaces';
import moment from 'moment';

// Properties defaults
export const DEFAULT_ADVERTISERS_STATE_DATA_ITEM: IAdvertisersStateDataItem = {
  id: 0,
  name: '',
  createdAt: moment(),
  stats: {
    chainsWithCampaigns: 0,
    usedBrands: 0, 
    campaignsInProgressAmount: 0, 
    campaignsPendingApprovalAmount: 0, 
    usersAmount: 0, 
  },
};

// Initial State
const ADVERTISERS_INITIAL_STATE: IAdvertisersState = {
  data: [],
  errors: {
    search: '',
    delete: '',
  },
  meta: {
    message: '',
  },
};

export default ADVERTISERS_INITIAL_STATE;
