import { IUsersState, IUsersStateDataItem } from './UsersInterfaces';
import moment  from 'moment';
// Properties defaults
export const DEFAULT_USERS_STATE_DATA_ITEM: IUsersStateDataItem = {
  id: '',
  name: '',
  phone: '',
  email: '',
  roleId: 0,
  createdAt: moment(),
  statusId: 0,
};

// Initial State
const USERS_INITIAL_STATE: IUsersState = {
  data: [],
  errors: {
    search: '',
    suspend: '',
    deleted: '',
  },
  meta: {
    message: '',
  },
};

export default USERS_INITIAL_STATE;
