import { lazy } from 'react';

const ScreenList = lazy(() => import('./screen-list'));;
const CreateScreen = lazy(() => import('./create-screen'));;
const ChainScreenList = lazy(() => import('./chain-screen-list'));;

export default {
  ScreenList,
  CreateScreen,
  ChainScreenList,
};
