import { IScreensState, IScreensStateDataItem } from './ScreensInterfaces';
import { PlayerStatusTypes } from '../../enums/players/PlayerStatusTypes';
import { MonitorStatusTypes } from '../../enums/monitors/MonitorStatusTypes';

// Properties defaults
export const DEFAULT_SCREENS_STATE_DATA_ITEM: IScreensStateDataItem = {
  id: 0,
  name: '',
  active: true,

  monitor: {
    id: 0,
    serialCode: '',
    manufacturer: '',
    model: '',
    size: 0,
    active: false,
    status: MonitorStatusTypes.Ok,
  },

  player: {
    id: 0,
    serialCode: '',
    manufacturer: '',
    model: '',
    status: PlayerStatusTypes.Ok,
    active: false,
  },

  area: {
    name: '',
  },
  
  branch: {
    name: '',
  },

  chain: {
    name: '',
  },
};

// Initial State
const SCREENS_INITIAL_STATE: IScreensState = {
  data: [],
  errors: {
    search: '',
    delete: '',
  },
  meta: {
    message: '',
  },
};

export default SCREENS_INITIAL_STATE;
