
import * as ACTIONS from '../constants/actions';
import { handleActions, Action } from 'redux-actions';
import SessionInitialState from './ThirdPartyInitialState';
import { IThirdParty, IRncPayload, IRncResponseContentPayload } from './ThirdPartyInterfaces';


const rncSucceed = (thirdParty: IThirdParty, action: Action<IRncResponseContentPayload>): IThirdParty => {
  return {
    ...thirdParty,
    rncCode: {
      ...thirdParty.rncCode,
      data: {
        ...thirdParty.rncCode.data,
      },
    },
  };
};

const rncFail = (thirdParty: IThirdParty, action: Action<IRncPayload>): IThirdParty => {
  return {
    ...thirdParty,
    rncCode: {
      data: { ...thirdParty.rncCode.data },
      errors: {
        ...thirdParty.rncCode.errors,
        rncCode: action.payload!.rncCode,
      },
    },
  };
};

const setRNCDataAttribute = (thirdParty: IThirdParty, action: Action<{ key: string, value: string }>): IThirdParty => {
  const key = action.payload ? action.payload.key : '';
  const value = action.payload ? action.payload.value : '';
  return {
    ...thirdParty,
    rncCode: {
      data: {
        ...thirdParty.rncCode.data,
        [key]: value,
      },
      errors: {
        ...thirdParty.rncCode.errors,
      },
    },
  };
};
const setRNCError = (thirdParty: IThirdParty, action: Action<{ key: string, value: string }>): IThirdParty => {
  const key = action.payload ? action.payload.key : '';
  const value = action.payload ? action.payload.value : '';
  return {
    ...thirdParty,
    rncCode: {
      data: {
        ...thirdParty.rncCode.data,
      },
      errors: {
        ...thirdParty.rncCode.errors,
        [key]: value,
      },
    },
  };
};


const clearRncError = (thirdParty: IThirdParty, action: Action<{ key: string }>): IThirdParty => {
  const key = action.payload ? action.payload.key : '';
  return {
    ...thirdParty,
    rncCode: {
      data: { ...thirdParty.rncCode.data },
      errors: {
        ...thirdParty.rncCode.errors,
        [key]: '',
      },
    },
  };
};

const sessionReducer = handleActions<IThirdParty, any>(
  {
    [ACTIONS.RNC_SUCCEED]: rncSucceed,
    [ACTIONS.RNC_FAIL]: rncFail,
    [ACTIONS.CLEAR_RNC_ERROR]: clearRncError,
    [ACTIONS.SET_RNC_DATA_ATTRIBUTE]: setRNCDataAttribute,
    [ACTIONS.SET_RNC_ERROR]: setRNCError,
  },
  SessionInitialState,
);

export default sessionReducer;
