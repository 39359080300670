export default {
  rncCode: {
    data: {
      rncCode: '',
      name: '',
    },
    errors: {
      rncCode: '',
      name: '',
    },
  },
};
