
import AuthService from '../../services/AuthService';
import * as ACTIONS from '../constants/actions';
import { IRnc, IRncPayload } from './ThirdPartyInterfaces';
import { RncService } from '../../services/third-party';

export const validateRnc = (payload: IRncPayload) => async (dispatch: Function, getState: Function) => {

  const store = getState().session || {};

  if (store.isFetching) { return; }

  const key = 'rncCode';
  const error = 'Rnc no es valido';

  try {
    const response = await RncService.getBusinessInfo(payload.rncCode);
    if (!response || !response.data) {
      dispatch({ type: ACTIONS.SET_RNC_ERROR, payload: { [key]:error } });
    } else {
      dispatch({
        type: ACTIONS.SET_CHAIN_DATA_ATTRIBUTE,
        payload: {
          key: 'name',
          value: response.data.content[0]!.name,
        },
      });
    }

  } catch (error) {
    dispatch({ type: ACTIONS.SET_RNC_ERROR, payload: { [key]:error } });
  }
};

export const setRNCDataAttribute = (key: string, value: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_RNC_DATA_ATTRIBUTE, payload: { key, value } });
};

export const setRNCError = (key: string, value: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_RNC_ERROR, payload: { key, value } });
};

export default {
  validateRnc,
  setRNCDataAttribute,
};
