import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IPlayersState, IPlayersStateDataItem, IPlayersStateErrors, IPlayersStateFilters } from './PlayersInterfaces';
import PLAYERS_INITIAL_STATE from './PlayersInitialState';

const setPlayersData = (playersState: IPlayersState, action: Action<IPlayersStateDataItem[]>): IPlayersState => {
  if (!action.payload) return { ...playersState };

  const newPlayersData: IPlayersStateDataItem[] = action.payload;

  return ({
    ...playersState,
    data: [
      ...newPlayersData,
    ],
  });  
};

const updatePlayersDataById = (
  playersState: IPlayersState, 
  action: Action<Partial<IPlayersStateDataItem>>,
): IPlayersState => {
  if (!action.payload || !action.payload.id) return { ...playersState };

  const newPlayer = action.payload;

  return {
    ...playersState,
    data: playersState.data.map((player) => {
      return player.id !== newPlayer.id ? { ...player } : {
        ...player,
        ...extractDefinedProps(newPlayer),
      };
    }),
  };  
};

const deletePlayersDataById = (
  playersState: IPlayersState, 
  action: Action<number>,
): IPlayersState => {
  if (!action.payload) return { ...playersState };

  const playerId = action.payload;

  return {
    ...playersState,
    data: playersState.data.filter((player) => {
      return player.id !== playerId;
    }),
  };  
};

const removeAssociatedScreenFromPlayers = (
  playersState: IPlayersState, 
  action: Action<number>,
): IPlayersState => {
  if (!action.payload) return { ...playersState };

  const screenId = action.payload;

  return {
    ...playersState,
    data: playersState.data.map((player) => {
      if (player.screen && player.screen.id !== screenId) return { ...player };

      return {
        ...player,
        screen: undefined,
      };
    }),
  };  
};

const updatePlayersErrors = (
  playersState: IPlayersState, 
  action: Action<Partial<IPlayersStateErrors>>,
): IPlayersState => {

  if (!action.payload) return { ...playersState };

  const newPlayersErrors: Partial<IPlayersStateErrors> = action.payload;

  return {
    ...playersState,
    errors: {
      ...playersState.errors,
      ...extractDefinedProps(newPlayersErrors),
    },
  };
    
};

const updatePlayersFilters = (
  playersState: IPlayersState, 
  action: Action<Partial<IPlayersStateFilters>>,
): IPlayersState => {

  if (!action.payload) return { ...playersState };

  const newPlayersFilters: Partial<IPlayersStateFilters> = action.payload;

  return {
    ...playersState,
    filters: {
      ...playersState.filters,
      ...extractDefinedProps(newPlayersFilters),
    },
  };
    
};

const resetPlayersState = (playersState: IPlayersState, action: Action<null>): IPlayersState => ({
  ...PLAYERS_INITIAL_STATE,
});

const suspendPlayerById = (playersState: IPlayersState, action: Action<number>): IPlayersState => {
  if (!action.payload) return { ...playersState };

  const playerId = action.payload;

  return {
    ...playersState,
    data: [
      ...playersState.data.map((player) => {
        return {
          ...player,
          active: player.id === playerId ? false : player.active,
        };
      }),
    ],
  };
};

const suspendPlayerScreenByScreenId = (
  state: IPlayersState,
  action: Action<Partial<number>>,
): IPlayersState => {
  if (!action.payload) return { ...state };

  const screenId = action.payload;

  return {
    ...state,
    data: [
      ...state.data.map((player) => {
        return {
          ...player,
          screen: player.screen && player.screen.id === screenId ? {
            ...player.screen,
            active: false,
          } : player.screen,
        };
      }),
    ],
  };
};


const activatePlayerById = (playersState: IPlayersState, action: Action<number>): IPlayersState => {
  if (!action.payload) return { ...playersState };

  const playerId = action.payload;

  return {
    ...playersState,
    data: [
      ...playersState.data.map((player) => {
        return {
          ...player,
          active: player.id === playerId ? true : player.active,
        };
      }),
    ],
  };
};

const activatePlayerScreenByScreenId = (
  state: IPlayersState,
  action: Action<Partial<number>>,
): IPlayersState => {
  if (!action.payload) return { ...state };

  const screenId = action.payload;

  return {
    ...state,
    data: [
      ...state.data.map((player) => {
        return {
          ...player,
          screen: player.screen && player.screen.id === screenId ? {
            ...player.screen,
            active: true,
          } : player.screen,
        };
      }),
    ],
  };
};


const playersReducer = handleActions<IPlayersState, any>(
  {
    [ACTIONS.SET_PLAYERS_DATA]: setPlayersData,
    [ACTIONS.UPDATE_PLAYERS_ERRORS]: updatePlayersErrors,
    [ACTIONS.RESET_PLAYERS_STATE]: resetPlayersState,
    [ACTIONS.UPDATE_PLAYERS_DATA_BY_ID]: updatePlayersDataById,
    [ACTIONS.DELETE_PLAYERS_DATA_BY_ID]: deletePlayersDataById,
    [ACTIONS.UPDATE_PLAYERS_FILTERS]: updatePlayersFilters,
    [ACTIONS.REMOVE_ASSOCIATED_SCREEN_FROM_PLAYERS]: removeAssociatedScreenFromPlayers,
    [ACTIONS.SUSPEND_PLAYER_BY_ID]: suspendPlayerById,
    [ACTIONS.SUSPEND_SCREEN_BY_ID]: suspendPlayerScreenByScreenId,
    [ACTIONS.ACTIVATE_PLAYER_BY_ID]: activatePlayerById,
    [ACTIONS.ACTIVATE_SCREEN_BY_ID]: activatePlayerScreenByScreenId,
  },
  PLAYERS_INITIAL_STATE,
);

export default playersReducer;
