import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import BRAND_INITIAL_STATE from './BrandInitialState';
import {
  IBrandState,
  IBrandStateData,
  IBrandStateCategory,
  IBrandStateStats,
  IBrandStateMeta,
  IBrandStateErrors,
} from './BrandInterfaces';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';


const setBrand = (brandState: IBrandState, action: Action<IBrandState>): IBrandState => ({
  ...brandState,
  ...action.payload || BRAND_INITIAL_STATE,
});

const updateBrandData = (brandState: IBrandState, action: Action<Partial<IBrandStateData>>): IBrandState => {
  if (!action.payload) return { ...brandState };

  const newBrandData: Partial<IBrandStateData> = action.payload;

  return {
    ...brandState,
    data: {
      ...brandState.data,
      ...extractDefinedProps(newBrandData),
    },
  };
};

const setCategoryToBrand = (brandState: IBrandState, action: Action<IBrandStateCategory>): IBrandState => {
  if (!action.payload) return { ...brandState };

  return {
    ...brandState,
    data: {
      ...brandState.data,
      category: action.payload,
    },
  };
};

const setTypeToBrand = (brandState: IBrandState, action: Action<{id: number}>): IBrandState => {
  if (!action.payload) return { ...brandState };

  return {
    ...brandState,
    data: {
      ...brandState.data,
      type: action.payload.id,
    },
  };
};

const updateBrandStats = (brandState: IBrandState, action: Action<Partial<IBrandStateStats>>): IBrandState => {
  if (!action.payload) return { ...brandState };

  const newBrandStats: Partial<IBrandStateStats> = action.payload;
  
  return {
    ...brandState,
    stats: {
      ...brandState.stats,
      ...extractDefinedProps(newBrandStats),
    },
  };
};

const updateBrandErrors = (brandState: IBrandState, action: Action<Partial<IBrandStateErrors>>): IBrandState => {
  if (!action.payload) return { ...brandState };

  const newBrandErrors: Partial<IBrandStateErrors> = action.payload;

  return {
    ...brandState,
    errors: {
      ...brandState.errors,
      ...extractDefinedProps(newBrandErrors),
    },
  };
};

const updateBrandMeta = (brandState: IBrandState, action: Action<Partial<IBrandStateMeta>>): IBrandState => {
  if (!action.payload) return { ...brandState };

  const newBrandMeta: Partial<IBrandStateMeta> = action.payload;

  return {
    ...brandState,
    meta: {
      ...brandState.meta,
      ...extractDefinedProps(newBrandMeta),
    },
  };
};

const resetBrandState = (brandState: IBrandState, action: Action<null>): IBrandState => ({
  ...BRAND_INITIAL_STATE,
});

const resetBrandErrorsState = (brandState: IBrandState, action: Action<{ key: string }>): IBrandState => {
  if (action.payload && action.payload.key) {
    return {
      ...brandState,
      errors: {
        ...brandState.errors,
        [action.payload.key]: '',
      },
    };
  }
  return {

    ...brandState,
    errors: {
      ...BRAND_INITIAL_STATE.errors,
    },
  };
};


const updateBrandErrorsCategoryEditable = (brandState: IBrandState, action: Action<boolean>): IBrandState => {
  if (action.payload === undefined) return { ...brandState };

  const isEditable = action.payload;

  return {
    ...brandState,
    errors: {
      ...brandState.errors,
      category: {
        ...brandState.errors.category,
        editable: isEditable,
      },
    },
  };
};

const brandReducer = handleActions<IBrandState, any>(
  {
    [ACTIONS.UPDATE_BRAND_DATA]: updateBrandData,
    [ACTIONS.RESET_BRAND_STATE]: resetBrandState,
    [ACTIONS.SET_CATEGORY_TO_BRAND]: setCategoryToBrand,
    [ACTIONS.SET_TYPE_TO_BRAND]: setTypeToBrand,
    [ACTIONS.UPDATE_BRAND_STATS]: updateBrandStats,
    [ACTIONS.UPDATE_BRAND_ERRORS]: updateBrandErrors,
    [ACTIONS.UPDATE_BRAND_META]: updateBrandMeta,
    [ACTIONS.GET_BRAND_BY_ID_SUCCEED]: setBrand,
    [ACTIONS.GET_BRAND_BY_ID_FAIL]: resetBrandState,
    [ACTIONS.RESET_BRAND_ERRORS_STATE]: resetBrandErrorsState,
    [ACTIONS.UPDATE_BRAND_ERRORS_CATEGORY_EDITABLE]: updateBrandErrorsCategoryEditable,
  },
  BRAND_INITIAL_STATE,
);

export default brandReducer;
