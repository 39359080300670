export const brands = {
  suspendBrand: {
    method: 'patch',
    url: '/brands/:id/suspend',
  },
  activateBrand: {
    method: 'patch',
    url: '/brands/:id/activate',
  },
  getBrandById: {
    method: 'get',
    url: '/brands/:id',
  },
  searchBrands: {
    method: 'get',
    url: '/brands',
  },
  searchReservedBrands: {
    method: 'get',
    url: '/brands/reserved',
  },
  searchPublicBrands: {
    method: 'get',
    url: '/public/brands',
  },
  searchPublicReservedBrands: {
    method: 'get',
    url: '/public/brands/reserved',
  },
  checkBrandExists: {
    method: 'get',
    url: '/brands/exists',
  },
  createBrand: {
    method: 'post',
    url: '/brands',
  },
  updateBrand: {
    method: 'put',
    url: '/brands/:id',
  },
  deleteBrand: {
    method: 'delete',
    url: '/brands/:id',
  },
};
