import React from 'react';
import MaskedInput from 'react-text-mask';
import { ITextInput } from '../text-input/TextInput';

export interface IMaskedTextInput extends ITextInput {
  showMask: boolean;
  mask: (string | RegExp)[];
}

export default class MaskedTextInput extends React.PureComponent<IMaskedTextInput> {

  static defaultProps: IMaskedTextInput = {
    id: '',
    disabled: false,
    name: '',
    value: '',
    onChange: () => { },
    onClick: () => { },
    onFocus: () => { },
    onBlur: () => { },
    onKeyUp: () => { },
    type: 'text',
    required: true,
    autoComplete: 'on',
    icon: '',
    placeholder: '',
    tabIndex: 0,
    containerClassName: '',
    showMask: false,
    mask: [],
  };

  private onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (!this.props.onChange) return;
    this.props.onChange(val);
  }

  private onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (this.props.onClick) {
      this.props.onClick(event.target);
    }
  }

  private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event.target);
    }
  }

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(event.target.value);
    }
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event.target.value);
    }
  }

  renderInputContainer = () => {
    const {
      icon, value, className, tabIndex,
    } = this.props;

    return (
      <div>
        {icon && <span className={icon} />}
        <MaskedInput
          id={this.props.id}
          name={this.props.name}
          type={this.props.type}
          className={`form-control ${className || ''}`}
          onChange={this.onTextChange}
          onFocus={this.onFocus}
          onKeyUp={this.onKeyUp}
          onClick={this.onClick}
          onBlur={this.onBlur}
          value={value}
          disabled={this.props.disabled}
          required={this.props.required}
          autoComplete={this.props.autoComplete}
          min={this.props.min}
          max={this.props.max}
          placeholder={this.props.placeholder}
          tabIndex={tabIndex}
          mask={this.props.mask}
          showMask={this.props.showMask}

        />
      </div>
    );
  }

  render() {
    return (
      <div className={`form-input ${this.props.containerClassName}`}>
        {
          this.props.label ?
            <label id={`label-${this.props.id}`}>{this.props.label}</label> : ''
        }
        {this.renderInputContainer()}
      </div>
    );
  }
}
