import _ from "lodash";
import { handleActions, Action } from "redux-actions";
import { CampaignStatusTypes } from "../../enums/campaigns/CampaignStatusTypes";
import { extractDefinedProps } from "../../utilities/ObjectManipulation";
import campaign from "../campaign";

import * as ACTIONS from "../constants/actions";
import RESERVATION_INITIAL_STATE from "./ReservationInitialState";
import {
  IBlockPrices,
  IReservationState,
  IReservationStateData,
  IReservationStateDataChain,
  ISection,
} from "./ReservationInterfaces";

const updateReservationData = (
  reservationState: IReservationState,
  action: Action<Partial<IReservationStateData>>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const newReservationData: Partial<IReservationStateData> = action.payload;

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      ...extractDefinedProps(newReservationData),
    },
  };
};

const createReservationSuccess = (
  reservationState: IReservationState,
  action: Action<{}>
): IReservationState => {
  return {
    ...reservationState,
    errors: {
      createdSuccessful: true,
    },
  };
};

const createReservationFail = (
  reservationState: IReservationState,
  action: Action<{}>
): IReservationState => {
  return {
    ...reservationState,
    errors: {
      createdSuccessful: false,
    },
  };
};

const getReservationByIdSucceed = (
  state: IReservationState,
  action: Action<IReservationStateData>
): IReservationState => {
  const data = action.payload ? action.payload : state.data;

  return {
    ...state,
    data: {
      ...data,
    },
  };
};

const resetBlockReservationState = (
  reservationState: IReservationState,
  action: Action<null>
): IReservationState => {
  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      sblock: RESERVATION_INITIAL_STATE.data.sblock,
    },
  };
};

const resetReservationState = (
  reservationState: IReservationState,
  action: Action<null>
): IReservationState => ({
  ...reservationState,
  data: {
    ...RESERVATION_INITIAL_STATE.data,
    blocks: [],
    campaigns: [],
    brands: [],
    categories: [],
  },
});

const updateReservationSBlockChainsData = (
  reservationState: IReservationState,
  action: Action<IReservationStateDataChain[]>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const newReservationChainsData: IReservationStateDataChain[] = action.payload;

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      sblock: {
        ...reservationState.data.sblock,
        chains: [...newReservationChainsData],
      },
    },
  };
};

const addUniqueAreaToReservationBranch = (
  reservationState: IReservationState,
  action: Action<{
    areaId: number;
    name: string;
    ownerBranchId: number;
    ownerBranchName: string;
    ownerChainId: number;
  }>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const {
    areaId,
    name,
    ownerBranchId,
    ownerBranchName,
    ownerChainId,
  } = action.payload;
  const currentChains = reservationState.data.sblock.chains;
  const ownerChain = currentChains.find((chain) => {
    return chain.id === ownerChainId;
  });

  if (!ownerChain) {
    currentChains.push({
      id: ownerChainId,
    });
  }

  const newChains = currentChains.map((chain) => {
    if (chain.id !== ownerChainId) return { ...chain };

    const currentBranches = [...(chain.branches || [])];
    const ownerBranch = currentBranches.find((branch) => {
      return branch.id === ownerBranchId;
    });

    if (!ownerBranch) {
      currentBranches.push({
        id: ownerBranchId,
        name: ownerBranchName,
        areas: [],
        chainId: ownerChainId,
      });
    }

    const newBranches = currentBranches.map((branch) => {
      if (branch.id !== ownerBranchId) return { ...branch };

      const existingArea = (branch.areas || []).find(
        (area) => area.id === areaId
      );
      if (existingArea) return { ...branch };

      const newAreas = [...(branch.areas || []), { id: areaId, name }];

      return {
        ...branch,
        areas: newAreas,
      };
    });

    return {
      ...chain,
      branches: newBranches,
    };
  });

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      sblock: {
        ...reservationState.data.sblock,
        chains: newChains,
      },
    },
  };
};

const updateSectionDataReservation = (
  reservationState: IReservationState,
  action: Action<Partial<ISection>>
): IReservationState => {
  if (!action.payload) return { ...reservationState };
  const newSectionData: Partial<ISection> = action.payload;
  let currentBlocks = reservationState.data.blocks;
  const newBlocks = currentBlocks.map((cb) => {
    let currentSections = cb.sections;
    const newSections =
      currentSections &&
      currentSections.map((cSection) => {
        if (newSectionData.id === cSection.id) {
          return { ...cSection, ...newSectionData };
        }
        return cSection;
      });
    return { ...cb, sections: newSections };
  });

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      blocks: newBlocks,
    },
  };
};

const removeSectionFromReservation = (
  campaignState: IReservationState,
  action: Action<{
    sectionId: string;
  }>
): IReservationState => {
  return { ...campaignState };
};

const removeAreaFromReservationBranch = (
  reservationState: IReservationState,
  action: Action<{
    areaId: number;
    ownerBranchId: number;
    ownerChainId: number;
  }>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const { areaId, ownerBranchId, ownerChainId } = action.payload;

  const newChains = reservationState.data.sblock.chains.map((chain) => {
    if (chain.id !== ownerChainId) return { ...chain };

    const newBranches =
      chain.branches &&
      chain.branches.map((branch) => {
        if (branch.id !== ownerBranchId) return { ...branch };

        return {
          ...branch,
          areas:
            branch.areas && branch.areas.filter((area) => area.id !== areaId),
        };
      });

    return {
      ...chain,
      branches: newBranches,
    };
  });

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      sblock: {
        ...reservationState.data.sblock,
        chains: newChains,
      },
    },
  };
};

const addUniqueBranchToReservationSBlockChain = (
  reservationState: IReservationState,
  action: Action<{
    branchId: number;
    name: string;
    ownerChainId: number;
  }>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const { branchId, name, ownerChainId } = action.payload;

  const currentChains = reservationState.data.sblock.chains;

  const ownerChain = currentChains.find((chain) => {
    return chain.id === ownerChainId;
  });

  if (!ownerChain) {
    currentChains.push({
      id: ownerChainId,
      name: "",
      branches: [],
    });
  }

  const newChains = currentChains.map((chain) => {
    if (chain.id !== ownerChainId) return { ...chain };

    const existingBranch = (chain.branches || []).find(
      (branch) => branch.id === branchId
    );
    if (existingBranch) return { ...chain };

    const newBranches = [
      ...(chain.branches || []),
      { id: branchId, name: name, areas: [], chainId: ownerChainId },
    ];

    return {
      ...chain,
      branches: newBranches,
    };
  });

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      sblock: {
        ...reservationState.data.sblock,
        chains: [...newChains],
      },
    },
  };
};

const deleteCampaignSucceed = (
  reservationState: IReservationState,
  action: Action<number>
): IReservationState => {
  const campaigns = reservationState.data.campaigns.filter(
    (c) => c.id !== action.payload
  );
  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      campaigns,
    },
  };
};

const approveCampaignSucceed = (
  reservationState: IReservationState,
  action: Action<{id: number, newState: number}>
): IReservationState => {
  if (!action.payload) return { ...reservationState };
  const {id, newState} = action.payload;
  const campaigns = reservationState.data.campaigns.map((campaign) => {
    if (campaign.id === id){
      campaign.status = newState;
    }
    return campaign;
  }
  );
  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      campaigns,
    },
  };
};

const deleteCampaignFail = (
  reservationState: IReservationState,
  action: Action<null>
): IReservationState => {
  return {
    ...reservationState,
  };
};

const addReservationBlock = (
  reservationState: IReservationState,
  action: Action<null>
): IReservationState => {
  const blocks = reservationState.data.blocks || [];
  blocks.push(reservationState.data.sblock);
  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      blocks,
    },
  };
};

const removeReservationBlock = (
  reservationState: IReservationState,
  action: Action<{ id: number }>
): IReservationState => {
  if (!action.payload) return { ...reservationState };
  const { id } = action.payload;
  const blocks =
    reservationState.data.blocks &&
    reservationState.data.blocks.filter((block) => {
      if (block.id != id) {
        return block;
      }
    });

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      blocks: blocks,
    },
  };
};

const removeBranchFromReservationChain = (
  reservationState: IReservationState,
  action: Action<{
    branchId: number;
    ownerChainId: number;
  }>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const { ownerChainId, branchId } = action.payload;

  const newChains = reservationState.data.sblock.chains.map((chain) => {
    if (chain.id !== ownerChainId) return { ...chain };

    return {
      ...chain,
      branches:
        chain.branches &&
        chain.branches.filter((branch) => branch.id !== branchId),
    };
  });

  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      sblock: {
        ...reservationState.data.sblock,
        chains: newChains,
      },
    },
  };
};

const updateReservationPricePerBlocksData = (
  reservationState: IReservationState,
  action: Action<IBlockPrices[]>
): IReservationState => {
  if (!action.payload) return { ...reservationState };

  const newBlocks =
    reservationState.data.blocks &&
    reservationState.data.blocks.map((block) => {
      let branches =
        block.branches &&
        block.branches.map((branch) => {
          const cBranch =
            action.payload &&
            action.payload.find((prices) => prices.branchId == branch.id);

          let prices = cBranch
            ? cBranch.prices
            : {
                standardPrice: 0,
                loopExtraPrice: 0,
                areaPrice: 0,
              };
          return { ...branch, prices };
        });

      return { ...block, branches };
    });
  return {
    ...reservationState,
    data: {
      ...reservationState.data,
      blocks: newBlocks,
    },
  };
};

export const reservationsReducer = handleActions<IReservationState, any>(
  {
    [ACTIONS.CREATE_RESERVATION_SUCCESS]: createReservationSuccess,
    [ACTIONS.CREATE_RESERVATION_FAIL]: createReservationFail,
    [ACTIONS.UPDATE_RESERVATION_DATA]: updateReservationData,
    [ACTIONS.RESET_RESERVATION_STATE]: resetReservationState,
    [ACTIONS.GET_RESERVATION_BY_ID_SUCCEED]: getReservationByIdSucceed,
    [ACTIONS.RESET_BLOCK_RESERVATION_STATE]: resetBlockReservationState,
    [ACTIONS.UPDATE_RESERVATION_CHAINS_DATA]: updateReservationSBlockChainsData,
    [ACTIONS.ADD_UNIQUE_AREA_TO_RESERVATION_BRANCH]: addUniqueAreaToReservationBranch,
    [ACTIONS.REMOVE_AREA_FROM_RESERVATION_BRANCH]: removeAreaFromReservationBranch,
    [ACTIONS.ADD_UNIQUE_BRANCH_TO_RESERVATION_CHAIN]: addUniqueBranchToReservationSBlockChain,
    [ACTIONS.REMOVE_BRANCH_FROM_RESERVATION_CHAIN]: removeBranchFromReservationChain,
    [ACTIONS.ADD_RESERVATION_BLOCK]: addReservationBlock,
    [ACTIONS.REMOVE_RESERVATION_BLOCK]: removeReservationBlock,
    [ACTIONS.UPDATE_RESERVATION_PRICE_PER_BLOCKS_DATA]: updateReservationPricePerBlocksData,
    [ACTIONS.ADD_SECTION_TO_RESERVATION]: updateSectionDataReservation,
    [ACTIONS.REMOVE_SECTION_FROM_RESERVATION]: removeSectionFromReservation,
    [ACTIONS.DELETE_CAMPAIGN_SUCCEED]: deleteCampaignSucceed,
    [ACTIONS.APPROVE_CAMPAIGN_SUCCEED]: approveCampaignSucceed,
    [ACTIONS.DELETE_CAMPAIGN_FAIL]: deleteCampaignFail,
  },
  RESERVATION_INITIAL_STATE
);

export default reservationsReducer;
