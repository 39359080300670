import * as ACTIONS from '../constants/actions';
import { handleActions, Action } from 'redux-actions';
import SessionInitialState from './SessionInitialState';
import { ISession, ISessionData, ISessionStatusIdData } from './SessionInterfaces';


export const loginSucceed = (session: ISession, action: Action<ISessionData>): ISession => {
  return {
    ...session,
    data: {
      ...session.data,
      ...action.payload,
    },
  };
};

export const updateSessionStatus = (session: ISession, action: Action<ISessionStatusIdData>): ISession => {
  return {
    ...session,
    data: {
      ...session.data,
      ...action.payload,
    },
  };
};

const loginFail = (session: ISession, action: Action<any>): ISession => {
  return {
    ...session,
    errors: {
      ...session.errors,
      password: 'Error',
    },
  };
};

const setDataAttribute = (session: ISession, action: Action<{ key: string, value: string }>): ISession => {
  const key = action.payload ? action.payload.key : '';
  const value = action.payload ? action.payload.value : '';
  return {
    ...session,
    data: {
      ...session.data,
      [key]: value,
    },
    errors: {
      ...session.errors,
      [key]: '',
    },
  };
};

const setSessionError = (session: ISession, action: Action<{ key: string, value: string }>): ISession => {
  const key = action.payload ? action.payload.key : '';
  const value = action.payload ? action.payload.value : '';
  return {
    ...session,
    errors: {
      ...session.errors,
      [key]: value,
    },
  };
};

const clearError = (session: ISession, action: Action<{ key: string }>): ISession => {
  const key = action.payload ? action.payload.key : '';
  return {
    ...session,
    errors: {
      ...session.errors,
      [key]: '',
    },
  };
};

const resetPasswordSuceed = (session: ISession, action: Action<{ error: string }>): ISession => ({
  ...SessionInitialState,
});

const resetPasswordFailed = (session: ISession, action: Action<{ error: string }>): ISession => {
  const error = action.payload ? action.payload.error : '';
  return {
    ...session,
    errors: {
      ...session.errors,
      token: error,
    },
  };
};

const logOutSuceed = (session: ISession): ISession => ({
  ...SessionInitialState,
});

const sessionReducer = handleActions<ISession, any>(
  {
    [ACTIONS.LOGIN_SUCCEED]: loginSucceed,
    [ACTIONS.UPDATE_SESSION_DATA]: updateSessionStatus,
    [ACTIONS.LOGIN_FAIL]: loginFail,
    [ACTIONS.CLEAR_SESSION_ERROR]: clearError,
    [ACTIONS.SET_SESSION_DATA_ATTRIBUTE]: setDataAttribute,
    [ACTIONS.SET_SESSION_ERROR]: setSessionError,
    [ACTIONS.RESET_PASSWORD_SUCCEED]: resetPasswordSuceed,
    [ACTIONS.RESET_PASSWORD_FAILED]: resetPasswordFailed,
    [ACTIONS.LOGOUT_SUCCEED]: logOutSuceed,
  },
  SessionInitialState,
);

export default sessionReducer;
