import AuthService from '../../services/AuthService';
import * as ACTIONS from '../constants/actions';
import { ILoginPayload } from './SessionInterfaces';
import { PASSWORD_ERROR, USER_ERROR, USER_PENDING_ERROR } from '../../constants/enum.constants';
import { IResetPasswordPayload } from '../../payloads/session/ResetPasswordPayload';
import UsersService from '../../services/UsersService';
import UserDTO from '../../dtos/users/UserDTO';

export const authenticate = (payload: ILoginPayload) => async (dispatch: Function, getState: Function) => {

  const store = getState().session || {};

  if (store.isFetching) { return; }

  const key = 'password';
  var value = PASSWORD_ERROR;

  try {
    const response = await AuthService.login(payload.email, payload.password);
    if (!response) {
      dispatch({ type: ACTIONS.SET_SESSION_ERROR, payload: { key, value } });
    } else {
      const responseData = response.data.data;
      if(responseData.statusId == 1){ //usuario suspendido
        value = USER_ERROR;
        dispatch({ type: ACTIONS.SET_SESSION_ERROR, payload: { key, value } });
        return;
      }

      if(responseData.statusId == 3){ //pendiente de aprobacion
        value = USER_PENDING_ERROR;
        dispatch({ type: ACTIONS.SET_SESSION_ERROR, payload: { key, value } });
        return;
      }

      localStorage.setItem('token', `Bearer ${response.data.data.token}`);
      dispatch({
        type: ACTIONS.LOGIN_SUCCEED,
        payload: {
          ...response.data.data,
          token: `Bearer ${responseData && responseData.token}`,
        },
      });
    }

  } catch (error) {
    dispatch({ type: ACTIONS.SET_SESSION_ERROR, payload: { key, value } });
  }
};

export const setSessionDataAttribute = (key: string, value: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_SESSION_DATA_ATTRIBUTE, payload: { key, value } });
};

export const setSessionError = (key: string, value: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_SESSION_ERROR, payload: { key, value } });
};

export const clearError = (key: string) => async (dispatch: Function) => {
  dispatch({ key, type: ACTIONS.CLEAR_SESSION_ERROR });
};

export const resetUserPassword = (payload: IResetPasswordPayload) => async (dispatch: Function) => {
  try {
    const response = await AuthService.resetPassword(payload);
    if (response) {
      dispatch({ type: ACTIONS.RESET_PASSWORD_SUCCEED });
    }
  } catch (error) {
    let err = '';
    const status = error.response.status;

    switch (status) {
      case 500:
        err = 'Este enlace es inválido.';
        break;
      case 409:
        err = 'Este enlace ha expirado.';
        break;
      default:
        err = 'Ha ocurrido un error.';
        break;
    }

    dispatch({ type: ACTIONS.RESET_PASSWORD_FAILED, payload: { error: err } });
  }
};

export const logginOutSession = () => async (dispatch: Function) => {
  localStorage.removeItem('token');
  dispatch({ type: ACTIONS.LOGOUT_SUCCEED });
};

export const getUserStatusById = (id: string) => async (dispatch: Function) => {
  const response = await UsersService.getById(id);
  if (response.data.data) {
    const data: UserDTO = response.data.data;
    dispatch({ type: ACTIONS.UPDATE_SESSION_DATA , payload: {statusId: data.statusId}});
  }
};

export default {
  authenticate,
  setSessionDataAttribute,
  clearError,
  resetUserPassword,
  logginOutSession,
  getUserStatusById,
};
