import { authorization } from "./authorization";
import { categories } from "./categories";
import { chains } from "./chains";
import { branches } from "./branches";
import { brands } from "./brands";
import { campaigns } from "./campaigns";
import { screens } from "./screens";
import { monitors } from "./monitors";
import { players } from "./players";
import { advertisers } from "./advertisers";
import { users } from "./users";
import { dashboard } from "./dashboard";
import { notifications } from "./notifications";
import { configurations } from "./configurations";
import { advertiserAdmins } from "./advertiserAdmins";
import { reports } from "./reports";
import { termsAndConditions } from "./termsAndConditions";
import { reservations } from "./reservations";
import { products } from "./products";
import { areas } from "./areas";

export default {
  ...authorization,
  ...categories,
  ...chains,
  ...branches,
  ...brands,
  ...campaigns,
  ...screens,
  ...monitors,
  ...players,
  ...advertisers,
  ...users,
  ...dashboard,
  ...notifications,
  ...configurations,
  ...advertiserAdmins,
  ...reports,
  ...termsAndConditions,
  ...reservations,
  ...products,
  ...areas,
};
