import { handleActions, Action } from 'redux-actions';

import * as ACTIONS from '../constants/actions';
import { extractDefinedProps } from '../../utilities/ObjectManipulation';
import { IScreensState, IScreensStateDataItem, IScreensStateErrors } from './ScreensInterfaces';
import SCREENS_INITIAL_STATE from './ScreensInitialState';

const setScreensData = (screensState: IScreensState, action: Action<IScreensStateDataItem[]>): IScreensState => {
  if (!action.payload) return { ...screensState };

  const newScreensData: IScreensStateDataItem[] = action.payload;

  return ({
    ...screensState,
    data: [
      ...newScreensData,
    ],
  });
};

const updateScreensErrors = (
  screensState: IScreensState,
  action: Action<Partial<IScreensStateErrors>>,
): IScreensState => {

  if (!action.payload) return { ...screensState };

  const newScreensErrors: Partial<IScreensStateErrors> = action.payload;

  return {
    ...screensState,
    errors: {
      ...screensState.errors,
      ...extractDefinedProps(newScreensErrors),
    },
  };

};

const resetScreensState = (screensState: IScreensState, action: Action<null>): IScreensState => ({
  ...SCREENS_INITIAL_STATE,
});

const deleteScreenById = (state: IScreensState, action: Action<Number>): IScreensState => {
  if (!action.payload) return { ...state };

  return {
    ...state,
    data: [
      ...state.data.filter(monitor => monitor.id !== action.payload),
    ],
  };
};

const checkScreenCanDeleteFailed = (
  state: IScreensState,
  action: Action<Partial<IScreensStateErrors>>,
): IScreensState => {
  if (!action.payload) return { ...state };

  const errors: Partial<IScreensStateErrors> = action.payload;
  return {
    ...state,
    errors: {
      search: '',
      delete: '',
      ...errors,
    },
  };
};

const suspendScreenPlayerByPlayerId = (
  state: IScreensState,
  action: Action<Partial<number>>,
): IScreensState => {
  if (!action.payload) return { ...state };

  const playerId = action.payload;

  return {
    ...state,
    data: [
      ...state.data.map((screen) => {
        return {
          ...screen,
          player: screen.player && screen.player.id === playerId ? {
            ...screen.player,
            active: false,
          } : screen.player,
        };
      }),
    ],
  };
};

const suspendScreenById = (screensState: IScreensState, action: Action<number>): IScreensState => {
  if (!action.payload) return { ...screensState };

  const screenId = action.payload;

  return {
    ...screensState,
    data: [
      ...screensState.data.map((screen) => {
        return {
          ...screen,
          active: screen.id === screenId ? false : screen.active,
        };
      }),
    ],
  };
};

const suspendScreenMonitorByMonitorId = (
  state: IScreensState,
  action: Action<Partial<number>>,
): IScreensState => {
  if (!action.payload) return { ...state };

  const monitorId = action.payload;

  return {
    ...state,
    data: [
      ...state.data.map((screen) => {
        return {
          ...screen,
          monitor: screen.monitor && screen.monitor.id === monitorId ? {
            ...screen.monitor,
            active: false,
          } : screen.monitor,
        };
      }),
    ],
  };
};

const getScreensByChainIdSuccess = (
  screensState: IScreensState,
  action: Action<IScreensStateDataItem[]>,
): IScreensState => {
  if (!action.payload) return { ...screensState };
  const newScreensData: IScreensStateDataItem[] = action.payload;

  return ({
    ...screensState,
    data: [
      ...newScreensData,
    ],
  });
};

const activateScreenById = (screensState: IScreensState, action: Action<number>): IScreensState => {
  if (!action.payload) return { ...screensState };

  const screenId = action.payload;

  return {
    ...screensState,
    data: [
      ...screensState.data.map((screen) => {
        return {
          ...screen,
          active: screen.id === screenId ? true : screen.active,
        };
      }),
    ],
  };
};

const screensReducer = handleActions<IScreensState, any>(
  {
    [ACTIONS.SET_SCREENS_DATA]: setScreensData,
    [ACTIONS.UPDATE_SCREENS_ERRORS]: updateScreensErrors,
    [ACTIONS.RESET_SCREENS_STATE]: resetScreensState,
    [ACTIONS.DELETE_SCREEN_BY_ID]: deleteScreenById,
    [ACTIONS.CHECK_SCREEN_CAN_DELETE_FAILED]: checkScreenCanDeleteFailed,
    [ACTIONS.SUSPEND_PLAYER_BY_ID]: suspendScreenPlayerByPlayerId,
    [ACTIONS.SUSPEND_SCREEN_BY_ID]: suspendScreenById,
    [ACTIONS.SUSPEND_MONITOR_BY_ID]: suspendScreenMonitorByMonitorId,
    [ACTIONS.GET_SCREENS_BY_CHAIN_ID_SUCCESS]: getScreensByChainIdSuccess,
    [ACTIONS.ACTIVATE_SCREEN_BY_ID]: activateScreenById,
  },
  SCREENS_INITIAL_STATE,
);

export default screensReducer;
