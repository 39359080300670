import React, { Component } from 'react';

interface IVerticalSolidSeparator {
  className?: string;
}

export default class VerticalSolidSeparator extends Component<IVerticalSolidSeparator> {
  static defaultProps = {
    className: '',
  };
  
  render() {
    const { className } = this.props;
    
    return (
      <div className={`solid-separator__vertical ${className}`} />
    );
  }
}
