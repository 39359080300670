import React, { Component } from 'react';

interface Props {
  id: string;
  textColorClass: string;
  borderColorClass: string;
  backgroundColorClass: string;
  dataToggle?: string;
  toggleText?: string;

  onClick: Function;
  disabled: boolean;
  className: string;
}

export default class RectangleButton extends Component<Props> {
  static defaultProps: Props = {
    id: '',
    textColorClass: 'default-text-color',
    borderColorClass: 'default-border-color',
    backgroundColorClass: 'default-background-color',
    dataToggle: '',
    toggleText: '',

    onClick: () => { },
    disabled: false,
    className: 'btn btn-primary',
  };

  handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onClick } = this.props;
    onClick();
  }

  render() {
    return (
      <button
        id={this.props.id}
        className={`${this.props.className}`}
        onClick={this.handleOnClick}
        disabled={this.props.disabled}
        data-toggle={this.props.dataToggle}
        title={this.props.toggleText}
        
      >
        {this.props.children}
      </button>
    );
  }
}
