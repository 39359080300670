import _ from "lodash";
import { handleActions, Action } from "redux-actions";

import * as ACTIONS from "../constants/actions";
import ThynkadsResponse from "../../dtos/ThynkadsResponse";
import ThynkadsResponseInitialState from "../../dtos/ThynkadsResponseInitialState";
import { ICampaignData, ICampaignsState } from "./CampaignsInterfaces";
import CAMPAIGNS_INITIAL_STATE from "./CampaignsInitialState";

const setCampaigns = (
  campaigns: ICampaignsState,
  action: Action<ThynkadsResponse<ICampaignData[]>>
): any => {
  const payload = action.payload! || ThynkadsResponseInitialState;
  const data = payload.data || CAMPAIGNS_INITIAL_STATE.data;

  return {
    ...campaigns,
    data,
  };
};

const resetCampaignsState = (): ICampaignsState => {
  return {
    ...CAMPAIGNS_INITIAL_STATE,
  };
};

const campaignsSearchStarted = (
  campaigns: ICampaignsState,
  action: Action<string>
): ICampaignsState => {
  return {
    ...campaigns,
    meta: {
      ...campaigns.meta,
      isSearching: true,
      searchIdentifier: action.payload,
    },
  };
};

const campaignsSearchFinished = (
  campaigns: ICampaignsState
): ICampaignsState => {
  return {
    ...campaigns,
    meta: {
      ...campaigns.meta,
      isSearching: false,
    },
  };
};

const campaignsSearchSuccess = (
  campaigns: ICampaignsState,
  action: Action<{
    searchIdentifier: string;
    data?: ICampaignData[];
  }>
): ICampaignsState => {
  if (!action.payload) return { ...campaigns };

  const { payload } = action;
  const data = payload.data || CAMPAIGNS_INITIAL_STATE.data;
  const searchIdentifier = payload.searchIdentifier;
  const currentSearchIdentifier = campaigns.meta.searchIdentifier;

  if (currentSearchIdentifier && currentSearchIdentifier !== searchIdentifier)
    return { ...campaigns };

  return {
    ...campaigns,
    data: [...data],
    meta: {
      ...campaigns.meta,
      searchIdentifier: undefined,
    },
  };
};

const campaignsReducer = handleActions<ICampaignsState, any>(
  {
    [ACTIONS.SET_CAMPAIGNS]: setCampaigns,
    [ACTIONS.RESET_CAMPAIGNS_STATE]: resetCampaignsState,
    [ACTIONS.CAMPAIGNS_SEARCH_STARTED]: campaignsSearchStarted,
    [ACTIONS.CAMPAIGNS_SEARCH_FINISHED]: campaignsSearchFinished,
    [ACTIONS.CAMPAIGNS_SEARCH_SUCCESS]: campaignsSearchSuccess,
  },
  CAMPAIGNS_INITIAL_STATE
);

export default campaignsReducer;
