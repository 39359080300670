import React, { Component } from 'react';
import Checkbox from '../checkbox';
import { ICheckboxRow } from './interfaces';
import { CheckChangeCallback, ICheckbox } from '../checkbox/interfaces';

interface ICheckboxRowProps extends ICheckboxRow {
  onCheckChange: CheckChangeCallback;
  isReadOnly?: boolean;
  hideCheckBox?: boolean;

  labelClassName: string;
  className: string;
}

export default class CheckboxRow extends Component<ICheckboxRowProps> {
  static defaultProps = {
    labelClassName: '',
    className: '',
    isReadOnly: false,
    hideCheckBox: false,
  };

  static create(label: string, checkbox: ICheckbox): ICheckboxRow {
    return {
      label, 
      checkbox,
    };
  }

  render() {
    const { checkbox, label, onCheckChange, labelClassName, className, isReadOnly, hideCheckBox } = this.props;
    const isDisabled = checkbox.disabled || isReadOnly;

    return (
      <div className={`checkbox-row ${className}`}>
        <div className={`label-text ${labelClassName}`}>
          <span>{label}</span>
        </div>
        <div className="checkbox-container">
        {!hideCheckBox &&
          <Checkbox 
            id={checkbox.id}
            isChecked={checkbox.isChecked}
            value={checkbox.value}
            disabled={isDisabled}
            onCheckChange={onCheckChange}
          />
        }
        </div>
      </div>
    );
  }
}
