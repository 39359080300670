export enum PlacementTypes {
  TopLeft = 'topLeft',
  TopCenter = 'top',
  TopRight = 'topRight',
  RightCenter = 'right',
  BottomRight = 'bottomRight',
  BottomCenter = 'bottom',
  BottomLeft = 'bottomLeft',
  LeftCenter = 'left',
}
