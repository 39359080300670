import * as ACTIONS from "../constants/actions";
import { handleActions, Action } from "redux-actions";

import CATEGORIES_INITIAL_STATE from "./CategoriesInitialState";
import ThynkadsResponse from "../../dtos/ThynkadsResponse";
import { ICategory } from "../category/CategoryInterfaces";
import {
  ICategoriesState,
  IDeleteCategoryPayload,
} from "./CategoriesInterfaces";
import { ISearchCategoryDTO } from "../../dtos/categories/SearchCategoryDTO";
import CategoryMapper from "../../mappers/CategoryMapper";
import { ICategoryEntityDTO } from "../../dtos/categories/CategoryEntityDTO";

const setCategoriesAttribute = (
  categories: ICategoriesState,
  action: Action<ThynkadsResponse<ICategory[]>>
) => {
  const payloadData = action.payload && action.payload.data;
  const data = payloadData || CATEGORIES_INITIAL_STATE.data;

  return {
    ...categories,
    data,
  };
};

const setWorkingStatus = (
  categories: ICategoriesState,
  action: Action<boolean>
) => ({
  ...categories,
  isCreating: action.payload || false,
});

const searchCategoriesSuccess = (
  categories: ICategoriesState,
  action: Action<ISearchCategoryDTO[]>
) => {
  const newCategories =
    action.payload &&
    action.payload.map((categoryDto) =>
      CategoryMapper.mapSearchDtoToStore(categoryDto)
    );

  return {
    ...categories,
    data: [...(newCategories || CATEGORIES_INITIAL_STATE.data)],
  };
};

const searchCategoriesError = (
  categories: ICategoriesState,
  action: Action<null>
) => ({
  ...categories,
  data: [...CATEGORIES_INITIAL_STATE.data],
});

const updateExistingCategoryInList = (
  categories: ICategoriesState,
  action: Action<ICategoryEntityDTO>
) => {
  const newCategory = action.payload;

  if (!newCategory) return { ...categories };

  const newCategories = categories.data.map((category) => ({
    ...category,
    ...(category.id === newCategory.id
      ? CategoryMapper.mapDtoToStore(newCategory)
      : {}),
  }));

  return {
    ...categories,
    data: [...newCategories],
  };
};

export const updateCategoryStatusSuccess = (
  categories: ICategoriesState,
  action: Action<ICategoryEntityDTO>
) => {
  if (action.payload === undefined) return { ...categories };

  const newCategory = action.payload;
  const newCategories = categories.data.map((category) => ({
    ...category,
    active:
      category.id === newCategory.id ? newCategory.active : category.active,
  }));

  return {
    ...categories,
    data: [...newCategories],
  };
};

const updateCategoryStatusError = (
  categories: ICategoriesState,
  action: Action<null>
) => ({
  ...categories,
});

const deleteCategorySuccess = (
  categories: ICategoriesState,
  action: Action<IDeleteCategoryPayload>
) => {
  const deleteSuccessful = action.payload && action.payload.isDeleted;
  const deletedId = action.payload && action.payload.id;

  if (!deleteSuccessful) return { ...categories };

  return {
    ...categories,
    data: [...categories.data.filter((category) => category.id !== deletedId)],
  };
};

const deleteCategoryError = (
  categories: ICategoriesState,
  action: Action<null>
) => ({
  ...categories,
});

const resetCategories = (
  categories: ICategoriesState,
  action: Action<null>
) => ({
  ...CATEGORIES_INITIAL_STATE,
});

const categoriesReducer = handleActions<ICategoriesState, any>(
  {
    [ACTIONS.SET_CATEGORIES]: setCategoriesAttribute,
    [ACTIONS.SET_CATEGORY_CREATING_STATUS]: setWorkingStatus,
    [ACTIONS.SET_CATEGORIES_ERROR]: setCategoriesAttribute,
    [ACTIONS.SEARCH_CATEGORIES_SUCCESS]: searchCategoriesSuccess,
    [ACTIONS.SEARCH_CATEGORIES_ERROR]: searchCategoriesError,
    [ACTIONS.DELETE_CATEGORY_SUCCESS]: deleteCategorySuccess,
    [ACTIONS.DELETE_CATEGORY_ERROR]: deleteCategoryError,
    [ACTIONS.UPDATE_CATEGORY_STATUS_SUCCESS]: updateCategoryStatusSuccess,
    [ACTIONS.UPDATE_CATEGORY_STATUS_ERROR]: updateCategoryStatusError,
    [ACTIONS.UPDATE_EXISTING_CATEGORY_IN_LIST]: updateExistingCategoryInList,
    [ACTIONS.RESET_CATEGORIES]: resetCategories,
  },
  CATEGORIES_INITIAL_STATE
);

export default categoriesReducer;
