import React, { ReactElement } from 'react';

interface IHeaderBarProps {
  leftContent?: ReactElement<any>;
  leftClassName?: string;
  rightContent?: ReactElement<any>;
  className: string;
}

export default class HeaderBar extends React.PureComponent<IHeaderBarProps> {
  static defaultProps = {
    className: '',
  };

  render() {
    const { className, leftClassName } = this.props;

    return (
      <div className={`header-bar ${className}`}>
        <div className={`header-bar--left ${leftClassName}`}>
          {this.props.leftContent}
        </div>
        <div className="header-bar--right">
          {this.props.rightContent}
        </div>
      </div>
    );
  }
}
